import React, { useEffect, useRef } from "react";
import { useState } from "react";
import Layout from "../Layout/Layout";
import "../../Styles/mainstyle/select2.min.css";
import Multiselect from "multiselect-react-dropdown";
import axios from "axios";
import { baseUrl } from "../../baseUrl";
import loaderImg from "../../assets/images/loader.svg";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import Maps from "../Sections/LocationCoponents/Maps";
import Select from "react-select";

function AgentPropertyEdit() {
  const [showSellform, setShowSellform] = useState(false);
  const [form, setform] = useState(1);
  const [loading2, setloading2] = useState(false);
  const [images, setimages] = useState([]);

  const [town, settown] = useState();
  const [pool, setpool] = useState([]);
  const [loading, setloading] = useState(false);
  const [tags, setTags] = useState([]);
  const [outside, setoutside] = useState([]);
  const [verifyToken, setverifyToken] = useState();
  const [nearLocation, setnearLocation] = useState([]);
  const [multiTown, setmultiTown] = useState([]);
  const userinfo = JSON.parse(localStorage.getItem("user"));
  const user = JSON.parse(localStorage.getItem("data"));
  const token = JSON.parse(localStorage.getItem("token"));
  const [imageArray, setimageArray] = useState([]);
  const [Ref, setRef] = useState("");
  const [price2, setprice] = useState("");
  const [postcodePin, setpostcode] = useState("");
  const [propertyType, setProperty] = useState([]);
  const [bedsNum, setbedsNum] = useState("");
  const [bathsNum, setbathsNum] = useState("");
  const [buildSize, setbuildSize] = useState("");
  const [summaryDes, setsummary] = useState("");
  const [plotSize, setplotsize] = useState("");
  const [OptionalDesc, setOptionalDesc] = useState("");
  const [type, setType] = useState("");
  const [subproperty, setsubproperty] = useState("");
  const { id } = useParams();
  const [editdata, seteditdata] = useState("");
  const [mapdata, setmapdata] = useState({
    lat: "",
    lng: "",
    address: "",
  });
  // console.log(mapdata, "<<<<<<<<<");
  const [succes, setsucces] = useState("");
  const navigate = useNavigate();

  const townlabel = []
  town?.map((item) => townlabel.push({ value: item.town, label: item.town }))

  const [selectedimages, setselectedimages] = useState([]);
  const multiplefileChange = (e) => {
    setimageArray(e.target.files);
    const selectedfiles = e.target.files;
    const selectedfilesArray = Array.from(selectedfiles);

    const imageArray = selectedfilesArray.map((file) => {
      return file;
    });

    setselectedimages((prevImage) => prevImage.concat(imageArray));
  };

  const deleteHandleImage = (image) => {
    setselectedimages(selectedimages.filter((e) => e !== image));
  };
  const deleteHandleImage2 = (image) => {
    setimages(images.filter((e) => e !== image));
  };
  // console.log(imageArray.length, imageArray, selectedimages);

  const handlePrev = () => {
    if (form == 1) {
      setShowSellform(false);
      window.scrollTo(0, 0);
    } else if (form == 2) {
      setform(1);
    } else if (form == 3) {
      setform(1);
    } else if (form == 3) {
      setform(1);
    }
  };
  const fetchtownList = async () => {
    await axios
      .get(baseUrl + `/town-list`, {
        headers: { Authorization: `No Auth` },
      })
      .then((res) => {
        if (res.status == 200) {
          settown(res.data.data);
          setloading(true);
        }
      })
      .catch((error) => {
        if (error) {
          setloading(true);
        }
      });
  };

  const countries = [];
  const poolarray = [];
  const nearLocationarray = [];
  const outsidearray = [];
  const tagsarray = [];
  const propertytyearray = [];
  multiTown?.map((item) => countries.push(item.label.trim()));

  tags?.map((item) => tagsarray.push(item.label.trim()));
  nearLocation?.map((item) => nearLocationarray.push(item.label.trim()));
  pool?.map((item) => poolarray.push(item.label.trim()));
  outside?.map((item) => outsidearray.push(item.label.trim()));
  propertyType?.map((item) => propertytyearray.push(item.label?.trim()));

  console.log(tags, "<<<<,propertytyearray");


  const delete_img = async (e) => {
    await axios
      .get(baseUrl + `/agent/delete-image?id=${e}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        console.log("success");
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  const submitForm = (e) => {
    // e.preventDefault();
    setloading2(true);

    var FormData = require("form-data");
    var data = new FormData();
    data.append("ref", Ref);
    data.append("price", price2);
    data.append("price_freq", type);
    data.append("property_type", JSON.stringify(propertytyearray));
    data.append("town", JSON.stringify(countries));
    data.append("postcode", postcodePin);
    data.append("beds", bedsNum);
    data.append("baths", bathsNum);
    data.append("built", buildSize);
    data.append("plot", plotSize);
    data.append("pool", JSON.stringify(poolarray));
    data.append("outside_space", JSON.stringify(outsidearray));
    data.append("near_by", JSON.stringify(nearLocationarray));
    data.append("amenities", JSON.stringify(tagsarray));
    data.append("subtype_property", subproperty);
    data.append("summary", summaryDes);
    data.append("id", id);
    data.append("property_id", editdata.property_id);
    data.append("feat_desc", OptionalDesc);
    data.append("latitude", mapdata.lat);
    data.append("longitude", mapdata.lng);
    data.append("location_detail", mapdata.address);

    for (let i = 0; i < selectedimages.length; i++) {
      data.append("image[]", selectedimages[i]);
    }

    if (form == 4) {
      axios
        .request({
          method: "post",
          url: baseUrl + "/agent/update-property",
          data: data,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            navigate("/agent-dashboard");
            swal({
              title: "Bella Islas says",
              text: response.data.message,
              icon: "success",
              button: "OK",
              className: "notranslate"
            });
            setloading2(false);

          }
        })
        .catch((error) => {
          if (error) {
            swal({
              title: "Bella Islas says",
              text: error.message,
              icon: "error",
              button: "OK",
              className: "notranslate"
            });
            setloading2(false);

          }
        });
    }
  };

  useEffect(() => {
    if (userinfo) {
      //setUserType(userinfo.user_type)
      if (userinfo.user_type == "Agent") {
        // setUsername(userinfo.username)
      } else if (userinfo.user_type == "User") {
        // setUsername(userinfo.name)
      }
    }

    if (token) {
      setverifyToken(token);
    }
  }, []);

  const editdetailsFetch = async () => {
    await axios
      .get(baseUrl + `/agent/edit-property?id=${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        console.log(res.data?.data, res.data.data?.amenities, "res.data.data.data>>>.");

        if (res.status == 200) {
          if (res.data.data?.amenities != null) {
            JSON.parse(res.data.data?.amenities).map((a, index) => {
              if (tags.length < JSON.parse(res.data.data?.amenities).length) {
                tags.push({ label: a });
                setTags([...tags]);
              }
            });
          }
          setOptionalDesc(res.data.data?.feature_desc)
          setsubproperty(res.data.data?.subtype_property);
          seteditdata(res.data.data);
          setRef(res.data.data.data?.ref);
          setpostcode(res.data.data.data?.postcode);
          setmapdata({
            lat: res.data?.data?.latitude,
            lng: res.data?.data?.longitude,
            address: res.data?.data?.data?.location_detail,
          });
          JSON.parse(res.data.data.property_type).map((a, index) => {
            if (
              propertyType.length <
              JSON.parse(res.data.data.property_type).length
            ) {
              propertyType.push({ label: a });
              setProperty([...propertyType, { label: a }]);
              // setmultiTown([...multiTown]);
            }
          });
          JSON.parse(res.data.data?.near_by).map((a, index) => {
            if (
              nearLocation.length < JSON.parse(res.data.data.near_by).length
            ) {
              nearLocation.push({ label: a });
              //setnearLocation([...nearLocation ,{ label: a }]);
            }
          });
          JSON.parse(res.data.data?.pool).map((a, index) => {
            if (pool.length < JSON.parse(res.data.data.pool).length) {
              pool.push({ label: a });
              //setpool([...pool]);
            }
          });
          JSON.parse(res.data.data?.outside_space).map((a, index) => {
            if (
              outside.length < JSON.parse(res.data.data?.outside_space).length
            ) {
              outside.push({ label: a });
              setoutside([...outside]);
            }
          });
          JSON.parse(res.data.data.town).map((a, index) => {
            console.log(a);
            if (multiTown.length < JSON.parse(res.data.data.town).length) {
              multiTown.push({ label: a });
              setmultiTown([...multiTown]);
              setProperty([...propertyType]);
            }
          });
          
          setprice(res.data.data.price);
          setbedsNum(res.data.data.data.beds);
          setbathsNum(res.data.data.data.baths);
          setbuildSize(res.data.data.data?.surface_area.built != null ? res.data.data.data?.surface_area.built : "");
          setsummary(res.data.data?.english_description);
          setimages(res.data.data.data.images.image);
          setplotsize(res.data.data.data.surface_area.plot != null ?
            res.data.data.data.surface_area.plot
            : ""
          )
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };


  useEffect(() => {
    fetchtownList();
    editdetailsFetch();
  }, []);

  useEffect(() => {
    // editdetailsFetch()
    setprice(editdata.price);
    setbedsNum(editdata.beds);
    setbathsNum(editdata.data?.baths);
    setbuildSize(editdata.data?.surface_area?.built);
    setplotsize(editdata.data?.surface_area?.plot)
    setsummary(editdata.data?.summary);
    setimages(editdata.data?.images.image);

  }, [editdata]);

  const locationDetails = [
    { value: "Near Golf", label: "Near Golf" },
    { value: "Near Beach", label: "Near Beach" },
    { value: "Near Shops", label: "Near Shops" },
    { value: "Near Transport", label: "Near Transport" },
  ];

  const poolDetails = [
    { value: "Communal", label: "Communal" },
    { value: "Private", label: "Private" },
    { value: "Pool", label: "Pool" },
  ];

  const outsideDetails = [
    { value: "Communal Garden", label: "Communal Garden" },
    { value: "Private Garden", label: "Private Garden" },
    { value: "Terrace", label: "Terrace" },
  ];

  const tagsDetails = [
    { value: "Air Conditioning", label: "Air Conditioning" },
    { value: "Beach", label: "Beach" },
    { value: "Sea View", label: "Sea View" },
    { value: "Lift", label: "Lift" },
    { value: "Garage", label: "Garage" },
    { value: "Parking", label: "Parking" },
    { value: "Swimming Pool", label: "Swimming Pool" },
    { value: "Disabled Access", label: "Disabled Access" },
  ];
  const [errorMessage, setErrorMessage] = useState("");
  let nf = new Intl.NumberFormat();
  const dragItem = React.useRef(null);
  const dragOverItem = React.useRef(null);

  const handleSort = () => {
    let _fruitItems = [...selectedimages];
    const draggedItemContent = _fruitItems.splice(dragItem.current, 1)[0];
    _fruitItems.splice(dragOverItem.current, 0, draggedItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setselectedimages(_fruitItems);
  };
  const handleSubProperty = (e) => {
    setsubproperty(e.target.value);
  };

  const handleNextform = () => {
    if (
      form == 1
        ? Ref != "" &&
        price2 != "" &&
        postcodePin !== "" &&
        bathsNum !== "" &&
        bedsNum !== "" &&
        buildSize !== "" &&
        propertyType[0] &&
        multiTown[0] &&
        summaryDes !== ""
        : form > 0
    ) {
      setform(form + 1);
      window.scrollTo(0, 0);
    } else {
      setErrorMessage("Fill All the Fields !");
    }
    console.log("clicked");
  };

  setTimeout(() => {
    setErrorMessage("");
    setsucces("");
  }, 3000);

  const handleSell = () => {
    setShowSellform(true);
    setType(editdata ? editdata.type : "sale");
  };

  const handleRent = () => {
    setShowSellform(true);
    setType(editdata ? editdata.type : "Rent");
  };

  useEffect(() => {
    handleRent();
    handleSell();
  }, []);

  const map_value = (lat, lng, address) => {
    setmapdata({
      lat: lat,
      lng: lng,
      address: address,
    });
  };



  const data3 = [
    { value: "Flat", label: "Flat" },
    { value: "Villa", label: "Villa" },
    { value: "House", label: "House" },
    { value: "Land", label: "Land" },
    { value: "Penthouse", label: "Penthouse" },
    { value: "Country House", label: "Country House" },
    { value: "Ground Flat", label: "Ground Flat" },
    { value: "Town House", label: "Town House" },
    { value: "Finca", label: "Finca" },
    { value: "Garage", label: "Garage" },
  ];

  return (
    <>
      <Layout>
        {/* <!-- add listing type section start here --> */}
        {loading !== true ? (
          <div className="loader_set">
            {" "}
            <img src={loaderImg} alt="loading..." />{" "}
          </div>
        ) : (
          <section className="add_listing">

            {showSellform !== true ? (
              <div className="choose-listing">
                <div className="container-sm">
                  <div className="wrap flex">
                    <h3>Choose listing type</h3>
                    <div className="col_" data-id="forSale">
                      <div className="face front">
                        <div className="content">
                          <div className="icon" onClick={() => handleSell()}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="29"
                              height="26"
                              viewBox="0 0 576 512"
                            >
                              <path d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z" />
                            </svg>
                          </div>
                          <p>For Sale</p>
                        </div>
                      </div>
                      <div className="face back" onClick={() => handleSell()}>
                        <h6>Choose type</h6>
                      </div>
                    </div>
                    <div className="col_" data-id="toRent">
                      <div className="face front">
                        <div className="content">
                          <div className="icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="22"
                              height="26"
                              viewBox="0 0 448 512"
                            >
                              <path d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM192 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM64 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z" />
                            </svg>
                          </div>
                          <p>To Rent</p>
                        </div>
                      </div>
                      <div className="face back" onClick={() => handleRent()}>
                        <h6>Choose type</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="multisteps">
                <div className="container-sm">
                  <div
                    className="multistep-form"
                    id="forSale"
                    style={{ display: "block" }}
                  >
                    <h2>Edit Property Details</h2>
                    <form
                      action=""

                    >
                      <div style={{ textAlign: "center", fontSize: "16px" }}>
                        {succes}
                      </div>
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: "15px",
                          color: "red",
                        }}
                      >
                        {errorMessage ? errorMessage : ""}
                      </div>
                      <div className="steps ">
                        <ul className="tablist">
                          <li
                            data-id="stepsOnes"
                            className={`${form == "1" ? "active" : ""}`}
                            onClick={() => {
                              setform(1);
                            }}
                          >
                            <span className="number ">1</span> Basic Information
                          </li>
                          <li
                            data-id="stepsTwos"
                            className={`${form == "2" ? "active" : ""}`}
                            onClick={() => {
                              if (
                                (Ref != "" &&
                                  price2 != "" &&
                                  postcodePin != "" &&
                                  bathsNum != "" &&
                                  bedsNum != "" &&
                                  buildSize != "" &&
                                  propertyType[0] &&
                                  multiTown[0] &&
                                  summaryDes != "") ||
                                form > 2
                              ) {
                                setform(2);
                              } else {
                                setErrorMessage("Fill All the Fields !");
                              }
                            }}
                          >
                            <span className="number">2</span> Additional
                            Property Details
                          </li>
                          <li
                            data-id="stepsThrees"
                            className={`${form == "3" ? "active" : ""}`}
                            onClick={() => {
                              if (
                                (Ref != "" &&
                                  price2 != "" &&
                                  postcodePin != "" &&
                                  bathsNum != "" &&
                                  bedsNum != "" &&
                                  buildSize != "" &&
                                  propertyType[0] &&
                                  multiTown[0] &&
                                  summaryDes != "") ||
                                form > 3
                              ) {
                                setform(3);
                              } else {
                                setErrorMessage("Fill All the Fields !");
                              }
                            }}
                          >
                            <span className="number">3</span> Property Location
                          </li>
                          <li
                            data-id="stepsFours"
                            className={`${form == "4" ? "active" : ""}`}
                            onClick={() => {
                              if (
                                Ref != "" &&
                                price2 != "" &&
                                postcodePin != "" &&
                                bathsNum != "" &&
                                bedsNum != "" &&
                                buildSize != "" &&
                                propertyType[0] &&
                                multiTown[0] &&
                                summaryDes != ""
                              ) {
                                setform(4);
                              } else {
                                setErrorMessage("Fill All the Fields !");
                              }
                            }}
                          >
                            <span className="number">4</span> Images &amp; Video
                          </li>
                        </ul>
                      </div>
                      {form == 1 ? (
                        <div
                          class="formWrappers active"
                          style={{ display: "block" }}
                          id="stepsOnes"
                        >
                          <div class="input-wrap flex">
                            <label for="job_title">Property Address</label>
                            <div class="form_group half">
                              <input
                                type="text"
                                // defaultValue={editdata?.data ? editdata.data?.ref :"Not Added" }
                                value={Ref}
                                placeholder="Reference"
                                onChange={(e) => setRef(e.target.value)}
                              />
                            </div>
                            <div class="form_group half pdrt0">
                              <input
                                type="text"
                                maxLength={5}
                                value={postcodePin}
                                defaultValue={
                                  editdata?.data
                                    ? editdata.data?.postcode
                                    : postcodePin
                                }
                                onChange={(e) => setpostcode(e.target.value)}
                                placeholder="Postcode"
                              />
                            </div>
                            <div class="form_group">

                              <Select
                                isObject={false}
                                options={townlabel}
                                isMulti={true}
                                className="multi-select notranslate"
                                placeholder="Town, village or city"

                                onChange={(e) => {
                                  setmultiTown(e)
                                }}

                                value={multiTown}
                              />

                              {/* <Multiselect
                                className="multi-select"
                                placeholder="Town, village or city"
                                displayValue="town"
                                // isMulti ={false}
                                //id="townSelect"
                                selectedValues={multiTown}
                                onSelect={(e) => setmultiTown([...e])}
                                options={town}
                              /> */}
                            </div>
                          </div>
                          <div class="input-wrap flex">
                            <label for="job_title">
                              BASIC PROPERTY INFORMATION
                            </label>
                            <div class="form_group">
                              <input
                                type="text"
                                placeholder="Price"
                                value={price2 ? nf.format(price2) : ""}
                                //defaultValue={editdata?.data? editdata.data?.price :price2 }
                                onChange={(e) => {
                                  var validnum = e.target.value.replace(
                                    /[^0-9\.]/g,
                                    ""
                                  );
                                  setprice(validnum)
                                }
                                }
                              />
                            </div>
                            <div class="form_group half">
                              <input
                                type="text"
                                value={bedsNum}
                                maxLength={6}
                                placeholder="Number of bedrooms"
                                //defaultValue={editdata?.data? editdata.data?.beds :bedsNum }
                                onChange={(e) => setbedsNum(e.target.value)}
                              />
                            </div>
                            <div class="form_group half pdrt0">
                              <input
                                type="text"
                                value={bathsNum}
                                maxLength={6}
                                onChange={(e) => {
                                  setbathsNum(e.target.value);
                                }}
                                // defaultValue={editdata?.data? editdata.data?.baths :bathsNum }
                                placeholder="Number of bathrooms"
                              />
                            </div>
                            <div class="form_group half">
                              <input
                                type="text"
                                value={buildSize}
                                maxLength={10}
                                defaultValue={buildSize}
                                onChange={(e) => setbuildSize(e.target.value)}
                                placeholder="Build size in m2"
                              // value={buildSize}
                              />
                            </div>
                            <div class="form_group">
                              <Select
                                isObject={false}
                                options={data3}
                                isMulti={true}
                                className="multi-select"
                                placeholder="Property Type"
                                onChange={(e) => {
                                  setProperty(e)
                                  // console.log(e[0].value)
                                }}
                                // selectedValues={multiTown}
                                value={propertyType}
                              />

                            </div>
                          </div>
                          <div class="optional">
                            <label for="optional">(optional)</label>
                            <div class="field " style={{ paddingTop: "1rem" }}>
                              <div class="md-checkbox">
                                <input
                                  type="radio"
                                  name="subtype-property[]"
                                  id="cbopt-3odoaL3"
                                  value="New Build"
                                  maxLength={6}
                                  onChange={(e) => handleSubProperty(e)}
                                  autocomplete="chrome-off"
                                  checked={subproperty == "New Build" ? true :false}
                                />
                                <label for="cbopt-3odoaL3">New Build </label>
                              </div>
                              <div class="md-checkbox">
                                <input
                                  type="radio"
                                  name="subtype-property[]"
                                  id="cbopt-RU93lgT"
                                  value="Exclusive"
                                  onChange={(e) => handleSubProperty(e)}
                                  autocomplete="chrome-off"
                                  checked={subproperty == "Exclusive" ? true :false}
                                />
                                <label for="cbopt-RU93lgT">Exclusive </label>
                              </div>
                              <div class="md-checkbox">
                                <input
                                  type="radio"
                                  name="subtype-property[]"
                                  id="cbopt-dMlX2XD"
                                  onChange={(e) => handleSubProperty(e)}
                                  value="Tourist License"
                                  autocomplete="chrome-off"
                                  checked={subproperty == "Tourist License" ? true :false}
                                />
                                <label for="cbopt-dMlX2XD">
                                  Tourist License{" "}
                                </label>
                              </div>
                              <div class="md-checkbox">
                                <input
                                  type="radio"
                                  onChange={(e) => handleSubProperty(e)}
                                  name="subtype-property[]"
                                  id="cbopt-gC1ndR6"
                                  value="New Project"
                                  autocomplete="chrome-off"
                                  checked={subproperty == "New Project" ? true :false}
                                />
                                <label for="cbopt-gC1ndR6">New Project </label>
                              </div>
                            </div>
                          </div>
                          <div class="input-wrap flex">
                            <label for="job_title">Property Description</label>
                            <div class="form_group">
                              <textarea
                                value={summaryDes}
                                name="summary"
                                placeholder="Description"
                                // id="summary"
                                onChange={(e) => setsummary(e.target.value)}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      )
                        : ""}
                      {
                        form == 2 ? (
                          <div
                            className="formWrappers active"
                            style={{ display: "block" }}
                            id="stepsTwos"
                          >
                            <div
                              className="input-wrap flex"
                              style={{ marginBottom: "20px" }}
                            >
                              <label for="job_title">
                                Property Details <span>(optional)</span>
                              </label>
                              <div className="form_group">
                                <input
                                  type="text"
                                  autoComplete={false}
                                  value={plotSize}
                                  maxLength={10}
                                  onChange={(e) => setplotsize(e.target.value)}
                                  placeholder="Plot size in m2"
                                />
                              </div>
                            </div>
                            <div className="input-wrap flex">
                              <label for="job_title">
                                Features <span>(optional)</span>
                              </label>
                              <div className="form_group">
                                {/* <input type="text" placeholder="Location" /> */}

                                <Select
                                  isObject={false}
                                  options={locationDetails}
                                  isMulti={true}
                                  placeholder="Location"

                                  className="multi-select"
                                  onChange={(e) => {
                                    setnearLocation(e)
                                    // console.log(e[0].value)
                                  }}
                                  // selectedValues={multiTown}
                                  value={nearLocation}
                                />


                                {/* <Multiselect
                                className="multi-select"
                                placeholder="Location"
                                defaultValue="Select"
                                displayValue="Country"
                                selectedValues={nearLocation}
                                onSelect={(e) => setnearLocation([...e])}
                                // onChange={handleOnchange}
                                options={locationDetails}
                              /> */}
                              </div>
                              <div className="form_group">
                                <span>(optional)</span>

                                <Select
                                  isObject={false}
                                  options={poolDetails}
                                  isMulti={true}
                                  placeholder="Pool"

                                  className="multi-select"
                                  onChange={(e) => {
                                    setpool(e)
                                    // console.log(e[0].value)
                                  }}
                                  // selectedValues={multiTown}
                                  value={pool}
                                />


                                {/* <Multiselect
                                className="multi-select"
                                placeholder="Pool"
                                defaultValue="Town"
                                selectedValues={pool}
                                displayValue="Country"
                                onSelect={(e) => setpool([...e])}
                                // onChange={handleOnchange}
                                options={poolDetails}
                              /> */}
                                {/* <input type="text" placeholder="Pool" /> */}
                              </div>
                              <div className="form_group">
                                <span>(optional)</span>

                                {/* <input type="text" placeholder="Outside Space" /> */}

                                <Select
                                  isObject={false}
                                  options={outsideDetails}
                                  isMulti={true}
                                  placeholder="Outside Space"

                                  className="multi-select"
                                  onChange={(e) => {
                                    setoutside(e)
                                    // console.log(e[0].value)
                                  }}
                                  // selectedValues={multiTown}
                                  value={outside}
                                />


                                {/* <Multiselect
                                className="multi-select"
                                placeholder="Outside Space"
                                defaultValue="Town"
                                displayValue="Country"
                                selectedValues={outside}
                                onSelect={(e) => setoutside([...e])}
                                // onChange={handleOnchange}
                                options={outsideDetails}
                              /> */}
                              </div>
                              <div className="form_group">
                                <span>(optional)</span>
                                <Select
                                  isObject={false}
                                  options={tagsDetails}
                                  isMulti={true}
                                  placeholder="Tags"

                                  className="multi-select"
                                  onChange={(e) => {
                                    setTags(e)
                                    // console.log(e[0].value)
                                  }}
                                  // selectedValues={multiTown}
                                  value={tags}
                                />
                                {/* <Multiselect
                                className="multi-select"
                                placeholder="Tags"
                                defaultValue="Town"
                                displayValue="Country"
                                selectedValues={tags}
                                onSelect={(e) => setTags([...e])}
                                // onChange={handleOnchange}
                                options={tagsDetails}
                              /> */}
                                {/* <input type="text" placeholder="Tags" /> */}
                              </div>
                            </div>
                            <div className="input-wrap flex">
                              <label for="job_title">
                                Features <span>(optional)</span>
                              </label>
                              <div className="form_group">
                                <textarea
                                  name="summary"
                                  placeholder="Description"
                                  onChange={(e) =>
                                    setOptionalDesc(e.target.value)
                                  }
                                  defaultValue={OptionalDesc}
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        ) : ""}
                      {form == 3 ? (
                        <div
                          className="formWrappers active"
                          style={{ display: "block" }}
                          id="stepsThrees"
                        >
                          <div
                            className="input-wrap flex"
                            style={{ marginBottom: "20px" }}
                          >
                            <label for="job_title">SET MAP LOCATION</label>

                            <div className="form_group">
                              <div className="map_setpostion">
                                <Maps map_value={map_value} mapdata={mapdata} />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : ""}
                      {form == 4 ? (
                        <div
                          className="formWrappers active"
                          style={{ display: "block" }}
                          id="stepsFours"
                        >
                          <div className="input-wrap flex">
                            <label for="job_title">IMAGE GALLERY</label>
                            <div className="form_group">
                              <div className="upload__box">
                                <div className="upload__btn-box">
                                  <label className="upload__btn">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 384 512"
                                    >
                                      <path d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm65.18 216.01H224v80c0 8.84-7.16 16-16 16h-32c-8.84 0-16-7.16-16-16v-80H94.82c-14.28 0-21.41-17.29-11.27-27.36l96.42-95.7c6.65-6.61 17.39-6.61 24.04 0l96.42 95.7c10.15 10.07 3.03 27.36-11.25 27.36zM377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9z" />
                                    </svg>
                                    <input
                                      type="file"
                                      multiple
                                      id="imagefile"
                                      onChange={(e) => multiplefileChange(e)}
                                      data-max_length="20"
                                      className="upload__inputfile"
                                    />
                                  </label>
                                  <p>
                                    Maximum file size: 20 MB. Up to 50 files
                                    allowed.
                                  </p>
                                </div>
                                <div className="upload__img-wrap">
                                  {console.log(images, selectedimages)}
                                  {images[0]
                                    ? images.map((e) => {
                                      return (
                                        <>
                                          <span>
                                            <img
                                              style={{
                                                width: "200px",
                                                margin: "10px",
                                                height: "115px",
                                              }}
                                              src={e.url}
                                            />
                                          </span>
                                          <span
                                            className="delete_bt"
                                            onClick={() => {
                                              if (e.id) {
                                                delete_img(e.id)
                                              }
                                              deleteHandleImage2(e)
                                            }
                                            }
                                          >
                                            x
                                          </span>
                                        </>
                                      );
                                    })
                                    : ""}

                                  {Array.from(selectedimages).map(
                                    (item, index) => {
                                      return (
                                        <span
                                          key={index}
                                          className="list-item"
                                          draggable
                                          onDragStart={(e) =>
                                            (dragItem.current = index)
                                          }
                                          onDragEnter={(e) =>
                                            (dragOverItem.current = index)
                                          }
                                          onDragEnd={handleSort}
                                          onDragOver={(e) => e.preventDefault()}
                                        >
                                          <img
                                            style={{
                                              width: "220px",
                                              margin: "0 2px",
                                              height: "115px",
                                            }}
                                            src={
                                              item
                                                ? URL.createObjectURL(item)
                                                : null
                                            }
                                          />
                                          <span
                                            className="delete_bt"
                                            onClick={() => {
                                              deleteHandleImage(item)
                                              delete_img(item.id)
                                            }
                                            }
                                          >
                                            x
                                          </span>
                                        </span>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="button-wrapper">
                        <button
                          type="button"
                          className="prev"
                          onClick={() => {
                            handlePrev();
                            window.scrollTo(0, 0);
                          }}
                        >
                          Previous
                        </button>
                        {form == 4 ? (
                          selectedimages[0] || images[0] ? (
                            <button
                              type="button"
                              className="save"
                              onClick={(e) => {
                                submitForm(e);
                              }}
                            >
                              Submit
                            </button>
                          ) : (
                            <>
                              <button
                                type="button"
                                className="save"
                                onClick={(e) => {
                                  setErrorMessage("Select Image !");
                                  window.scrollTo(0, 0);
                                }}
                              >
                                Submit
                              </button>
                            </>
                          )
                        ) : (
                          <button
                            type="button"
                            onClick={() => {
                              window.scrollTo(0, 0);
                              handleNextform();
                            }}
                            className="save"
                          >
                            Save and Continue
                          </button>
                        )}
                      </div>
                      <div style={{ textAlign: "center", fontSize: "12px" }}>
                        {succes}
                      </div>
                      <div style={{ textAlign: "center", fontSize: "12px" }}>
                        {errorMessage}
                      </div>
                    </form>
                  </div>
                  <div className="multistep-form" id="toRent"></div>
                </div>
              </div>
            )}



          </section>
        )}

        {loading2 == true ? (

          <section className="call_popup active" style={{ display: "flex", alignItems: "center" }}>
            <div className="closeBtn" >

            </div>
            <div className="wrapper">
              <div className="content" style={{ textAlign: "center" }}>
                <p>Property Updating Please Wait !</p>
                <br />
                <div>
                  <img src={loaderImg} />
                </div>

              </div>
            </div>
          </section>

        ) : (
          ""
        )}
        {/* <!-- add listing type section end here --> */}
      </Layout>
    </>
  );
}

export default AgentPropertyEdit;
