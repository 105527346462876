import React, { useEffect, useState } from 'react';



function DragDrop() {
    const [fruitItems, setFruitItems] = React.useState([
        "Apple",
        "Banana",
        "Orange",
    ])

    const dragItem = React.useRef(null)
    const dragOverItem = React.useRef(null)

    const handleSort = () => {
        let _fruitItems = [...fruitItems]
        const draggedItemContent = _fruitItems.splice(dragItem.current, 1)[0]
        _fruitItems.splice(dragOverItem.current, 0, draggedItemContent)
        dragItem.current = null
        dragOverItem.current = null
        setFruitItems(_fruitItems)
    }

    useEffect(() => {
        console.log(fruitItems);
    }, [fruitItems])
    return (
        <div className="app">
            <h2>Fruit List</h2>
            <div className="list-container">
                {fruitItems.map((item, index) => (
                    <div
                        key={index}
                        className="list-item"
                        draggable
                        onDragStart={(e) => (dragItem.current = index)}
                        onDragEnter={(e) => (dragOverItem.current = index)}
                        onDragEnd={handleSort}
                        onDragOver={(e) => e.preventDefault()}>
                        <i className="fa-solid fa-bars"></i>
                        <h3>{item}</h3>
                    </div>
                ))}
            </div>
        </div>
    )
}
export default DragDrop;