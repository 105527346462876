import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import { baseUrl } from '../../baseUrl'


import swal from 'sweetalert';


const emailRegex = RegExp(
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
);



function Footer() {

    const [UserType, setUserType] = useState("User")
    const [userTypeData, setUserTypedata] = useState("User")
    const login = JSON.parse(localStorage.getItem("status"))
    const islogin = JSON.parse(localStorage.getItem("login"))
    const userinfo = JSON.parse(localStorage.getItem("user"))
    const navigate = useNavigate()
    const [showPopup, setshowPopup] = useState(false)
    const [message, setmessage] = useState("")
    const [message2, setmessage2] = useState("")
    const [numbererror1, setNumberError1] = useState("")
    const [numbererror2, setNumberError2] = useState("")
    // console.log(userTypeData, islogin);
    const { pathname } = useLocation()
    const [phone, setphone] = useState("")
    const [email2, setemail] = useState("")
    const [Name, setName] = useState("")
    const [lName, setLname] = useState("")
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" })
    }, [pathname])

    useEffect(() => {
        if (userinfo) {
            // setUserType(userinfo.user_type)
            if (userinfo.user_type == "User") {
                setUserType(userinfo.name)
            } else if (userinfo.user_type == "Agent") {
                setUserType(userinfo.username)

            }
        }

        if (userinfo) {
            setUserTypedata(userinfo.user_type)
        }


    }, [])

    const handletoTop = () => {
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: "smooth" })
        }, 2000);
    }


    const memberHandle = () => {

        // navigate("/agent-login")


        if (islogin == true) {
            if (userTypeData == "User") {
                localStorage.clear()
                window.scrollTo(0, 0)

                navigate("/agent-login")
            }
        } else if (islogin == true) {
            if (userTypeData == "Agent") {
                navigate("/agent-dashboard")
                window.scrollTo(0, 0)


            }
        } else if (islogin == null) {
            navigate("/agent-login")
            window.scrollTo(0, 0)

        }
    }

    const handleContact = () => {
        setshowPopup(!showPopup)
    }



    const handleSubmit = (event) => {
        event.preventDefault();
        const f_name = Name;
        const l_name = lName;
        const email = email2;
        const number = phone;
        const message = message2;



        const data = { f_name, l_name, email, number, message };
        if (f_name !== "" && phone != "" && email != "") {

            axios
                .post(baseUrl + "/contactus", data)
                .then((response) => {
                    if (response.status == 200) {
                        setshowPopup(false)
                        swal({
                            title: "Bella Islas says",
                            text: response.data.message,
                            icon: "success",
                            button: "OK",
                            className:"notranslate"
                            // timer: 3000,
                        });
                        setmessage(response.data.message)
                        setTimeout(() => {
                            setmessage("")

                        }, 3000);
                    }



                }).catch((error) => {
                    setmessage(error)
                    swal({
                        title: "Bella Islas says",
                        text: error.message,
                        icon: "error",
                        button: "OK",
                        className:"notranslate"
                        // timer: 3000,
                    });
                })




        } else {
            setmessage("Fill all the required fields!")
            setTimeout(() => {
                setmessage("")

            }, 3000);
        }






    }








    return (
        <>
            <footer>
                <div className="container-sm">
                    <figure className="footer-logo">
                        <a href="/"><img src={require("../../assets/images/footer-logo.png")} alt="" /></a>
                    </figure>
                    <div className="wrapper flex">
                        <div className="col" >
                            <h6 className='notranslate'>Bella Islas</h6>
                            <ul>
                                <li><a href="/aboutus" onClick={() => handletoTop()}><i aria-hidden="true" className="fas fa-angle-right"></i> About Us</a></li>
                                <li><a href="/blog" onClick={() => handletoTop()}><i aria-hidden="true" className="fas fa-angle-right"></i> Blog</a></li>

                                <button onClick={() => setshowPopup(true)} className="btns contact_btn">Contact Us</button>

                            </ul>
                        </div>
                        <div className="col">
                            <h6>{document.documentElement.lang == "es" ? <c class="notranslate">Compra y Venta</c> : "Buying & Selling"}</h6>
                            <ul>
                                <li><a href="/buy" onClick={() => handletoTop()}><i aria-hidden="true" className="fas fa-angle-right"></i> Property For Sale</a></li>
                                <li><a href="/" onClick={() => handletoTop()}><i aria-hidden="true" className="fas fa-angle-right"></i> Sell A Property</a></li>
                                <li ><a href="/buy-advice" onClick={() => handletoTop()}><i aria-hidden="true" className="fas fa-angle-right"></i> Buying Guide</a></li>
                            </ul>
                        </div>
                        <div className="col">
                            <h6>Renting & Letting</h6>
                            <ul>
                                <li ><a href="/rent" onClick={() => handletoTop()}><i aria-hidden="true" className="fas fa-angle-right"></i> {document.documentElement.lang == "es" ? <c class="notranslate">Inmuebles en alquiler</c> : <c>Property To Rent</c>}</a></li>
                                <li><a href="/" onClick={() => handletoTop()}><i aria-hidden="true" className="fas fa-angle-right"></i> Let A Property</a></li>
                            </ul>
                        </div>
                        <div className="col">
                            {
                                userTypeData &&
                                    userTypeData == "Agent" ? <a href="/agent-dashboard" className="btns_member" onClick={() => handletoTop()}>My Account</a> :

                                    <a href="/agent-login" onClick={() => {
                                        handletoTop()
                                        memberHandle()
                                    }} style={{ cursor: "pointer" }} className="btns_member">Members Sign In</a>

                            }
                            <ul>
                                <li ><a href="/agent-registration-form" target="_blank" onClick={() => handletoTop()}><i aria-hidden="true" className="fas fa-angle-right"></i> Agent Sign up</a></li>
                                <li ><a href="/why-us" onClick={() => handletoTop()} target="_blank"><i aria-hidden="true" className="fas fa-angle-right"></i> Why List Now?</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="copyright">
                        <p>Follow Us</p>
                        <ul className="social-icons flex">
                            <li><a href="https://www.facebook.com/bellaislasmallorca" target="_blank"><i className="fab fa-facebook"></i></a></li>
                            <li><a href="https://www.instagram.com/bellaislasmallorca" target="_blank"><i className="fab fa-instagram"></i></a></li>
                        </ul>
                        <ul className="bottom-links flex">
                            <li><a href="/privacy-policy" >Privacy Policy</a></li>
                            <li><a href="/user-terms">Terms Of Use</a></li>
                            <li><a href="/privacy-center" >Cookies</a></li>
                        </ul>
                    </div>
                </div>

                {showPopup == true ?
                    <div class="contact-popup active">
                        <div class="wrapper">
                            <div class="close" onClick={() => setshowPopup(false)}>
                                <i class="fa fa-times" aria-hidden="true"></i>
                            </div>
                            <div class="head">
                                <h3>Contact Us</h3>
                            </div>
                            <div class="content">
                                <form action="#" onSubmit={handleSubmit}>
                                    <div class="form_group half">
                                        <input type="text" placeholder="First Name" onChange={(e) => setName(e.target.value)} id='f_name' />
                                    </div>
                                    <div class="form_group half">
                                        <input type="text" placeholder="Last Name" onChange={(e) => setLname(e.target.value)} id='l_name' />
                                    </div>
                                    <div class="form_group half">
                                        <input type="tel" maxLength={13} placeholder="Phone Number" id='number' onChange={(e) => {
                                            if (isNaN(e.target.value)) {
                                                setNumberError1("Enter only numbers. ");
                                            } else {
                                                setNumberError1("");
                                                setphone(e.target.value)
                                            }
                                        }} />
                                        <span style={{ fontSize: "11px", color: "red", fontWeight: "500" }}>{numbererror1}</span>
                                    </div>
                                    <div class="form_group half">
                                        <input type="email" placeholder="Email" id='email' onChange={(e) => {
                                            if (emailRegex.test(e.target.value)) {
                                                setNumberError2("");
                                                setemail(e.target.value)
                                            } else {
                                                setNumberError2("Enter valid email !");
                                            }
                                        }} />
                                        <span style={{ fontSize: "11px", color: "red", fontWeight: "500" }}>{numbererror2}</span>
                                    </div>
                                    <div class="form_group">
                                        <textarea name="" placeholder="Reason For Enquiry" onChange={(e) => setmessage2(e.target.value)} id='message'></textarea>
                                    </div>
                                    <div class="form_group">
                                        <input type="checkbox" id="subscribe" name="subscribe" value="subscribe" />
                                        <label for="subscribe"> Subscribe to the lastest insights</label>
                                        <span style={{ fontSize: "12px", paddingLeft: "1rem", color: "red" }}> {message}</span>
                                    </div>
                                    <div class="form_group">
                                        <button type="submit">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div> : ""

                }








            </footer>






        </>
    )
}

export default Footer
