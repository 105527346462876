import React, { useEffect, useRef, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import BackgroundSlider from "react-background-slider";

import { NavLink, useNavigate } from "react-router-dom";
import img1 from "../../../assets/images/home-banner-img1.jpg";
import img2 from "../../../assets/images/home-banner-img2.jpg";
import img3 from "../../../assets/images/home-banner-img3.jpg";
import img4 from "../../../assets/images/home-banner-img4.jpg";
import img5 from "../../../assets/images/home-banner-img5.jpg";
import img from "../../../assets/images/home-banner-img.jpg";
import { baseUrl } from "../../../baseUrl";
import Slider from "react-slick";

function Homepage() {
  const [showSearch, setshowSearch] = useState(1);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    AOS.init();

    const loadPosts = async () => {
      setLoading(true);
      const response = await axios.get(
        "https://jsonplaceholder.typicode.com/posts"
      );
      setPosts(response.data);
      setLoading(false);
    };

    loadPosts();
  }, []);


  const handleBuy = () => {
    // console.log("submit");
    navigate(`/`);
  };

  useEffect(() => {
    const interval = setInterval(() => { }, 10000);

    return () => { };
  }, []);

  //const token = localStorage?.getItem("token") ? JSON.parse(localStorage.getItem("token")) : ""
  const [town, settown] = useState([]);
  const [town2, settown2] = useState([]);

  const towndata = [];
  town?.map((item) => towndata.push(item.town));
  //console.log(towndata);
  const fetchtownList = async () => {
    await axios
      .get(baseUrl + `/town-list`, {
        headers: { Authorization: `No Auth` },
      })
      .then((res) => {
        // console.log("townlist==", res);
        if (res.status == 200) {
          console.log(res.data.data);
          settown(res.data.data);
          settown2(res.data.data)
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  useEffect(() => {
    fetchtownList();
  }, [showSearch]);

  console.log(town);

  const handleSearch = (e) => {
    e.preventDefault();

    const type = e.target.type.value;
    const town = e.target.town.value;


    switch (type) {
      case "commercial":
        navigate(`/commercial/${town}`);

        break;
      case "buy":
        navigate(`/buy/${town}`);

        break;
      case "rent":
        navigate(`/rent/${town}`);

        break;
      default:
        break;
    }

  };

  const handlebuySearch = (e) => {
    const type = e.target.type.value;
    const town = e.target.town.value;

    navigate(`/buy/${town}`);
  };

  const images = [img1, img2, img3, img4, img5, img];
  const [currentSlide, setCurrentSlide] = useState(0);
  // useRef does not cause a re-render
  let sliderInterval = useRef();
  let switchImages = () => {
    if (currentSlide < images.length - 1) {
      setCurrentSlide(currentSlide + 1);
    } else {
      setCurrentSlide(0);
    }
  };

  const [searchValue, setsearchValue] = useState("");

  const onChangeS = (e) => {
    console.log("abc");
    setShow(true);
    setsearchValue(e.target.value);
    const filteredValues = town2.filter(value => value.town.toUpperCase().includes(e.target.value.toUpperCase()));
    const sortedValues = filteredValues.sort((a, b) => {
      const indexA = a.town.toUpperCase().indexOf(e.target.value.toUpperCase());
      const indexB = b.town.toUpperCase().indexOf(e.target.value.toUpperCase());

      if (indexA === 0 && indexB === 0) {
        return 0;
      } else if (indexA === 0) {
        return -1;
      } else if (indexB === 0) {
        return 1;
      } else {
        return 0;
      }
    });
    settown(sortedValues)
  };

  const hidesearchboxhandle = (elm) => {
    setsearchValue(elm);
    setShow(false);
  };

  useEffect(() => {
    sliderInterval = setInterval(() => {
      switchImages();
    }, 5000);
    return () => {
      clearInterval(sliderInterval);
    };
  });

  useEffect(() => {
    document.addEventListener("click", () => setShow(false))
  }, [])


  const settings = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 2200,
    arrows: false,
    fade: true,
    cssEase: 'linear',
  };






  return (
    <>
      <section className="home-banner" >
        <div className="home-slider ">

          <Slider {...settings}>

            <div>
              <figure>
                <img src={img1} alt="" />
              </figure>
            </div>
            <div>
              <figure>
                <img src={img2} alt="" />
              </figure>
            </div>
            <div>
              <figure>
                <img src={img3} alt="" />
              </figure>
            </div>
            <div>
              <figure>
                <img src={img4} alt="" />
              </figure>
            </div>
            <div>
              <figure>
                <img src={img5} alt="" />
              </figure>
            </div>


          </Slider>

        </div>
        <div className="banner-content">
          <h1>Mallorca properties at your fingertips</h1>
          <div className="bannerTab">
            <ul className="tab">
              <li
                data-id="ones"
                onClick={() => {
                  setsearchValue("")
                  setshowSearch(1);
                  setShow(true);

                }
                }
                className={`${showSearch === 1 ? "active_one" : ""}`}
              >
                For Sale
              </li>
              <li
                data-id="twos"
                onClick={() => {
                  setshowSearch(2);
                  setsearchValue("")
                  setShow(true);
                }
                }
                className={`${showSearch === 2 ? "active_one" : ""}`}
              >
                {document.documentElement.lang == "es" ? "Alquilar" : "To Rent"}
                
              </li>
              <li
                data-id="threes"
                onClick={() => {
                  setsearchValue("")
                  setshowSearch(3);
                  setShow(true);
                }
                }
                className={`${showSearch === 3 ? "active_one" : ""}`}
              >
                Commercial
              </li>
            </ul>
            <div className="tab-wrapper ">
              {/* // {showSearch === 1 ? ( */}
              <div
                className="tab-content"
                onSubmit={() => handleBuy()}
                style={showSearch === 1 ? { display: "block" } : {}}
                id="ones"
              >
                <form
                  action=""
                  onSubmit={(e) => handlebuySearch(e)}
                  className="flex flex-width"
                >
                  <div className="form_group"
                  >
                    <input
                      type="text"
                      value={searchValue}
                      onClick={e => {
                        setTimeout(() => {
                          onChangeS(e)
                        }, 80);
                        setsearchValue(" ")
                        setTimeout(() => {
                          setsearchValue("")
                        }, 50);
                      }}
                      onChange={onChangeS}
                      placeholder="Where do you want to buy?"
                    // id="town"
                    />
                    {searchValue == "" ? (
                      ""
                    ) : (
                      <span
                        className="clear_btn3"
                        onClick={() => setsearchValue("")}
                      >
                        x
                      </span>
                    )}

                  </div>
                  <div className="form_group">
                    <button
                      type="button"
                      onClick={() => navigate(`/buy/${searchValue}`)}
                    >
                      Search
                    </button>
                  </div>
                </form>

              </div>

              <div
                className="tab-content"
                style={showSearch === 2 ? { display: "block" } : {}}
                id="twos"
              >
                <form
                  action=""
                  onSubmit={(e) => handleSearch(e)}
                  className="flex flex-width"
                >
                  <div className="form_group">
                    <input
                      type="text"
                      value={searchValue}
                      onClick={e => {
                        setTimeout(() => {
                          onChangeS(e)
                        }, 80);
                        setsearchValue(" ")
                        setTimeout(() => {
                          setsearchValue("")
                        }, 50);
                      }}
                      onChange={onChangeS}
                      // id="town"
                      placeholder="Where do you want to rent?"
                    ></input>
                    {searchValue == "" ? (
                      ""
                    ) : (
                      <span
                        className="clear_btn3"
                        onClick={() => setsearchValue("")}
                      >
                        x
                      </span>
                    )}

                  </div>
                  <div className="form_group">
                    <button
                      type="button"
                      onClick={() => navigate(`/rent/${searchValue}`)}
                    >
                      Search
                    </button>
                  </div>
                </form>
              </div>
              <div
                className="tab-content"
                style={showSearch === 3 ? { display: "block" } : {}}
                id="threes"
              >
                <form
                  action=""
                  onSubmit={(e) => handleSearch(e)}
                  className="flex flex-width"
                >
                  <div className="form_group">
                    <input
                      type="text"
                      autoComplete="off"
                      aria-autocomplete="off"
                      value={searchValue}
                      onClick={e => {
                        setTimeout(() => {
                          onChangeS(e)
                        }, 80);
                        setsearchValue(" ")
                        setTimeout(() => {
                          setsearchValue("")
                        }, 50);
                      }}
                      onChange={onChangeS}
                      // id="town"
                      placeholder="Search for a commercial property."
                    />
                    {searchValue == "" ? (
                      ""
                    ) : (
                      <span
                        className="clear_btn3"
                        onClick={() => setsearchValue("")}
                      >
                        x
                      </span>
                    )}
                    {/* <input
                        style={{ display: "none" }}
                        type="text"
                        value="commercial"
                        id="type"
                      /> */}
                  </div>
                  <div className="form_group">
                    <button
                      type="button"
                      onClick={() => navigate(`/commercial/${searchValue}`)}
                    >
                      Search
                    </button>
                  </div>
                </form>
              </div>
              {/* )} */}

              <div className={`${show == true ? "search_country2" : ""}`}>
                {show === true
                  ? town?.map((item) => (
                    <p
                      className="notranslate"
                      style={{ fontWeight: "400", fontSize: "14px" }}
                      key={item.id}
                      onClick={() => hidesearchboxhandle(item.town)}
                    >
                      {item.town}
                    </p>
                  ))
                  : ""}
              </div>
            </div>
          </div>
        </div>
        <div className="downBtn">
          <a href="#home-service">
            <img
              src={require("../../../assets/images/down-arrow.png")}
              alt=""
            />
          </a>
        </div>
      </section>
    </>
  );
}

export default Homepage;
