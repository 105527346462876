import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";

// import "../../Styles/style3.css";
import axios from "axios";
import { baseUrl } from "../../baseUrl";
import { useParams } from "react-router-dom";

function Codeofconduct() {
  const { id } = useParams();
  console.log(id);

  const [loading, setloading] = useState(false);

  const [privacy, setprivacy] = useState([]);
  //   console.log("pppp", privacy[0]);
  const fetchtownList = async () => {
    await axios
      .get(baseUrl + `/cookies?id=${id ? id : 3}`, {
        headers: { Authorization: `No Auth` },
      })
      .then((res) => {
        console.log("townlist==", res);
        if (res.status == 200) {
          setprivacy(res.data.data);
          setloading(true);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error) {
          setloading(true);
        }
      });
  };

  useEffect(() => {
    fetchtownList();
  }, []);

  var des = privacy[0] ? privacy[0].description : "";

  console.log(des);

  var data = [];

  return (
    <Layout>
        
      <section className="comman-contents">
        <div className="container-sm">
          <div className="center-heading">
            <h1>Code Of Conduct</h1>
          </div>
          <div className="inner-content">
            <div dangerouslySetInnerHTML={{ __html: des }} />
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default Codeofconduct;
