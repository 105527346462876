import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { baseUrl } from "../../baseUrl";
import Layout from "../Layout/Layout";
import "../../Styles/allmin.css";
import "../../Styles/mainstyle/style.css";
const emailRegex = RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
function ForgotPassword() {
  const [checkError, setcheckError] = useState("Please Click on Check Box");
  const [loginError, setloginError] = useState();
  const navigate = useNavigate();
  const [success, setSucces] = useState("");
  const [formShow, setformShow] = useState();
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");
  const [verify_token, setverify_token] = useState("");
  // sessionStorage.clear()

  console.log(success, status);
  const checkedHandle = (e) => {
    if (e.target.checked === true) {
      setcheckError("");
    } else if (e.target.checked === false) {
      setcheckError("Please Click on Check Box");
    }
  };

  const [timer, setTimer] = useState(30);
  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => currTimer - 1),
    []
  );

  useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 2000);
  }, [timer, timeOutCallback]);

  console.log(timer);

  const resetTimer = function () {
    if (!timer) {
      setTimer(30);
    }
  };

  const submitHandler = (event) => {
    event.preventDefault();
    const email = event.target.email.value;
    const data = { email };
    if (emailRegex.test(email)) {
      axios
        .post(baseUrl + "/user/forgotpassword", data)
        .then((response) => {
          console.log(" resp ", response);
          event.target.reset();
          if (response.status === 200) {
            setEmail(email);

            setSucces(response.data.message);
            if (response) {
              setformShow(response.status);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          if (error) {
            // alert(error.message)
            setloginError(
              "Your email id not register!"
            );
          } else {
            setloginError("");
          }
        });
    } else {
      setloginError(
        "Wrong email format!"
      );
    }
  };

  const submitOtp = (e) => {
    e.preventDefault();
    const otp = e.target.otp.value;
    const email = e.target.emailC.value;
    const data = { email, otp };
    console.log(data);
    axios
      .post(baseUrl + "/user/forgotpassword-otp", data, {
        headers: { Authorization: "No Auth" },
      })
      .then((response) => {
        console.log(" resp ", response);
        // e.target.reset();

        if (response.status === 200) {
          //   setformShow();
          navigate(`/createNewpassword/${response.data.verify_token}`);
          setSucces(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error) {
          // alert(error.message)
          setloginError(
            "Fill all the required fields!"
          );
        } else {
          setloginError("");
        }
      });
  };

  const createNewPass = (e) => {
    e.preventDefault();
    const password = e.target.password.value;
    const confirm_password = e.target.emailC.value;
    const data = { verify_token, password, confirm_password };
    console.log(data);
    axios
      .post(baseUrl + "/user/forgotpassword-otp", data, {
        headers: { Authorization: "No Auth" },
      })
      .then((response) => {
        console.log(" resp ", response);
        // e.target.reset();
        if (response.status === 200) {
          //   setformShow();
          if (response) {
            setStatus(response.status);
          }
          setSucces(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error) {
          // alert(error.message)
          setloginError("Fill all the required fields!");
        } else {
          setloginError("");
        }
      });
  };




  const resendOtp = (email) => {
    // event.preventDefault();
    // const email = event.target.emailC.value;
    const data = { email };
    resetTimer()
    axios
      .post(baseUrl + "/user/resend-otp", data)
      .then((response) => {
        console.log(" resp ", response);
        // event.target.reset();
        if (response.status === 200) {
          setEmail(email);

          setSucces(response.data.message);
          if (response) {
            setformShow(response.status);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        if (error) {
          // alert(error.message)
          setloginError(
            "Fill all the required fields!"
          );
        } else {
          setloginError("");
        }
      });
  };
  return (
    <>
      <Layout>
        <section className="account-edit lost_password">
          <div className="container-sm">
            <div className="wrapper">
              <div className="head flex">
                <div className="icon">
                  <img src="app/images/lock-icon.png" alt="" />
                </div>
                <h5>Lost your password?</h5>
              </div>
              <p>
                Lost your password? Please enter your username or email address.
                You will receive a link to create a new password via email.
              </p>

              {formShow == "200" ? (
                <>
                  <form className="editProfile" onSubmit={(e) => submitOtp(e)}>
                    <div className="form_group">
                      <div style={{ display: "none" }}>
                        <label for="userNames">Username or email</label>
                        <input
                          type="text"
                          name="email"
                          value={email}
                          id="emailC"
                        />
                      </div>

                      <label for="otp">Enter Your OTP</label>
                      <input type="text" name="otp" id="otp" />

                      <div>
                        <button type="button" onClick={() => resendOtp(email)} >Resend OTP {timer}</button>
                      </div>
                    </div>
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: "300",
                        paddingBottom: "1rem",
                        textAlign: "center",
                      }}
                    >
                      {success}
                    </div>
                    <div className="form_group">
                      <button type="submit">Confirm</button>
                    </div>
                  </form>
                </>
              ) : (
                <>
                  <form
                    action=""
                    className="editProfile"
                    onSubmit={(e) => submitHandler(e)}
                  >
                    <div className="form_group">
                      <label for="userNames">Username or email</label>
                      <input type="text" name="userNames" id="email" />
                    </div>

                    <div className="form_group">
                      <button type="submit">Reset password</button>
                    </div>
                    <div style={{ fontSize: "12px", paddingBottom: "12px" }}>
                      {success}
                    </div>
                    <div style={{ fontSize: "12px", paddingBottom: "12px", color: "red" }}>
                      {loginError}
                    </div>
                  </form>
                </>
              )}
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
}

export default ForgotPassword;
