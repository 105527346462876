import axios from "axios";
import React from "react";
import { useState } from "react";
import { baseUrl } from "../../baseUrl";
import Layout from "../Layout/Layout";
import swal from "sweetalert";
const emailRegex = RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);

function AgentRegistration() {
  const [message, setmessage] = useState("");
  const [errorMessage, setErrorMess] = useState("");
  //const [loading set]

  console.log(errorMessage, message);
  const [Message, setMessage] = useState("");
  const [CallAgentlName, setCallAgentlName] = useState("");
  const [CallAgentName, setCallAgentName] = useState("");
  const [CallAgentEmail, setCallAgentEmail] = useState("");
  const [CallAgentPhone, setCallAgentPhone] = useState("");
  const [CallcompanyName, setcompanyName] = useState("");
  const [CallLocation, setLocation] = useState("");
  //const [CallAgentPhone, setCallAgentPhone] = useState("")
  const [error2, seterrorfield] = useState({
    username: "",
    email: "",
    password: "",
    phone: "",
    resetemailadd: "",
    companyName: "",
    message: "",
    location: "",
  });

  console.log(CallAgentEmail, error2.email);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const f_name = CallAgentName;
    const l_name = CallAgentlName;
    const email = CallAgentEmail;
    const phone = CallAgentPhone;
    const company = CallcompanyName;
    const location = CallLocation;
    const details = Message;
    const data = { f_name, l_name, email, phone, company, location, details };
    console.log(data);
    if (
      f_name !== "" &&
      email !== "" &&
      phone != "" &&
      company != "" &&
      location != "" &&
      details != ""
    ) {
      await axios
        .post(baseUrl + "/agent/enquiry", data)
        .then((res) => {
          console.log(res.data);
          //event.target.reset();

          if (res.status == 200) {
            // setmessage("Thanks for your enquiry, We will get back to you soon!");
            swal({
              title: "Bella Islas says",
              text: "Thanks for your enquiry, We will get back to you soon!",
              icon: "success",
              button: "OK",
              // timer: 3000,
            });

            setCallAgentName("");
            setCallAgentlName("");
            setCallAgentEmail("");
            setCallAgentPhone("");
            setCallAgentlName("");
            setcompanyName("");
            setMessage("");
            setLocation("");



          }
        })
        .catch((error) => {
          console.log(error);
          swal({
            title: "Bella Islas says",
            text: error.message,
            icon: "success",
            button: "OK",
            // timer: 3000,
          });
        });
    } else {

      setErrorMess("Fill all the required fields!")
      setTimeout(() => {
        setErrorMess("")
      }, 2000);
    }
  };

  const logins_field = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "name":
        error2.username = value.length > 4 ? "" : "Enter Valid Name !";
        break;
      case "phone":
        error2.phone = isNaN(value) ? "Enter A Valid Number !" : "";
        break;
      case "email":
        error2.resetemailadd = emailRegex.test(value) ? "" : "Email not valid.";
        break;
      case "location":
        error2.location = value ? "" : "Enter valid Location !";
        break;
      case "company":
        error2.companyName = value ? "" : "Enter Company Name!";
        break;
      case "message":
        error2.message = value ? "" : "";
        break;

      default:
        break;
    }
    if (name == "name") {
      setCallAgentName(value);
    }
    if (name == "l_name") {
      setCallAgentlName(value);
    }
    if (name == "phone") {
      setCallAgentPhone(value);
    }
    if (name == "email") {
      setCallAgentEmail(value);
    }
    if (name == "company") {
      setcompanyName(value);
    }
    if (name == "message") {
      setMessage(value);
    }
    if (name == "location") {
      setLocation(value);
    }
    //seterror2(error2)
  };

  return (
    <Layout>
      {/* <!-- login section start here --> */}
      <section className="login-section registration">
        <div className="container-sm">
          <div className="wrapper">
            <h2>Agent Signup Form</h2>
            <form onSubmit={handleSubmit} className="user-login">
              <div className="form_group">
                <input
                  type="text"
                  id="f_name"
                  placeholder="First Name *"
                  onChange={(e) => logins_field(e)}
                  value={CallAgentName}
                  name="name"
                  style={
                    error2.username != ""
                      ? { borderBottom: "2px solid red" }
                      : {}
                  }
                />
              </div>
              <div className="form_group">
                <input type="text" id="l_name" placeholder="Last Name *" onChange={(e) => logins_field(e)}
                  value={CallAgentlName}
                  name="l_name"
                />
              </div>
              <div className="form_group">
                <input
                  type="email"
                  id="email"
                  placeholder="Email Id *"
                  onChange={(e) => logins_field(e)}
                  value={CallAgentEmail}
                  name="email"
                  style={
                    error2.resetemailadd != ""
                      ? { borderBottom: "2px solid red" }
                      : {}
                  }
                />
                <div
                  style={{
                    textAlign: "center",
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  {error2.resetemailadd}
                </div>
              </div>
              <div className="form_group">
                <input
                  type="text"
                  id="phone"
                  placeholder="Phone No *"
                  maxLength={13}
                  onChange={(e) => logins_field(e)}
                  value={CallAgentPhone}
                  name="phone"
                  style={
                    error2.phone != "" ? { borderBottom: "2px solid red" } : {}
                  }
                />
                <div
                  style={{
                    textAlign: "center",
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  {error2.phone}
                </div>
              </div>
              <div className="form_group">
                <input
                  type="text"
                  id="company"
                  placeholder="Company *"
                  onChange={(e) => logins_field(e)}
                  value={CallcompanyName}
                  name="company"
                  style={
                    error2.companyName != ""
                      ? { borderBottom: "2px solid red" }
                      : {}
                  }
                />
                <div
                  style={{
                    textAlign: "center",
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  {error2.companyName}
                </div>
              </div>
              <div className="form_group">
                <input
                  type="text"
                  id="location"
                  placeholder="Location *"
                  onChange={(e) => logins_field(e)}
                  value={CallLocation}
                  name="location"
                  style={
                    error2.location != ""
                      ? { borderBottom: "2px solid red" }
                      : {}
                  }
                />
                <div
                  style={{
                    textAlign: "center",
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  {error2.location}
                </div>
              </div>
              <div className="form_group" style={{marginRight:"0"}}>
                <textarea
                  id="message"
                  autoComplete="off"
                  placeholder="Enter Your Details *"
                  onChange={(e) => logins_field(e)}
                  value={Message}
                  name="message"
                  style={
                    error2.message != ""
                      ? { borderBottom: "2px solid red" }
                      : {}
                  }
                ></textarea>
                <div
                  style={{
                    textAlign: "center",
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  {error2.message}
                </div>
              </div>
              <div className="form_group">
                <button type="submit">Submit</button>
              </div>
            </form>
            <div
              style={{
                fontSize: "15px",
                textAlign: "center",
                fontWeight: "400",
              }}
            >
              {message}
            </div>
            <div
              style={{
                fontSize: "15px",
                textAlign: "center",
                fontWeight: "400",
                color: "red"
              }}
            >
              {errorMessage}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- login section end here --> */}
    </Layout>
  );
}

export default AgentRegistration;
