import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import img1 from "../../assets/images/the-island-img.jpeg";
import img2 from "../../assets/images/tabing-left-1-img.jpg";
import img3 from "../../assets/images/blog-detail-banner.jpg";
import { NavLink, useParams } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../baseUrl";
import loader from "../../assets/images/loader.svg";

function BlogDetails() {
  const { id } = useParams();

  console.log(id);
  const [blog, setblog] = useState();
  const [loading, setloading] = useState(false);
  const [resent, setRecent] = useState();
  const [itemid, setitemid] = useState("");
  console.log(blog);

  const fetchblogdata = async () => {
    await axios
      .get(baseUrl + `/blog/blog-list?id=${id}`, {
        headers: { Authorization: `No Auth` },
      })
      .then((res) => {
        if (res.status == 200) {
          setloading(true);
        }
        console.log(res);
        setblog(res.data);

        if (res.data.message) {
          setRecent(res.data.message);
        }
      })
      .catch((error) => {
        if (error) {
          setloading(true);
        }
        console.log(error);
      });
  };

  useEffect(() => {
    fetchblogdata();
  }, [itemid]);

  return (
    <>
      <Layout>
        {loading !== true ? (
          <div className="loader_set">
            {" "}
            <img src={loader} alt="loading..." />{" "}
          </div>
        ) : (
          <>
            <section class="blogBanner">
              {/* {
        blog.data?.map((item ,index)=)
       } */}
              <div class="container-sm">
                <h1>{blog.data[0]?.title}</h1>
                <figure>
                  <img src={blog.data[0]?.file_path} alt="img..." />
                </figure>
              </div>
            </section>

            {/* <!-- blog details section start here --> */}
            <section className="blogDetails">
              <div className="container-sm">
                <div className="wrap flex">
                  <div className="col">
                    <ul>
                      <li>
                        <a href="#" target="_blank">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" />
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a href="#" target="_blank">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
                          </svg>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col">
                    <p>{blog.data[0]?.short_desc}</p>
                    <h3>{blog.data[0]?.title}</h3>
                    <p dangerouslySetInnerHTML={{ __html: blog.data[0]?.description }}></p>

                    {blog.data[0]?.title == "Mallorca Properties" ? (
                      <>
                        <h3>Mallorca Properties: Rentals through an agent</h3>
                        <p>
                          To make sure that renting a property in Mallorca is
                          not a game of chance, you should rely on the know-how
                          of experienced estate agents. The experts are very
                          familiar with the property market on the Balearic
                          Island and can usually find the property that you want
                          within a very short time. Upon request, the estate
                          agents can provide you with all the{" "}
                          <a
                            href="https://www.mallorca.com/en/holiday/mallorca-travel-information"
                            target="_blank"
                          >
                            information
                          </a>{" "}
                          on the current market situation and give an assessment
                          of the development of rental rates in Mallorca. Estate
                          agents in Mallorca will look after the planning, legal
                          and professional side of the rental and are available
                          after the conclusion of the lease as a contact for any
                          uncertainties that may arise.
                        </p>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </section>
            {/* <!-- blog details section end here -->

    <!-- blog list section start here --> */}
            <section className="blog-list second">
              <div className="container-sm">
                <h2>Recent News</h2>
                <ul>
                  {resent.recent_news?.map((item, index) => {
                    return (
                      <li className="flex" key={index}>
                        <figure>
                          <NavLink
                            to={`/blog-details/${item.id}`}
                            onClick={() => {
                              setitemid(item.id);
                            }}
                          >
                            <img src={item.file_path} alt="img.." />
                          </NavLink>
                        </figure>
                        <div className="content">
                          <h3>
                            <NavLink to={`/blog-details/${item.id}`}>
                              {item.title}
                            </NavLink>
                          </h3>
                          <p>{item.short_desc}</p>
                          <NavLink
                            to={`/blog-details/${item.id}`}
                            onClick={() => {
                              setitemid(item.id);
                            }}
                            className="readMore"
                          >
                            Read More
                          </NavLink>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </section>
            {/* <!-- blog list section end here --> */}
          </>
        )}
      </Layout>
    </>
  );
}

export default BlogDetails;
