import React from "react";
import Slider from "react-slick";
import Layout from "../../Layout/Layout";
import bed from "../../../assets/images/single-bed.png";
import bathroom_size from "../../../assets/images/bathrooms_size.png";
import bath from "../../../assets/images/bath.png";
import { useEffect } from "react";
import { useState } from "react";
import { baseUrl } from "../../../baseUrl";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import PremiumSection from "../SearchSections/PremiumSection";
import swal from 'sweetalert';

function CommercialPage(props) {
  const [loader, setLoader] = useState(false);
  const detials = props.details;
  const total = props.total;
  //const [sorting, setshorting] = useState(detials);
  const town = props.town;
  const navigate = useNavigate();
  const premiumDetails = props.premiumDetails;
  const setshorting = props.setshorting;
  const [AddFav, setAddFav] = useState(false);
  const shorting = props.shorting;

  console.log("ffffff>>>>>", premiumDetails);
  // const location = props.location
  const error = props.error;
  const [p_Id, setP_Id] = useState("");
  const [addSucces, setaddSucces] = useState("");
  // console.log("property_id", );
  const property = props.totalResult;
  const token = JSON.parse(localStorage.getItem("token"));
  const islogin = JSON.parse(localStorage.getItem("login"));

  const postFavourite = async (property_id) => {
    // e.preventDefault();

    const body = { property_id: property_id };
    const res = await axios.post(
      baseUrl + "/Add/favourite-property",
      body,

      { headers: { Authorization: `Bearer ${token}` } }
    );
    const data = await res.data;
    if (res.status == 200) {
      setAddFav(true)
    }
    // console.log(data,islogin, property_id);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,

    slidesToScroll: 1,
  };

  const passId = (id) => {
    navigate(`/property-details/${id}`);
  };

  // const filterDetails = detials?.filter(
  //   (item) =>
  //     item.data.price_freq === "month" || item.data.price_freq === "week"
  // );
  //console.log(  setshorting(detials.sort((a,b)=> a.price-b.price)))

  const shortbyprice = () => {
    detials.sort((a, b) => b.data.price - a.data.price);
  };

  let nf = new Intl.NumberFormat();

  const handleFilter = (e) => {
    console.log(e.target.value, "filtered");

    setshorting(e.target.value);
  };


  const handlenewtab = (event) => {
    console.log(event);
    if (event.ctrlKey) {
      window.open(`/rent`, "_blank")
    }
  }
  return (
    <>
      <section className="fillter_res">
        <div className="container_">
          <div className="top_bar flex">
            <h5>
              {town !== "" ? town + " :" : ""}
              {"     "}
              {total?.total !== "" ? total?.total : "0"} Properties results
            </h5>
            <div className="result-fillter">
              <label for="sort">Sort:</label>
              <select
                id="sortType"
                onChange={(e) => handleFilter(e)}
                name="sort"
                className="select sort-select"
                value={shorting == "low" ? "low" : shorting == "high" ? "high" : shorting == "new" ? "new" : shorting == "old" ? "old" : "new"}
              >
                {/* <option value="">Select option</option> */}

                <option value="low">Lowest Price</option>
                <option value="high" >
                  Highest Price
                </option>
                <option value="new">Newest Listed</option>
                <option value="old">Oldest Listed</option>
              </select>
            </div>
          </div>
          {
            premiumDetails[0] ?
              <PremiumSection premiumDetails={premiumDetails} />
              : ""
          }
          {/* <h3 className="top_heading"> Premium Featured-New to the market </h3> */}
          <h1>Available Listing</h1>
          <div style={{ textAlign: "center", display: "none" }}>{error}</div>
          {detials?.map((item, index) => {
            var type2 = JSON.stringify(item.data?.type).replace(
              /[\\[,"\]']+/g,
              "  "
            );

            return (
              <div className="listings flex" key={item.data.id} onClick={(e) => handlenewtab(e)}>
                <div className="col_">
                  <div className="bookmark-slider_mob ">
                    <div className="slickArrow bookmark-slider_mob bookmark-slider list-img-slider">
                      <Slider {...settings}>
                        {item.data?.images.image !== undefined
                          ? item.data?.images.image.map((img, i) => {
                            return (
                              <div key={i}>
                                <figure
                                  style={{ cursor: "pointer" }}
                                  onClick={() => passId(item.property_id)}
                                >
                                  <img src={img.url} alt="loading img..." />
                                </figure>
                              </div>
                            );
                          })
                          : ""}
                      </Slider>
                    </div>
                  </div>
                </div>
                <div
                  className="col_"
                  onClick={() => passId(item.property_id)}
                  style={{ cursor: "pointer" }}
                >
                  <div className="listDataContainer">
                    <a>
                      <div className="listDataOuter">
                        <div
                          className="listingtitle"
                          style={{ cursor: "pointer" }}
                          onClick={() => passId(item.property_id)}
                        >
                          {item.data?.beds} bedroom {type2 ? type2 : ""} For{" "}
                          {item.data?.price_freq}
                        </div>
                        <div className="notranslate pricesection">
                          <span className="price">
                            <b>
                              €{" "}
                              {item?.data.price
                                ? nf.format(item?.data.price)
                                : ""}
                            </b>{" "}
                            {/* {item.data?.price_freq} */}
                          </span>
                          <span className="bedrooms">
                            {item.data?.beds} <img src={bed} alt="logo" />{" "}
                          </span>
                          <span className="bathrooms">
                            {item.data?.baths} <img src={bath} />{" "}
                          </span>
                          <span className="bathrooms_size">
                            {item.data?.surface_area?.built == null ? (
                              ""
                            ) : (
                              <>
                                <img src={bathroom_size} alt="" />
                                <span>
                                  {item.data?.surface_area.built
                                    ? item.data.surface_area.built
                                    : ""}
                                  m<sup>2</sup>{" "}
                                </span>
                              </>
                            )}
                          </span>
                        </div>

                        <div className="listingaddress">
                          <i
                            aria-hidden="true"
                            className="fas fa-map-marker-alt"
                          ></i>
                          {item.data?.town?.replace(/[\[,"\]']+/g, " | ")
                            ? item.data?.town?.replace(/[\[,"\]']+/g, " | ")
                            : ""}
                        </div>
                      </div>
                    </a>
                    <div className="listDataRight">
                      <div
                        // className={`${addSucces === true ? "fav_add_btn" : ""}`}
                        // type="submit"
                        onClick={() => {
                          if (islogin) {
                            postFavourite(item.property_id);
                          } else {
                            swal({
                              title: "Bella Islas says",
                              text: "Please Log in First !",
                              icon: "error",
                              button: "OK",
                              // timer: 3000,
                            });
                          }
                        }}
                      >
                        {" "}
                        {addSucces == item.property_id && AddFav == true ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                          >
                            <path fill="none" d="M0 0H24V24H0z" />
                            <path
                              d="M12.001 4.529c2.349-2.109 5.979-2.039 8.242.228 2.262 2.268 2.34 5.88.236 8.236l-8.48 8.492-8.478-8.492c-2.104-2.356-2.025-5.974.236-8.236 2.265-2.264 5.888-2.34 8.244-.228z"
                              fill="rgba(255,140,0,1)"
                            />
                          </svg>
                        ) : (
                          // <svg
                          //   xmlns="http://www.w3.org/2000/svg"
                          //   onClick={() => setaddSucces(item.property_id)}

                          //   viewBox="0 0 24 24"
                          //   width="24"
                          //   height="24"
                          // >
                          //   <path fill="none" d="M0 0H24V24H0z" />
                          //   <path d="M12.001 4.529c2.349-2.109 5.979-2.039 8.242.228 2.262 2.268 2.34 5.88.236 8.236l-8.48 8.492-8.478-8.492c-2.104-2.356-2.025-5.974.236-8.236 2.265-2.264 5.888-2.34 8.244-.228zm6.826 1.641c-1.5-1.502-3.92-1.563-5.49-.153l-1.335 1.198-1.336-1.197c-1.575-1.412-3.99-1.35-5.494.154-1.49 1.49-1.565 3.875-.192 5.451L12 18.654l7.02-7.03c1.374-1.577 1.299-3.959-.193-5.454z" />
                          // </svg>
                          <span
                            className="heart_hover"
                            onClick={() => setaddSucces(item.property_id)}
                          >
                            <i class="far fa-heart"></i>
                          </span>
                        )}
                        {/* <i onClick={()=> setaddSucces(true)} className="far fa-heart"></i>{" "} */}
                      </div>
                    </div>
                  </div>
                  <div className="agentname">
                    <div>
                      <p className="eng_desFont">
                      {item.english_description?.substring(0, window.innerWidth < "750" ? 100 : 200)}
                      </p>
                    </div>
                    <div className="wrap flex">
                      <p className="notranslate">
                        <NavLink to={`/property-details/${item.property_id}`}>
                          {item?.company_name}
                        </NavLink>
                      </p>
                      <figure>
                        <a href="#">
                          <img
                            src={require("../../../assets/images/1.png")}
                            alt=""
                          />
                        </a>
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
}

export default CommercialPage;
