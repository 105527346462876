import React, { Component } from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

export class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showingInfoWindow: false,
      activeMarker: true,
      selectedPlace: true,
      check: false,

      mapCenter: {
        lat: props.lat ? props.lat : this.props.address == "Campos" ? "-21.7615" : this.props.address == "Dolores" ? "37.473331" : "",
        lng: props.long ? props.long : this.props.address == "Campos" ? "-41.3168" : this.props.address == "Dolores" ? "-108.503059" : ""
      }
    };
  }

  handleChange = address => {
    geocodeByAddress(this.props.address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        this.setState({ mapCenter: latLng });
        console.log(latLng);
      })

  };

  render() {
    if (this.props.address && this.state.check == false) {
      this.handleChange()
      this.setState({ check: true });
    }
    console.log(this.props);
    return (
      <div id='googleMaps'>
        <Map
          google={this.props.google}
          initialCenter={{
            lat: this.state.mapCenter.lat,
            lng: this.state.mapCenter.lng
          }}
          center={{
            lat: this.state.mapCenter.lat,
            lng: this.state.mapCenter.lng
          }}
          zoom={15}
        >
          <Marker
            position={{
              lat: this.state.mapCenter.lat,
              lng: this.state.mapCenter.lng
            }} />
        </Map>
      </div>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: ('AIzaSyBBzYVXpIo9n-DmcWMBeDBW1BVwpnZB7yI')
})(MapContainer)