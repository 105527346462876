import axios from 'axios'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { baseUrl } from '../../../baseUrl'

import { exploredata } from '../../../Data/Data'

function ExploreSection() {


  const [loading, setloading] = useState(false);

  const [mallorca, setMallorca] = useState([]);


 

  const fetchtownList = async () => {
    await axios
      .get(baseUrl + `/mallorca-list?id=25`, {
        headers: { Authorization: `No Auth` },
      })
      .then((res) => {
        console.log("townlist==", res);
        if (res.status == 200) {
          setMallorca(res.data.data);
          setloading(true);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error) {
          setloading(true);
        }
      });
  };

  useEffect(() => {
    fetchtownList();
  }, []);







  return (
    <>
      <section className="explore-section notranslate" >
        <div className="container-sm">
          <div className="heading-line" data-aos="fade-in">
            <h3 class="notranslate">Explore Mallorca</h3>
            <NavLink to="/mallorca" class="notranslate">View All Areas</NavLink>
          </div>
          <ul className="flex">

            {
              exploredata.map((item, index) => {
                return (


                  <li data-aos="fade-up" key={index}>
                    <figure>
                      <a href={`/mallorca/${item.id}`}>
                        <img src={item.img} alt="img loading..." />
                        <div className="figcaption">
                          <h4 class="notranslate">{item.heading}</h4>
                        </div>
                      </a>
                    </figure>
                  </li>

                )
              })
            }


          </ul>
        </div>
      </section>
    </>
  )
}

export default ExploreSection
