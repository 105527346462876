import React, { useEffect, useRef, useState } from "react";
import { Route } from "../../Data/route";
import "../../Styles/main.css";
import HeaderLogo from "../../assets/images/header-logo.png";
import HeaderLogoBlue from "../../assets/images/header-logo-blue.png";
import { NavLink, useParams } from "react-router-dom";
import "../../Styles/header.css";

import loaderImg from "../../assets/images/loader.svg";






function Navbar() {
  const [UserType, setUserType] = useState("");
  const [userTypeData, setUserTypedata] = useState("User");
  const [toggelBtnHandler, settoggelBtnHandler] = useState(false);
  const [language, setlanguage] = useState(0);
  // const { id } = useParams()
  // console.log(id);
  const [loading, setloading] = useState(true)
  const login = JSON.parse(localStorage.getItem("status"));
  const islogin = JSON.parse(localStorage.getItem("login"));
  const userinfo = JSON.parse(localStorage.getItem("user"));

  // console.log(userinfo ,"<<<<<<<<");
  useEffect(() => {
    if (userinfo) {
      // setUserType(userinfo.user_type)
      if (userinfo.user_type == "User") {
        setUserType(userinfo.name);
      } else if (userinfo.user_type == "Agent") {
        setUserType(userinfo.username);
      }
    }

    if (userinfo) {
      setUserTypedata(userinfo?.user_type ? userinfo?.user_type : "User");
    }
  }, []);


  const [check, setcheck] = useState(false)
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        // pageLanguage: "en",
        includedLanguages: 'en,fr,de,es,en,fr,de,es,',
      },
      "google_translate_element"
    );

  };

  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
    setcheck(true)
  }, []);

  const lanref = useRef(null)

  useEffect(() => {
    if (language == 2) {
      setloading(false)
      setTimeout(() => {
        window.location.reload()
      }, 200);
    }
  }, [language,loading])


  return (
    <>
      {loading !== true ? (
        <div className="loader_set loader_set2">
          {" "}
          <img src={loaderImg} alt="loading..." />{" "}
        </div>
      ) : ""}
      <div
        className="backGround_click2"
        style={toggelBtnHandler == false ? { display: "none" } : {}}
        onClick={() => settoggelBtnHandler(false)}
        id="backGround_click"
      ></div>
      <div className="max_wi">
        <header className="home flex navbar_maxW  ">
          <div className="container_ flex">
            <figure className="logo">
              <NavLink to="/">
                <img src={HeaderLogo} alt="" className="white-logo" />
                <img
                  src={HeaderLogoBlue}
                  alt="img loading"
                  className="blue-logo"
                />
              </NavLink>
            </figure>
            <nav>
              <div
                className={toggelBtnHandler == true ? "toogle-btn active" : "toogle-btn"}
                onClick={() => settoggelBtnHandler(!toggelBtnHandler)}
                id="btn_avtive"
              // onClick={toggelBtnHandler}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div className="menus" id="toggel_btn"
                style={toggelBtnHandler == true ? { display: "block", transition: "0.3s" } : {}}
              >
                <ul className="flex">
                  {userTypeData && userTypeData == "User" ? (
                    <>
                      <li>
                        <NavLink to="/buy">Buy</NavLink>
                      </li>
                      <li>
                        <NavLink to="/rent">Rent</NavLink>
                      </li>

                      <li>
                        <NavLink to="/commercial">Commercial</NavLink>
                      </li>
                      <li>
                        <NavLink to="/buy-advice">Buying Advice</NavLink>
                      </li>
                      <li style={{ textTransform: "capitalize" }}>
                        <NavLink to="/mallorca" >Mallorca</NavLink>
                      </li>
                      <li className="dropdown2">
                        <div className="multi_lang">
                          <span id="google_translate_element" ref={lanref} onClick={e => {
                            setlanguage(language + 1)
                          }}> </span>
                        </div>

                      </li>
                    </>
                  ) : (
                    <>
                      <li>
                        <NavLink to="/">Home</NavLink>
                      </li>
                      <li>
                        <NavLink to="/add-listing">Add Property</NavLink>
                      </li>
                      <li>
                        <NavLink to="/agent-dashboard">My Account</NavLink>
                      </li>
                      <li>
                        <NavLink to="/aboutus">About Us</NavLink>
                      </li>
                      <li className="dropdown2">
                        <div className="multi_lang">

                          <span id="google_translate_element" ref={lanref} onClick={e => {
                            setlanguage(language + 1)
                          }}> </span>

                        </div>

                        {/* <NavLink
                          to="#"
                          className="dropbtn2"
                          style={{ display: "flex", fontsize: "17px" }}
                        >
                          English (UK)
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 16l-6-6h12z" fill="rgba(255,255,255,1)"/></svg>

                        </NavLink>
                        <div className="fonts dropdown-content2 ">
                          <a href="#">Deutsch</a>
                          <a href="#">Español</a>
                          <a href="#">Français</a>
                        </div> */}
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </nav>

            {!islogin ? (
              <NavLink to="/signin" className="loginBtn">
                Login/Sign Up
              </NavLink>
            ) : (
              <>
                <div className="dropdown user_Heading  ">
                  <button className="notranslate dropbtn" style={{ color: "white" }}>
                    {UserType ? UserType.substr(0, 20) : userinfo?.name ? userinfo?.name.substr(0, 20) : ""}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M12 16l-6-6h12z" fill="#fff" />
                    </svg>
                  </button>
                  <div className="dropdown-content">
                    <NavLink to="/my-account">Account Details</NavLink>
                    {userTypeData == "User" ? (
                      <>
                        <NavLink to="/my-bookmark">
                          {document.documentElement.lang == "es" ? <c class="notranslate">Inmuebles Favoritos</c> : <c>Favourite Properties</c>}
                        </NavLink>
                        <NavLink to="/saved-searches">Saved Searches</NavLink>
                      </>
                    ) : (
                      ""
                    )}{" "}
                    <NavLink
                      to={userTypeData == "User" ? "/signin" : "/agent-login"}
                      onClick={() => localStorage.clear()}
                    >
                      Log Out
                    </NavLink>
                  </div>
                </div>
              </>
            )}
          </div>
        </header>
      </div>
    </>
  );
}

export default Navbar;
