import React, { useState } from "react";
import loaderImg from "../../assets/images/loader.svg";

import "../../Styles/style.css";
import { baseUrl } from "../../baseUrl";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import Layout from "../Layout/Layout";
import swal from "sweetalert";
const emailRegex = RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);

function Signup() {
  const [loginError, setloginError] = useState();
  const [loading2, setloading2] = useState(false);

  const navigate = useNavigate();
  const [passwordError, setpasswordError] = useState("");
  const [CallAgentName, setCallAgentName] = useState("");
  const [CallAgentNamel, setCallAgentNamel] = useState("");
  const [CallAgentEmail, setCallAgentEmail] = useState("");

  const [error, setError] = useState("");
  const [error2, seterrorfield] = useState({
    username: "",
    email: "",
  })


  const logins_field = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "firstName":
        error2.username = value.length >= 1 ? "" : "Enter Valid Name !";
        break;
      case "emailSignup":
        error2.email = emailRegex.test(value) ? "" : "Enter Valid Email !";
        break;
    }
    if (name == "firstName") {
      setCallAgentName(value);
    }

    if (name == "emailSignup") {
      setCallAgentEmail(value);
    }
  }


  const submitHandler = (event) => {
    event.preventDefault();
    const name = CallAgentName + " " + CallAgentNamel;
    const email = event.target.email.value;
    const password = event.target.password.value;
    const c_password = event.target.cpassword.value;

    const data = { name, email, password, c_password };
    const logindata = { email, password };

    if (password == c_password && name != "" && email != "") {
      setloading2(true)
      axios
        .post(baseUrl + "/register", data)
        .then((response) => {
          if (response.status === 200) {
            setloading2(false);
            swal({
              title: "Bella Islas says",
              text: response.data.message,
              icon: "success",
              button: "OK",
              className: "notranslate"
              // timer: 3000,
            });

            axios
              .post(baseUrl + "/login", logindata)
              .then((resp) => {
                event.target.reset();
                if (resp.status === 200) {
                  //alert(response.data.message)
                  const user = resp.data.data.user.user_type;

                  if (user == "User") {
                    localStorage.setItem("data", JSON.stringify(resp.data));
                    localStorage.setItem(
                      "token",
                      JSON.stringify(resp.data.data.token)
                    );
                    localStorage.setItem(
                      "user",
                      JSON.stringify(resp.data.data.user)
                    );

                    localStorage.setItem("login", "true");

                    navigate("/");
                  } else if (user == "Agent") {
                    navigate("/signin");
                  } else if (user == "admin") {
                    navigate("/signin");
                  }
                } else if (resp.status === 404) {
                  //alert(response.data.message)
                  navigate("/signin");
                } else {
                  navigate("/signin");
                }
              })
              .catch((error) => {
                if (error) {
                  // alert(error.message)
                  swal({
                    title: "Bella Islas says",
                    text: error.message,
                    icon: "error",
                    button: "OK",
                    className: "notranslate"
                    // timer: 3000,
                  });
                  setloading2(false);
                  setloginError(
                    "Fill all the required fields!"
                  );
                } else {
                  setloginError("");
                }
              });
          }

          event.target.reset();
        })
        .catch((error) => {
          if (error) {
            swal({
              title: "Bella Islas says",
              text: error.message,
              icon: "error",
              button: "OK",
              className: "notranslate"
              // timer: 3000,
            });
            setloading2(false);
          } else {
            setError("");
          }
        });
    } else {
      setloginError(
        "Fill all the required fields!"
      );
      if (password != c_password && c_password.length > 0) {
        setloginError(
          "Password and Confirm password not same!"
        );
      }

      setTimeout(() => {
        setloginError("");
      }, 2000);
    }
  };
  // swal({
  //   title: "Bella Islas says",
  //   text: error.message,
  //   icon: "error",
  //   button: "OK",
  //   className: "notranslate"
  //   // timer: 3000,
  // });
  return (
    <>
      <Layout>
        <section className="login-section">
          <div className="container-sm">
            <div className="wrapper">
              <h3>New to Bella Islas? Register</h3>
              <p>
                Already registered? <NavLink to="/signin">Sign In</NavLink>
              </p>
              <form action="#" className="user-login" onSubmit={submitHandler}>
                <div className="form_group">
                  <label for="firstName">
                    First Name <sup>*</sup>
                  </label>
                  <input type="text" name="firstName" id="name" onChange={(e) => logins_field(e)}
                    value={CallAgentName}
                    // name="name"
                    style={
                      error2.username != ""
                        ? { borderBottom: "2px solid red" }
                        : {}
                    }
                  />
                  <div
                    style={{
                      textAlign: "center",
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {error2.username}
                  </div>
                </div>
                <div className="form_group">
                  <label for="lastName" >Last Name</label>
                  <input type="text" name="lastName" onChange={(e) => setCallAgentNamel(e.target.value)} />
                </div>
                <div className="form_group">
                  <label for="emailSignup">
                    Email Address <sup>*</sup>
                  </label>
                  <input type="email" name="emailSignup" id="email" onChange={(e) => logins_field(e)}
                    value={CallAgentEmail}

                    style={
                      error2.email != ""
                        ? { borderBottom: "2px solid red" }
                        : {}
                    }
                  />
                  <div
                    style={{
                      textAlign: "center",
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {error2.email}
                  </div>
                </div>
                <div className="form_group">
                  <label for="passwordSignup">
                    Password <sup>*</sup>
                  </label>
                  <input type="password" name="passwordSignup" id="password" />
                </div>
                <div className="form_group">
                  <label for="confirmpasswordSignup">
                    Confirm Password <sup>*</sup>
                  </label>
                  <input
                    type="password"
                    name="confirmpasswordSignup"
                    id="cpassword"
                  />
                  <span style={{ color: "red", fontSize: "15px" }}>
                    {" "}
                    {passwordError}{" "}
                  </span>
                  <span style={{ color: "red", fontSize: "15px" }}>
                    {error}
                  </span>
                  <span></span>
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "600",
                    paddingBottom: "1rem",
                    textAlign: "center",
                    color: "red"
                  }}
                >
                  {loginError}
                </div>
                <div className="form_group">
                  <button type="submit">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </section>

        {loading2 == true ? (
          <section
            className="call_popup active"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="closeBtn"></div>
            <div className="wrapper">
              <div className="content" style={{ textAlign: "center" }}>
                <p>Please Wait !</p>
                <br />
                <div>
                  <img src={loaderImg} />
                </div>
              </div>
            </div>
          </section>
        ) : (
          ""
        )}
      </Layout>
    </>
  );
}

export default Signup;
