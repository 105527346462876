import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import viewicon from "../../assets/images/view-icon.png";
import dlticon from "../../assets/images/dlt-big-icon.png";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import axios from "axios";
import { baseUrl } from "../../baseUrl";
import loader from "../../assets/images/loader.svg";
import swal from "sweetalert";

function Mybookmark() {
  const [data, setData] = useState([]);
  const [loading, setloading] = useState(false);
  const [error, seterror] = useState(false);
  const [popup, setpopup] = useState(false);
  const [deleteid, setdeleteid] = useState("");



console.log(data ,"<<<<<<<<<");


  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const fetchproperty = async () => {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      const res = await axios.get(baseUrl + "/Add/favourite-list", {
        headers: { Authorization: `Bearer ${token}` },
      });
      const details = res.data;
      if (res.status == 200) {
        setloading(true);
      }
      console.log(details);
      setData(details?.data?.response?.data);
    } catch (error) {
      console.log(error.message);
      seterror(error.message);
      if (error) {
        setloading(true);
      }
    }
  };
  useEffect(() => {
    fetchproperty();
  }, []);

  const delateFavourite = async () => {
    // console.log(id);
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      const res = await axios.get(
        baseUrl + `/delete/favourite-property?id=${deleteid}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const details = res.data;
      console.log("delate", details);
      if (details.success == true) {
        localStorage.removeItem(
          "fav_property_id"
         
        );
        swal({
          title: "Bella Islas says",
          text: "Favourite deleted Successfully",
          icon: "success",
          button: "OK",
          // timer: 3000,
        });
        setpopup(false)
        const token = JSON.parse(localStorage.getItem("token"));
        const res = await axios.get(baseUrl + "/Add/favourite-list", {
          headers: { Authorization: `Bearer ${token}` },
        });
        const details = res.data;
        console.log(details);
        setData(details.data.response.data);
      }
    } catch (error) {
      console.log(error.message);
      swal({
        title: "Bella Islas says",
        text: error.message,
        icon: "success",
        button: "OK",
        // timer: 3000,
      });
      // setError(error.message)
    }
  };

  return (
    <>
      <Layout>
        {loading !== true ? (
          <div className={loading !== true ? "loader_set" :""} 
          style={loading == true ? {display :"none"} :{}}
          >
            {" "}
            <img src={loader} alt="loading..." />{" "}
          </div>
        ) : (
          <section className="bookmark-section">
            <div className="container-lg">
              <ul className="flex_dirction_col flex" style={{justifyContent:"center"}}>
                {data?.length !== 0 ? (
                  data?.map((item, index) => {
                    console.log(item);
                    return (
                      <li className="bookmark-slider_mo" 
                          key={index}
                         >
                          <div className="  bookmark-slider">
                            <Slider {...settings}>
                              {item.data?.images?.image !=[] ? item.data?.images?.image?.map((img, index2) => {
                                return (
                                  <div
                                   key={index2.toString()} 
                                   >
                                    <figure>
                                      <img src={img?.url == undefined ? "":img?.url} alt="loading img..." />
                                    </figure>
                                  </div>
                                );
                              }) :""}
                            </Slider>
                          </div>
                          <div className="bookmark-content">
                            <div className="top flex">
                              <h5>
                                <Link to="#">
                                  €{" "}
                                  
                                  {parseInt(item?.data?.price).toLocaleString(
                                    undefined,
                                    { maximumFractionDigits: 2 }
                                  )}{" "}
                                  per {item?.data?.price_freq}
                                </Link>
                              </h5>
                              <h5 className='notranslate'>
                                <Link to="#" style={{ marginTop: "5px" }}>
                                  {item.data?.town
                                    ? item.data?.town?.substr(
                                      item.data?.town?.lastIndexOf("\\") +
                                        1
                                    )
                                    .split("[")[1]
                                  ? JSON.parse(item.data?.town).map(
                                      (e, index) =>
                                        e +
                                        (index <
                                        JSON.parse(item.data?.town)
                                          .length -
                                          1
                                          ? " | "
                                          : " ")
                                    )
                                  :  item?.data?.town
                                : item?.data?.town?.replace(
                                  /[\[,"\]']+/g,
                                  " | "
                                )}
                                </Link>
                              </h5>
                            </div>
                            <div className="bottom flex">
                              <Link
                                to={`/property-details/${item?.property_id}`}
                                className="view-action"
                              >
                                <img src={viewicon} alt="icon.." />
                              </Link>
                              <Link
                                to="#"
                                className="delete-action"
                                onClick={() => {
                                  setpopup(true)
                                  setdeleteid(item?.id);
                                }}
                              >
                                <img src={dlticon} alt="icon.." />
                              </Link>
                            </div>
                          </div>
                        </li>
            
                      
                    );
                  })
                ) : (
                  <>
                    <div className="heading-line" data-aos="fade-in">
                      <div>
                      {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-4-6h8v2H8v-2zm0-3a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm8 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/></svg> */}
                      </div>
                      <Link to="/buy">Go To Add</Link>
                    </div>
                  </>
                )}
              </ul>
              {/* <div className="loader_set">{error ? error : ""}</div> */}
            </div>
          </section>
        )}
        {popup == true ? (
          <section className="call_popup active" style={{display:"flex" ,alignItems:"center"}}>
            <div className="closeBtn" onClick={() => setpopup(false)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="21"
                viewBox="0 0 352 512"
              >
                <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" />
              </svg>
            </div>
            <div className="wrapper">
              <div className="content" style={{ textAlign: "center" }}>
                <p>Are you sure !</p>
                <br />
                <button onClick={() => setpopup(false)}>No</button>
                <button onClick={e => delateFavourite()}>Yes</button>
              </div>
            </div>
          </section>
        ) : (
          ""
        )}
      </Layout>
    </>
  );
}

export default Mybookmark;



 // <li className="bookmark-slider_mo" 
//                           key={index}
//                          >
//                           <div className="  bookmark-slider">
//                             <Slider {...settings}>
//                               {item.data?.images?.image !=[] ? item.data?.images?.image?.map((img, index2) => {
//                                 return (
//                                   <div
//                                    key={index2.toString()} 
//                                    >
//                                     <figure>
//                                       <img src={img?.url == undefined ? "":img?.url} alt="loading img..." />
//                                     </figure>
//                                   </div>
//                                 );
//                               }) :""}
//                             </Slider>
//                           </div>
//                           <div className="bookmark-content">
//                             <div className="top flex">
//                               <h5>
//                                 <Link to="#">
//                                   €{" "}
                                  
//                                   {parseInt(item?.data?.price).toLocaleString(
//                                     undefined,
//                                     { maximumFractionDigits: 2 }
//                                   )}{" "}
//                                   per {item?.data?.price_freq}
//                                 </Link>
//                               </h5>
//                               <h5 className='notranslate'>
//                                 <Link to="#" style={{ marginTop: "5px" }}>
//                                   {item.data?.town
//                                     ? item.data?.town?.substr(
//                                       item.data?.town?.lastIndexOf("\\") +
//                                         1
//                                     )
//                                     .split("[")[1]
//                                   ? JSON.parse(item.data?.town).map(
//                                       (e, index) =>
//                                         e +
//                                         (index <
//                                         JSON.parse(item.data?.town)
//                                           .length -
//                                           1
//                                           ? " | "
//                                           : " ")
//                                     )
//                                   : data
//                                 : item.data?.town?.replace(
//                                   /[\[,"\]']+/g,
//                                   " | "
//                                 )
                                
                                
                                    
// }
//                                 </Link>
//                               </h5>
//                             </div>
//                             <div className="bottom flex">
//                               <Link
//                                 to={`/property-details/${item?.property_id}`}
//                                 className="view-action"
//                               >
//                                 <img src={viewicon} alt="icon.." />
//                               </Link>
//                               <Link
//                                 to="#"
//                                 className="delete-action"
//                                 onClick={() => {
//                                   setpopup(true)
//                                   setdeleteid(item?.id);
//                                 }}
//                               >
//                                 <img src={dlticon} alt="icon.." />
//                               </Link>
//                             </div>
//                           </div>
//                         </li>
                      