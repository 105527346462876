import React, { useContext, useState } from "react";

// import "../../Styles/style2.css"
import Layout from "../Layout/Layout";
import userIcon from "../../assets/images/user-icon.png";

import axios from "axios";
import { baseUrl } from "../../baseUrl";
import { useEffect } from "react";
import swal from 'sweetalert';

function UserEdit() {
  const [userType, setUserType] = useState("User");
  const [errorMessage, setErrorMessage] = useState("");
  const [userName, setUsername] = useState();
  const [CurrentPass, setCurrentPass] = useState("");
  const [verifyToken, setverifyToken] = useState();
  const [email, setemail] = useState("");
  const [passError, setpassError] = useState("");

  const userinfo = JSON.parse(localStorage.getItem("user"));
  const user = JSON.parse(localStorage.getItem("data"));
  const token = JSON.parse(localStorage.getItem("token"));

  console.log(email, "<<<<<<<<<<<<<<<<<<<");

  useEffect(() => {
    if (userinfo) {
      setUserType(userinfo.user_type);
      if (userinfo.user_type == "Agent") {
        setUsername(userinfo.username);
        setemail(userinfo)
      } else if (userinfo.user_type == "User") {
        setUsername(userinfo.name);
      }
    }

    if (token) {
      setverifyToken(token);
    }
  }, []);

  //console.log(userType, userName);

  const handleSubmit = (e) => {
    e.preventDefault();
    const verify_token = verifyToken;
    const old_password = e.target.password.value;
    const current_password = e.target.newpassword.value;
    const password_confirmation = e.target.cnewpassword.value;

    const body = {
      verify_token,
      old_password,
      current_password,
      password_confirmation,
    };

    if (current_password !== password_confirmation) {
      setpassError("Your Confirm Password is not match!");
      setErrorMessage("");
    } else {
      setpassError("");
    }

    if (old_password != "" ? old_password !== "" && current_password != "" && password_confirmation != "" : "") {
      axios
        .request({
          method: "POST",
          url: baseUrl + "/user/edit-profile",
          data: body,
          headers: {
            Authorization: `Bearer ${verify_token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response);
          if (response.status === 200 && response.data.status == true) {
            console.log(response.data.message);
            swal({
              title: "Bella Islas says",
              text: response.data.message,
              icon: "success",
              button: "OK",
              className: "notranslate"
            });
          } else {
            setErrorMessage(response.data.message);
          }

          console.log(response.status);

          e.target.reset();
        })
        .catch((error) => {
          console.log(error.message);
          if (error) {
            swal({
              title: "Bella Islas says",
              text: error.message,
              icon: "error",
              button: "OK",
              className: "notranslate"
            });
            setErrorMessage("Fill all the required fields!");
          } else {
            setErrorMessage("");
          }
        });
    } else {
      setErrorMessage("Fill all the required fields!");
    }
  };

  const [image3, setimage3] = useState();
  const handleimagefile = (e) => {
    console.log(e.target.files[0]);
    setimage3(e.target.files[0]);
  };

  const handleAgentSubmit = (e) => {


    e.preventDefault();
    const verify_token = verifyToken;
    const old_password = e.target.password.value;
    const current_password = e.target.newpassword.value;
    const password_confirmation = e.target.cnewpassword.value;
    const logo = e.target.fileUploads.files[0];
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + verifyToken);
    var FormData = require('form-data');
    var data = new FormData();
    data.append('old_password', old_password);
    data.append('current_password', current_password);
    data.append('password_confirmation', password_confirmation);
    data.append('logo', logo);

    console.log(logo);

    if (old_password != "" ? old_password !== "" && current_password == password_confirmation : logo) {
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: data,
        redirect: 'follow'
      };

      fetch(baseUrl + "/agent/edit-profile", requestOptions)
        .then(res => res.json())
        .then(response => {
          console.log(response);
          if (response.status == true) {
            console.log(response.message);
            localStorage.setItem("user", JSON.stringify(response.user.response))
            swal({
              title: "Bella Islas says",
              text: response.message,
              icon: "success",
              button: "OK",
              className: "notranslate"
              // timer: 3000,
            });
          } else {
            setErrorMessage(response.message);
            setCurrentPass("");
          }

          console.log(response.status);

          e.target.reset();
        })
        .catch((error) => {
          console.log(error.message);
          if (error) {
            swal({
              title: "Bella Islas says",
              text: error.message,
              icon: "error",
              button: "OK",
              className: "notranslate"
            });
            setErrorMessage("Fill the required field !");
          } else {
            setErrorMessage("");
          }
        });
    } else {
      setErrorMessage("Fill the required field !");
      if (current_password != password_confirmation && password_confirmation.length > 0) {
        setErrorMessage(
          "New password and Confirm password not same!"
        );
      }
    }

    // console.log("clicked", old_password);
  };

  return (
    <>
      <Layout>
        <section className="account-edit">
          <div className="container-sm">
            <div className="wrapper">
              <div className="head flex">
                <div className="icon">
                  <img src={userIcon} alt="loading..." />
                </div>
                <h5>Account details</h5>
              </div>
              <form
                action=""
                className="editProfile"
                onSubmit={userType == "User" ? handleSubmit : handleAgentSubmit}
                enctype="multipart/form-data"
              >
                <div className="form_group">
                  <label for="userEmail">Email</label>
                  <input
                    type="text"
                    name="userEmail"
                    value={userinfo.email}
                    disabled={true}
                  />
                </div>
                <div className="form_group">
                  <label for="userNames">Username</label>

                  <input
                    type="text"
                    name="userNames"
                    id="name"
                    value={userName}
                    disabled={true}
                  />

                  {/* {
                                        userType && userType == "Agent" ?
                                            <input type="text" name="userNames" id='name' value={userinfo.username} /> :
                                            <input type="text" name="userNames" id='name' value={userinfo.name} />
                                    } */}
                </div>

                {userType && userType == "Agent" ? (<>
                  <div className="form_group headingTop">
                    <label for="changeProfile">Change Logo</label>
                    <div className="fileUpload flex">
                      <div className="wrap">
                        <div className="placeHolder">
                          <img
                            src={require("../../assets/images/upload-icon.png")}
                            alt="select"
                          />
                          <input
                            type="file"
                            className="filesInput"
                            onChange={(e) => handleimagefile(e)}
                            id="fileUploads"
                          />
                        </div>
                        <p>Maximum file size: 20 MB.</p>
                      </div>
                      <div className="newImg" id="image-holder">
                        {
                          image3 ? <>
                            <img src={image3 ? URL.createObjectURL(image3) : ""} />
                            <span onClick={() => setimage3("")} style={{
                              cursor: "pointer", position: "absolute"
                              , right: "4px"
                            }}>X</span>
                          </>
                            : email?.file_path && email?.file_path != "assets/images/users/default.png" ? <img src={email?.file_path ? email?.file_path : ""} /> : ""

                        }
                      </div>
                    </div>
                  </div>
                </>
                ) : (
                  ""
                )}

                <div className="form_group headingTop">
                  <label for="changePassword">Change password</label>
                  <div className="passWrap">
                    <div className="inputWrap">
                      <input type="password" id="password" />
                      <span>
                        Current password<sup>*</sup>
                        {/* (leave blank to leave unchanged) */}
                      </span>
                    </div>
                    <div className="inputWrap">
                      <input type="password" id="newpassword" />
                      <span>New password<sup>*</sup>
                        {/* (leave blank to leave unchanged) */}
                      </span>
                    </div>
                    <div className="inputWrap">
                      <input type="password" id="cnewpassword" />
                      <span>Confirm new password<sup>*</sup></span>
                      {/* <p className='text-danger'>{passError}</p> */}
                    </div>
                    <div style={{ fontSize: "12px", color: "#303030" }}>
                      {" "}
                      {passError !== "" ? passError : ""}
                      <span> {CurrentPass}</span>{" "}
                      <span style={{ color: "red" }}> {`${errorMessage}`}</span>
                    </div>
                  </div>
                </div>
                <div className="form_group">
                  <button type="submit">Save changes</button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
}

export default UserEdit;
