import axios from "axios";
import React from "react";
import Layout from "../Layout/Layout";
import { baseUrl } from "../../baseUrl";
import { useEffect } from "react";
import { Pagination } from "antd";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Rent from "../Sections/SearchSections/Rent";
import { useDispatch } from "react-redux";
import loaderImg from "../../assets/images/loader.svg";
import swal from 'sweetalert';

function Searchlisting() {
  const [apartmant, setApartmant] = useState([]);
  const [priceShow, setpriceShow] = useState(false);
  const [bedShow, setbedShow] = useState(false);
  const [propertyTypeShow, setpropertyTypeShow] = useState(false);
  const [filterShow, setfilterShow] = useState(false);
  const [dialogShow, setdialogShow] = useState(false);
  const [Showbookmark, setShowbookmark] = useState(false);
  const [details, setDetails] = useState();
  const [error, setError] = useState("");
  const [minprice, setMinprice] = useState("");
  const [maxprice, setMaxprice] = useState("");
  const [minbed, setminbed] = useState("");
  const [maxbed, setmaxbed] = useState("");
  const [town, settown] = useState("");
  const [categorylist, setcategoryList] = useState("");
  const [property, setProperty] = useState();
  const [forPagination, setForPagination] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [searchName, setsearchName] = useState("");
  const [alerts, setAlerts] = useState("");
  const { townName } = useParams();
  const [loader, setLoader] = useState(false);
  const [loading, setloading] = useState(false);
  const [show, setShow] = useState(false);
  const [searchTitle, setSearchTitle] = useState("");
  const [town2, settown2] = useState();
  const [searchTown, setSearchTown] = useState("");
  const login = JSON.parse(localStorage.getItem("login"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filterSearch, setfilterSearch] = useState([]);
  const [searchfilterParams, setsearchfilterParams] = useState([]);
  const [params, setparams] = useState([]);
  const [premiumDetails, setpremiumDetails] = useState([]);
  const [total, setTotal] = useState();
  const [shorting, setshorting] = useState("");
  const [added_site, setadded_site] = useState("");
  const [subtype, setsubtype] = useState("");
  const [savesearcherror, setsavesearcherror] = useState("");
  const [postperPage, setpostperPage] = useState(20);

  const [searchValue, setsearchValue] = useState(
    townName != null && townName != "" ? townName : ""
  );
  const totalpage = total?.last_page;
  const handlePrice = (e) => {
    setMinprice(e.target.value);
  };
  let nf = new Intl.NumberFormat();

  const handleSearch = () => {
    setdialogShow(!dialogShow);
    setfilterShow(false);
    setcurrentPage(1);
    setShowbookmark(false);
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 30);
  }, [currentPage]);
  var localdata = localStorage.getItem("user")
  const fetchproperty = async (elm) => {
    setDetails([]);
    setloading(false);

    try {
      const res = await axios.get(
        baseUrl +
        `/property?user_id=${JSON.parse(localdata) != undefined ? JSON.parse(localdata)?.id : ""}&page=${currentPage}&min_price=${minprice}&max_price=${maxprice}&min_beds=${minbed}&max_beds=${maxbed}&town=${elm != null && elm ? elm : searchValue ? searchValue : townName ? townName : ""
        }${searchfilterParams.length !== 0
          ? `&amenities[]=${searchfilterParams.join("&amenities[]=")}`
          : ""
        }${added_site == "" ? "" : `&added_site=${added_site}`}${subtype == "" ? "" : `&subtype=${subtype}`
        }${params.length !== 0
          ? `&property_type[]=${params.join("&property_type[]=")}`
          : ""
        }&type=month${shorting == "low"
          ? `&low=low`
          : shorting == "high"
            ? `&high=high`
            : shorting == "new"
              ? `&new=new`
              : shorting == "old"
                ? `&old=old`
                : searchValue ? "" : "&new=new"
        }`,

        { headers: { Authorization: "No Auth" } }
      );

      const details = res.data;
      if (res.status == 200) {
        setTimeout(() => {
          setloading(true);
        }, 1000);
      }
      setDetails(details.data.xml);
      setpremiumDetails(details.data);
      setTotal(details.data.totalRecord);
      setProperty(details.data.xml.length);
      setForPagination(details.data.totalPage);
    } catch (error) {
      if (error) {
        setloading(true);
      }
      setError(error.message);
    }
  };

  const page = [];
  let totalPage = forPagination.length - 2;
  for (let i = 0; i < Math.ceil(totalPage); i++) {
    // if ((totalPage - 10) > -9) {
    //   totalPage = (totalPage - 10)
    // }
    page.push(i + 1);
  }

  const [PageValue, setPageValue] = useState();

  const handlePrev = () => {
    if (currentPage !== 1) {
      setcurrentPage(currentPage - 1);
      window.scrollTo(0, 0);
    }
  };

  const handleNext = () => {
    if (currentPage !== totalPage) {
      setcurrentPage((currentPage) => currentPage + 1);
      window.scrollTo(0, 0);
    }
  };

  const saveSearch = (event) => {
    //event.preventDefault();
    const min_price = minprice;
    const max_price = maxprice;
    const min_beds = minbed;
    const max_beds = maxbed;
    const saved_name = searchName;
    const alert = alerts;
    const town = searchValue;
    const token = JSON.parse(localStorage.getItem("token"));

    if (
      minprice !== "" &&
      maxprice !== "" &&
      minbed !== "" &&
      maxbed !== "" &&
      searchName !== "" &&
      searchValue != ""
    ) {
      setsavesearcherror("");
      // const latitude = "34.765"
      const data = {
        min_price,
        max_price,
        min_beds,
        max_beds,
        town,
        saved_name,
        alert,
      };
      axios
        .post(baseUrl + "/saved-search", data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          if (response.status === 200) {
            const token = JSON.parse(localStorage.getItem("token"));
            setdialogShow(false);
            try {
              const res = axios.get(baseUrl + "/saved/search-list", {
                headers: { Authorization: `Bearer ${token}` },
              });
              const details = res.data.data.response;
              setData(details);
            } catch (error) { }
          }
        })
        .catch((error) => { });
    } else {
      setsavesearcherror("All fields required");
    }
  };
  const handlePriceShow = () => {
    setbedShow(false);
    setpriceShow(!priceShow);
    setShow(false);
    setpropertyTypeShow(false);
    setfilterShow(false);
    setShowbookmark(false);

    // setMaxprice("")
  };

  const handleBed = () => {
    setbedShow(!bedShow);
    setpriceShow(false);
    setpropertyTypeShow(false);
    setfilterShow(false);
    setShowbookmark(false);
    setmaxbed("");
    setShow(false);
  };
  const [data, setData] = React.useState([]);
  const Savedlength = async () => {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      const res = await axios.get(baseUrl + "/saved/search-list", {
        headers: { Authorization: `Bearer ${token}` },
      });
      const details = res.data.data.response;
      setData(details);
    } catch (error) {
      // setError(error.message)
    }
  };

  // const handleCheckProperty = (e) => {
  //   const value = e.target.value;
  //   const check = e.target.checked;

  //   if (check) {
  //     setApartmant([...apartmant, value]);
  //     setfilterSearch([...filterSearch, value]);
  //   } else {
  //     setApartmant(apartmant.filter((vlu) => vlu !== value));
  //     setfilterSearch(filterSearch.filter((vlu) => vlu !== value));
  //     setcurrentPage(1)
  //   }
  // };

  //const searchParams = new URLSearchParams()
  const handleCheckProperty = (e) => {
    const value = e.target.value;
    const check = e.target.checked;

    if (check) {
      setApartmant([...apartmant, value]);
    } else {
      setApartmant(apartmant.filter((vlu) => vlu !== value));
      setcurrentPage(1);
    }
  };

  const handlefilterProperty = (e) => {
    const value = e.target.value;
    const check = e.target.checked;

    if (check) {
      setfilterSearch([...filterSearch, value]);
    } else {
      setfilterSearch(filterSearch.filter((vlu) => vlu !== value));
      setcurrentPage(1);
    }
  };

  const propertyTypeSubmit = () => {
    setparams(apartmant);
    setpropertyTypeShow(false);
    fetchtownList();
  };

  const searchfilterHandle = () => {
    setsearchfilterParams(filterSearch);
    setfilterShow(false);
    setShowbookmark(false);
  };

  const delateSearch = async (id) => {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      const res = await axios.get(baseUrl + `/delete/search-list?id=${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const details = res.data;
      if (details.success == true) {
        const token = JSON.parse(localStorage.getItem("token"));

        const res = await axios.get(baseUrl + "/saved/search-list", {
          headers: { Authorization: `Bearer ${token}` },
        });
        const details = res.data.data.response;
        setData(details);
      }
    } catch (error) {
      // setError(error.message)
    }
  };
  const Alert_Frequency = async (id, value) => {
    console.log(id);
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      const res = await axios.get(baseUrl + `update-search?id=${id}&alert=${value}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const details = res.data;
      console.log(res);
      if (details.success == true) {
        swal({
          title: "Bella Islas says",
          text: "Save Search Alert Frequency Change  Successfully",
          icon: "success",
          button: "OK",
          className: "notranslate"
          // timer: 3000,
        });
        // setData(details);
      }
    } catch (error) {
      console.log(error.message);
      swal({
        title: "Bella Islas says",
        text: error.message,
        icon: "error",
        button: "OK",
        className: "notranslate"
        // timer: 3000,
      });
      // setError(error.message)
    }
  };
  const fetchtownList = async () => {
    await axios
      .get(baseUrl + `/town-list`, {
        headers: { Authorization: `No Auth` },
      })
      .then((res) => {
        if (res.status == 200) {
          settown(res.data.data);
          settown2(res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //console.log(town);
  const hidesearchboxhandle = (elm) => {
    setsearchValue(elm);
    setShowbookmark(false);
    navigate("/rent/" + elm)
    setShow(false);
    fetchproperty(elm);
  };

  const onChangeS = (e, type) => {
    setShow(true);
    if (type) {
      setsearchValue("");
    } else {
      setsearchValue(e.target.value)
    }
    const filteredValues = town2.filter(value => value.town.toUpperCase().includes(e.target.value.toUpperCase()));
    const sortedValues = filteredValues.sort((a, b) => {
      const indexA = a.town.toUpperCase().indexOf(e.target.value.toUpperCase());
      const indexB = b.town.toUpperCase().indexOf(e.target.value.toUpperCase());

      if (indexA === 0 && indexB === 0) {
        return 0;
      } else if (indexA === 0) {
        return -1;
      } else if (indexB === 0) {
        return 1;
      } else {
        return 0;
      }
    });
    settown(sortedValues)
    setfilterShow(false);
    setpropertyTypeShow(false);
    setpriceShow(false);
    setbedShow(false);
  };
  React.useEffect(() => {
    Savedlength();
    fetchtownList();
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setcurrentPage(1);
    }, 30);
  }, [
    minprice,
    maxprice,
    minbed,
    maxbed,
    params,
    searchfilterParams,
    shorting,
    subtype,
    added_site,
  ]);

  useEffect(() => {
    fetchproperty();
  }, [
    minprice,
    maxprice,
    minbed,
    maxbed,
    currentPage,
    params,
    searchfilterParams,
    shorting,
    added_site,
    subtype,
  ]);

  const handleOutside = () => {
    let x = document.getElementById("backGround_click");
    // x.style.display = "none"
    // console.log(state);
    setShow(false);
    setMobileMenu(false);
    setShowbookmark(false);
    setdialogShow(false);
    setpriceShow(false);
    setbedShow(false);
    setfilterShow(false);
    setpropertyTypeShow(false);
  };

  const [MobileMenu, setMobileMenu] = useState(false);

  const [itemsPerPage, setitemsPerPage] = useState(20);

  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

  const pages = [];
  for (let i = 1; i <= Math.ceil(forPagination); i++) {
    pages.push(i);
  }
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);
    navigate("#page=" + (currentPage + 1))
    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);
    navigate("#page=" + (currentPage - 1))
    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
  }


  return (
    <Layout>
      {/*     <!-- fillter section start here --> */}
      <section className="fillter-section">
        <div
          className="backGround_click"
          onClick={handleOutside}
          id="backGround_click"
        ></div>
        <div className="container_">
          <form action="" className={MobileMenu == true ? "active" : ""}>
            <div className="form-group">
              <div className="select2 search_select">
                <form
                  action=""
                  onSubmit={(e) => handleSearch(e)}
                  className="flex formUnderForm flex-width m-0"
                  style={{ margin: "0px" }}
                >
                  <div className="form_group">
                    <input
                      type="text"
                      value={searchValue}
                      onClick={(e) => {
                        setTimeout(() => {
                          onChangeS(e, true)
                        }, 80);
                        setsearchValue(" ")
                        setTimeout(() => {
                          setsearchValue("")
                          navigate("/rent")
                        }, 70);
                      }}
                      onChange={e => {
                        
                        hidesearchboxhandle(e.target.value)
                        onChangeS(e)
                      }}
                      // id="town"
                      className="form-control search_input_width select2-input searchSelect"
                      placeholder="Location"
                    />
                    {searchValue == "" ? (
                      ""
                    ) : (
                      <span
                        className="clear_btn4"
                        onClick={() => {
                          setsearchValue("");
                          setApartmant([]);
                          setmaxbed("");
                          setMinprice("");
                          setMaxprice("");
                          setminbed("");
                          setsearchfilterParams([]);
                          setparams([]);
                          setadded_site("");
                          setsubtype("");
                          navigate("/rent");
                        }}
                      >
                        x
                      </span>
                    )}
                  </div>
                </form>
                <div
                  className={`${show == true ? "search_country" : ""}`}
                  style={{
                    position: "absolute",
                    left: "0",
                    width: "100%",
                    zIndex: "1",
                  }}
                >
                  {/* <p
                    onClick={() => hidesearchboxhandle("PALMA DE MALLORCA")}
                  >PALMA DE MALLORCA
                  </p> */}
                  {show === true
                    ? town
                      ?.map((item) => (
                        <p
                          className="notranslate"
                          key={item.id}
                          onClick={() => hidesearchboxhandle(item.town)}
                        >
                          {item.town}
                        </p>
                      ))
                    : ""}
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="pricesearch">
                <div className="price-btn" onClick={() => handlePriceShow()}>
                  <span className="price-btn-text" id="price-btn-text">
                    {minprice !== ""
                      ? "€" +
                      (minprice?.minprice > 4
                        ? minprice.substring(0, 3)
                        : (Math.round(minprice) / 1000)
                          .toFixed(2)
                          ?.substring(0, 3)) +
                      "K" +
                      "-" +
                      "€" +
                      (maxprice?.length > 4
                        ? maxprice.substring(0, 3)
                        : (Math.round(maxprice) / 1000)
                          .toFixed(2)
                          .substring(0, 3)) +
                      // maxprice.substring(0, 3)
                      "K"
                      : "Price"}{" "}
                  </span>
                </div>
                <div className="min-max-price">
                  <div
                    className={
                      priceShow == true ? "innerprice active" : "innerprice"
                    }
                  >
                    <div className="minprice">
                      <select
                        name="minPrice"
                        value={minprice}
                        onChange={(e) => setMinprice(e.target.value)}
                        className="select js-example-basic-single2 pricefilterss select2-hidden-accessible"
                        id="minPrice"
                        title="Min Price"
                      >
                        <option value="" data-select2-id="select2-data-5-owbi">
                          Min Price
                        </option>
                        <option
                          value="0"
                          data-select2-id="select2-data-18-u92m"
                        >
                          Request Price
                        </option>
                        <option
                          value="100"
                          data-select2-id="select2-data-19-8vy1"
                        >
                          €100 per month
                        </option>
                        <option
                          value="150"
                          data-select2-id="select2-data-20-5tjj"
                        >
                          €150 per month
                        </option>
                        <option
                          value="250"
                          data-select2-id="select2-data-21-vqd0"
                        >
                          €250 per month
                        </option>
                        <option
                          value="300"
                          data-select2-id="select2-data-22-l2va"
                        >
                          €300 per month
                        </option>
                        <option
                          value="400"
                          data-select2-id="select2-data-23-wmkd"
                        >
                          €400 per month
                        </option>
                        <option
                          value="500"
                          data-select2-id="select2-data-24-9ztz"
                        >
                          €500 per month
                        </option>
                        <option
                          value="600"
                          data-select2-id="select2-data-25-ymin"
                        >
                          €600 per month
                        </option>
                        <option
                          value="700"
                          data-select2-id="select2-data-26-mei3"
                        >
                          €700 per month
                        </option>
                        <option
                          value="800"
                          data-select2-id="select2-data-27-b3r0"
                        >
                          €800 per month
                        </option>

                        <option
                          value="900"
                          data-select2-id="select2-data-29-m5qn"
                        >
                          €900 per month
                        </option>
                        <option
                          value="1000"
                          data-select2-id="select2-data-30-faor"
                        >
                          €1,000 per month
                        </option>
                        <option
                          value="1100"
                          data-select2-id="select2-data-31-xpw0"
                        >
                          € 1,100 per month
                        </option>
                        <option
                          value="1200"
                          data-select2-id="select2-data-31-xpw0"
                        >
                          € 1,200 per month
                        </option>
                        <option
                          value="1300"
                          data-select2-id="select2-data-32-cr5h"
                        >
                          €1,300 per month
                        </option>
                        <option
                          value="1400"
                          data-select2-id="select2-data-33-l1dk"
                        >
                          €1,400 per month
                        </option>
                        <option
                          value="1500"
                          data-select2-id="select2-data-34-77b4"
                        >
                          €1,500 per month
                        </option>
                        <option
                          value="1750"
                          data-select2-id="select2-data-35-fgiv"
                        >
                          €1,750 per month
                        </option>
                        <option
                          value="2000"
                          data-select2-id="select2-data-36-tr7k"
                        >
                          €2,000 per month
                        </option>
                        <option
                          value="2250"
                          data-select2-id="select2-data-37-bnmy"
                        >
                          €2,250 per month
                        </option>
                        <option
                          value="2500"
                          data-select2-id="select2-data-38-6pt3"
                        >
                          €2,500 per month
                        </option>
                        <option
                          value="2750"
                          data-select2-id="select2-data-39-gngi"
                        >
                          €2,750 per month
                        </option>
                        <option
                          value="3000"
                          data-select2-id="select2-data-40-d8ej"
                        >
                          €3,000 per month
                        </option>
                        <option
                          value="3500"
                          data-select2-id="select2-data-41-mysy"
                        >
                          €3,500 per month
                        </option>
                        <option
                          value="4000"
                          data-select2-id="select2-data-42-qe4j"
                        >
                          €4,000 per month
                        </option>
                        <option
                          value="4500"
                          data-select2-id="select2-data-43-1dgk"
                        >
                          €4,500 per month
                        </option>
                        <option
                          value="5000"
                          data-select2-id="select2-data-44-4mtd"
                        >
                          €5,000 per month
                        </option>
                        <option
                          value="5500"
                          data-select2-id="select2-data-45-2s0r"
                        >
                          €5,500 per month
                        </option>
                        <option
                          value="6000"
                          data-select2-id="select2-data-46-w5jj"
                        >
                          €6,000 per month
                        </option>
                        <option
                          value="6500"
                          data-select2-id="select2-data-47-f5f6"
                        >
                          €6,500 per month
                        </option>
                        <option
                          value="7000"
                          data-select2-id="select2-data-48-f91u"
                        >
                          €7,000 per month
                        </option>
                        <option
                          value="8000"
                          data-select2-id="select2-data-49-z1vi"
                        >
                          €8,000 per month
                        </option>
                        <option
                          value="9000"
                          data-select2-id="select2-data-50-wqwe"
                        >
                          €9,000 per month
                        </option>
                        <option
                          value="10000"
                          data-select2-id="select2-data-51-p1b5"
                        >
                          €10,000 per month
                        </option>
                        <option
                          value="12500"
                          data-select2-id="select2-data-52-hndq"
                        >
                          €12,500 per month
                        </option>
                        <option
                          value="15000"
                          data-select2-id="select2-data-53-azwk"
                        >
                          €15,000 per month
                        </option>
                        <option
                          value="17500"
                          data-select2-id="select2-data-54-loiu"
                        >
                          €17,500 per month
                        </option>
                        <option
                          value="200000"
                          data-select2-id="select2-data-55-80v2"
                        >
                          €20,000 per month
                        </option>
                        <option
                          value="25000"
                          data-select2-id="select2-data-56-myy5"
                        >
                          €25,000 per month
                        </option>
                        <option
                          value="30000"
                          data-select2-id="select2-data-57-9ioh"
                        >
                          €30,000 per month
                        </option>
                        <option
                          value="35000"
                          data-select2-id="select2-data-58-pf67"
                        >
                          €35,000 per month
                        </option>
                        <option
                          value="40000"
                          data-select2-id="select2-data-59-x15t"
                        >
                          €40,000 per month
                        </option>

                        <option value="" data-select2-id="select2-data-74-8s2a">
                          Min Price
                        </option>
                      </select>
                    </div>
                    <span className="toSeperator">-</span>
                    <div className="maxprice">
                      <select
                        value={maxprice}
                        name="maxPrice"
                        onChange={(e) => {
                          setMaxprice(e.target.value);
                          setcurrentPage(1);
                        }}
                        id="maxPrice"
                        onClick={(e) => {
                          if (e.target.value !== "") {
                            setpriceShow(false);
                          }
                        }}
                        className="select js-example-basic-single2 pricefilterss select2-hidden-accessible"
                        title="Max Price"
                      >
                        <option value="" >
                          Max Price
                        </option>
                        <option value="0">Request Price</option>
                        <option
                          value="100"
                          data-select2-id="select2-data-19-8vy1"
                        >
                          €100 per month
                        </option>
                        <option
                          value="150"
                          data-select2-id="select2-data-20-5tjj"
                        >
                          €150 per month
                        </option>
                        <option
                          value="250"
                          data-select2-id="select2-data-21-vqd0"
                        >
                          €250 per month
                        </option>
                        <option
                          value="300"
                          data-select2-id="select2-data-22-l2va"
                        >
                          €300 per month
                        </option>
                        <option
                          value="400"
                          data-select2-id="select2-data-23-wmkd"
                        >
                          €400 per month
                        </option>
                        <option
                          value="500"
                          data-select2-id="select2-data-24-9ztz"
                        >
                          €500 per month
                        </option>
                        <option
                          value="600"
                          data-select2-id="select2-data-25-ymin"
                        >
                          €600 per month
                        </option>
                        <option
                          value="700"
                          data-select2-id="select2-data-26-mei3"
                        >
                          €700 per month
                        </option>
                        <option
                          value="800"
                          data-select2-id="select2-data-27-b3r0"
                        >
                          €800 per month
                        </option>

                        <option
                          value="900"
                          data-select2-id="select2-data-29-m5qn"
                        >
                          €900 per month
                        </option>
                        <option
                          value="1000"
                          data-select2-id="select2-data-30-faor"
                        >
                          €1,000 per month
                        </option>
                        <option
                          value="1100"
                          data-select2-id="select2-data-31-xpw0"
                        >
                          € 1,100 per month
                        </option>
                        <option
                          value="1200"
                          data-select2-id="select2-data-31-xpw0"
                        >
                          € 1,200 per month
                        </option>
                        <option
                          value="1300"
                          data-select2-id="select2-data-32-cr5h"
                        >
                          €1,300 per month
                        </option>
                        <option
                          value="1400"
                          data-select2-id="select2-data-33-l1dk"
                        >
                          €1,400 per month
                        </option>
                        <option
                          value="1500"
                          data-select2-id="select2-data-34-77b4"
                        >
                          €1,500 per month
                        </option>
                        <option
                          value="1750"
                          data-select2-id="select2-data-35-fgiv"
                        >
                          €1,750 per month
                        </option>
                        <option
                          value="2000"
                          data-select2-id="select2-data-36-tr7k"
                        >
                          €2,000 per month
                        </option>
                        <option
                          value="2250"
                          data-select2-id="select2-data-37-bnmy"
                        >
                          €2,250 per month
                        </option>
                        <option
                          value="2500"
                          data-select2-id="select2-data-38-6pt3"
                        >
                          €2,500 per month
                        </option>
                        <option
                          value="2750"
                          data-select2-id="select2-data-39-gngi"
                        >
                          €2,750 per month
                        </option>
                        <option
                          value="3000"
                          data-select2-id="select2-data-40-d8ej"
                        >
                          €3,000 per month
                        </option>
                        <option
                          value="3500"
                          data-select2-id="select2-data-41-mysy"
                        >
                          €3,500 per month
                        </option>
                        <option
                          value="4000"
                          data-select2-id="select2-data-42-qe4j"
                        >
                          €4,000 per month
                        </option>
                        <option
                          value="4500"
                          data-select2-id="select2-data-43-1dgk"
                        >
                          €4,500 per month
                        </option>
                        <option
                          value="5000"
                          data-select2-id="select2-data-44-4mtd"
                        >
                          €5,000 per month
                        </option>
                        <option
                          value="5500"
                          data-select2-id="select2-data-45-2s0r"
                        >
                          €5,500 per month
                        </option>
                        <option
                          value="6000"
                          data-select2-id="select2-data-46-w5jj"
                        >
                          €6,000 per month
                        </option>
                        <option
                          value="6500"
                          data-select2-id="select2-data-47-f5f6"
                        >
                          €6,500 per month
                        </option>
                        <option
                          value="7000"
                          data-select2-id="select2-data-48-f91u"
                        >
                          €7,000 per month
                        </option>
                        <option
                          value="8000"
                          data-select2-id="select2-data-49-z1vi"
                        >
                          €8,000 per month
                        </option>
                        <option
                          value="9000"
                          data-select2-id="select2-data-50-wqwe"
                        >
                          €9,000 per month
                        </option>
                        <option
                          value="10000"
                          data-select2-id="select2-data-51-p1b5"
                        >
                          €10,000 per month
                        </option>
                        <option
                          value="12500"
                          data-select2-id="select2-data-52-hndq"
                        >
                          €12,500 per month
                        </option>
                        <option
                          value="15000"
                          data-select2-id="select2-data-53-azwk"
                        >
                          €15,000 per month
                        </option>
                        <option
                          value="17500"
                          data-select2-id="select2-data-54-loiu"
                        >
                          €17,500 per month
                        </option>
                        <option
                          value="200000"
                          data-select2-id="select2-data-55-80v2"
                        >
                          €20,000 per month
                        </option>
                        <option
                          value="25000"
                          data-select2-id="select2-data-56-myy5"
                        >
                          €25,000 per month
                        </option>
                        <option
                          value="30000"
                          data-select2-id="select2-data-57-9ioh"
                        >
                          €30,000 per month
                        </option>
                        <option
                          value="35000"
                          data-select2-id="select2-data-58-pf67"
                        >
                          €35,000 per month
                        </option>
                        <option
                          value="40000"
                          data-select2-id="select2-data-59-x15t"
                        >
                          €40,000 per month
                        </option>
                        <option value="">Max Price</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="bedsearch">
                <div className="beds-btn" onClick={() => handleBed()}>
                  <span className="beds-btn-text" id="beds-btn-text">
                    {minbed !== ""
                      ? "" + minbed + "-" + "" + maxbed
                      : document.documentElement.lang == "es" ? <c class="notranslate">Habitaciones</c> : "Any Bed"}
                  </span>
                </div>
                <div className="min-max-beds">
                  <div
                    className={
                      bedShow == true ? "innerbeds active" : "innerbeds"
                    }
                  >
                    <div className="minBedrooms">
                      <select
                        name="minBedrooms"
                        onChange={(e) => setminbed(e.target.value)}
                        className="js-example-basic-single2 bedroomsfilterss select2-hidden-accessible"
                        id="minBedrooms"
                        value={minbed}
                        title="Min Beds"
                      >
                        <option value="" name="">
                          {document.documentElement.lang == "es" ? "Minimo" : "Min Beds"}
                        </option>
                        <option value="Studio" name="Studio">
                          Studio
                        </option>
                        <option value="1" name="1 Bed">
                          1 Bed
                        </option>
                        <option value="2" name="2 Beds">
                          2 Bed
                        </option>
                        <option value="3" name="3 Beds">
                          3 Bed
                        </option>
                        <option value="4" name="4 Beds">
                          4 Bed
                        </option>
                        <option value="5" name="5 Beds">
                          5 Bed
                        </option>
                        <option value="6" name="6 Beds">
                          6 Bed
                        </option>
                        <option value="7" name="7 Beds">
                          7 Bed
                        </option>
                        <option value="8" name="8 Beds">
                          8 Bed
                        </option>
                        <option value="9" name="9 Beds">
                          9 Bed
                        </option>
                        <option value="10" name="10 Beds">
                          10 Bed
                        </option>
                        <option value="" name="Any Beds">
                          Min Beds
                        </option>
                      </select>
                    </div>
                    <span className="toSeperator">-</span>
                    <div className="maxBedrooms">
                      <select
                        name="maxBedrooms"
                        onChange={(e) => {
                          setcurrentPage(1);
                          setmaxbed(e.target.value);
                        }}
                        onClick={(e) => {
                          if (e.target.value !== "") {
                            setbedShow(false);
                          }
                        }}
                        value={maxbed}
                        className="js-example-basic-single2 bedroomsfilterss select2-hidden-accessible"
                        title="Max Beds"
                        data-select2-id="select2-data-11-43qq"
                        tabindex="-1"
                        aria-hidden="true"
                      >
                        <option
                          value=""
                          name=""
                          data-select2-id="select2-data-13-3tqy"
                        >
                          {document.documentElement.lang == "es" ? "Maximo" : "Max Beds"}
                        </option>
                        <option value="Studio" name="Studio">
                          Studio
                        </option>
                        <option value="1" name="1 Bed">
                          1 Bed
                        </option>
                        <option value="2" name="2 Beds">
                          2 Bed
                        </option>
                        <option value="3" name="3 Beds">
                          3 Bed
                        </option>
                        <option value="4" name="4 Beds">
                          4 Bed
                        </option>
                        <option value="5" name="5 Beds">
                          5 Bed
                        </option>
                        <option value="6" name="6 Beds">
                          6 Bed
                        </option>
                        <option value="7" name="7 Beds">
                          7 Bed
                        </option>
                        <option value="8" name="8 Beds">
                          8 Bed
                        </option>
                        <option value="9" name="9 Beds">
                          9 Bed
                        </option>
                        <option value="10" name="10 Beds">
                          10 Bed
                        </option>
                        <option value="" name="Any Beds">
                          Max Beds
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group" style={{ zIndex: "111" }}>
              <div className="propertytypesearch">
                <span
                  className="c-select"
                  onClick={() => {
                    setShow(false);
                    setpropertyTypeShow(!propertyTypeShow);
                    setdialogShow(false);
                    setpriceShow(false);
                    setbedShow(false);
                    setfilterShow(false);
                    setShowbookmark(false);
                  }}
                >
                  {document.documentElement.lang == "es" ? <c class="notranslate">Tipo de inmueble</c> : " Property type"}
                </span>
                {propertyTypeShow && propertyTypeShow == true ? (
                  <div className="propertytypelist active">
                    <ul className="propertytypelist-list">
                      <li>
                        <input
                          type="checkbox"
                          value="Flat"
                          onChange={(e) => handleCheckProperty(e)}
                          className="checkboxfilter"
                          defaultChecked={
                            apartmant.filter((vlu) => vlu == "Flat")[0]
                              ? true
                              : false
                          }
                        />
                        <div className="customtypedata">
                          <img src="https://bellaislas.com/wp-content/uploads/2021/05/apartment.png" />
                          <label>{"Flat"}</label>
                        </div>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          value="Country House"
                          onChange={(e) => handleCheckProperty(e)}
                          className="checkboxfilter"
                          defaultChecked={
                            apartmant.filter((vlu) => vlu == "Country House")[0]
                              ? true
                              : false
                          }
                        />
                        <div className="customtypedata">
                          <img src="https://bellaislas.com/wp-content/uploads/2021/08/country-house.png" />
                          <label>Country House</label>
                        </div>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          value="Finca"
                          onChange={(e) => handleCheckProperty(e)}
                          className="checkboxfilter"
                          defaultChecked={
                            apartmant.filter((vlu) => vlu == "Finca")[0]
                              ? true
                              : false
                          }
                        />
                        <div className="customtypedata">
                          <img src="https://bellaislas.com/wp-content/uploads/2021/08/finca.png" />
                          <label>Finca</label>
                        </div>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          value="Ground Flat"
                          onChange={(e) => handleCheckProperty(e)}
                          className="checkboxfilter"
                          defaultChecked={
                            apartmant.filter((vlu) => vlu == "Ground Flat")[0]
                              ? true
                              : false
                          }
                        />
                        <div className="customtypedata">
                          <img src="https://bellaislas.com/wp-content/uploads/2021/08/ground-flat.png" />
                          <label>Ground Flat</label>
                        </div>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          value="House"
                          onChange={(e) => handleCheckProperty(e)}
                          className="checkboxfilter"
                          defaultChecked={
                            apartmant.filter((vlu) => vlu == "House")[0]
                              ? true
                              : false
                          }
                        />
                        <div className="customtypedata">
                          <img src="https://bellaislas.com/wp-content/uploads/2021/05/house.png" />
                          <label>House</label>
                        </div>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          value="Land"
                          onChange={(e) => handleCheckProperty(e)}
                          className="checkboxfilter"
                          defaultChecked={
                            apartmant.filter((vlu) => vlu == "Land")[0]
                              ? true
                              : false
                          }
                        />
                        <div className="customtypedata">
                          <img src="https://bellaislas.com/wp-content/uploads/2021/05/land.png" />
                          <label>Land</label>
                        </div>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          value="Penthouse"
                          onChange={(e) => handleCheckProperty(e)}
                          className="checkboxfilter"
                          defaultChecked={
                            apartmant.filter((vlu) => vlu == "Penthouse")[0]
                              ? true
                              : false
                          }
                        />
                        <div className="customtypedata">
                          <img src="https://bellaislas.com/wp-content/uploads/2021/08/penthouse.png" />
                          <label>Penthouse</label>
                        </div>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          value="Town House"
                          onChange={(e) => handleCheckProperty(e)}
                          className="checkboxfilter"
                          defaultChecked={
                            apartmant.filter((vlu) => vlu == "Town House")[0]
                              ? true
                              : false
                          }
                        />
                        <div className="customtypedata">
                          <img src="https://bellaislas.com/wp-content/uploads/2021/08/townhouse.png" />
                          <label>Town House</label>
                        </div>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          value="Villa"
                          onChange={(e) => handleCheckProperty(e)}
                          className="checkboxfilter"
                          defaultChecked={
                            apartmant.filter((vlu) => vlu == "Villa")[0]
                              ? true
                              : false
                          }
                        />
                        <div className="customtypedata">
                          <img src="https://bellaislas.com/wp-content/uploads/2021/08/villa.png" />
                          <label>Villa</label>
                        </div>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          value="Garage"
                          onChange={(e) => handleCheckProperty(e)}
                          name="propertytagstype[]"
                          className="checkboxtagfilter"
                          defaultChecked={
                            filterSearch.filter((vlu) => vlu == "Garage")[0]
                              ? true
                              : false
                          }
                        />
                        <div className="garage_logo customtypedata ">
                          <img
                            src={require("../../assets/images/garage.png")}
                            style={{ opacity: "1" }}
                          />
                          <label>Garage</label>
                        </div>
                      </li>
                      <div className="doneFilter">
                        <button
                          type="button"
                          id="done_propertytype"
                          onClick={() => {
                            setpriceShow(false);
                            setbedShow(false);
                            setfilterShow(false);
                            propertyTypeSubmit();
                            setcurrentPage(1);
                          }}
                        >
                          Done
                        </button>
                      </div>
                    </ul>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="form-group" style={{ zIndex: "111" }}>
              <div className="customfiltersearch">
                <span
                  className="c-select"
                  onClick={() => {
                    setpriceShow(false);
                    setbedShow(false);
                    setpropertyTypeShow(false);
                    setShowbookmark(false);

                    setfilterShow(!filterShow);
                  }}
                >
                  Filters{" "}
                </span>
                {filterShow && filterShow == true ? (
                  <div className="mainfiltersection active">
                    <div className="datefilter-Outer">
                      <div className="datefilter">
                        <div className="addedSite__select">
                          <label>Added to Site:</label>
                          <div className="select-outer">
                            <select
                              name="addedToSite"
                              onChange={(e) => setadded_site(e.target.value)}
                              className="select"
                              value={added_site}
                            >
                              <option value="">Anytime</option>

                              <option value="1">{document.documentElement.lang == "es" ? <c class="notranslate">últimas 24 horas</c> : <c> Last 24 hours</c>}</option>
                              <option value="3">{document.documentElement.lang == "es" ? <c class="notranslate">últimos 3 días</c> : <c>Last 3 days</c>} </option>
                              <option value="7">{document.documentElement.lang == "es" ? <c class="notranslate">últimos 7 días</c> : <c>Last 7 days</c>} </option>
                              <option value="14">{document.documentElement.lang == "es" ? <c class="notranslate">últimos 14 días</c> : <c>Last 14 days</c>} </option>
                            </select>
                          </div>
                        </div>

                        <div className="search-subtype-property">
                          <label><label>{document.documentElement.lang == "es" ? <c class="notranslate">Subtipo de inmueble:</c> : <c>Property Subtype:</c>}</label></label>
                          <select
                            name="subtype"
                            id="subtype-propertyy"
                            className="subtype-propertyy"
                            onChange={(e) => setsubtype(e.target.value)}
                            value={subtype}
                          >
                            <option value="">Select</option>
                            <option value="New Build">New Build</option>
                            <option value="Exclusive">Exclusive</option>
                            <option value="Tourist License">
                              Tourist License
                            </option>
                            <option value="New Project">New Project</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="tagsfilter-Outer">
                      <div className="tagsfilter">
                        <ul className="taglist-list" id="taglist-list">
                          <li>
                            <input
                              type="checkbox"
                              value="airplay"
                              // onChange={(e) => handleCheckProperty(e)}
                              id="airplay"
                              name="propertytagstype[]"
                              className="checkboxtagfilter"
                              onChange={(e) => handlefilterProperty(e)}
                              defaultChecked={
                                filterSearch.filter(
                                  (vlu) => vlu == "airplay"
                                )[0]
                                  ? true
                                  : false
                              }
                            />
                            <div className="customtypedata">
                              <span className="notranslate material-symbols-outlined">
                                airplay
                              </span>
                              <label>Air conditioning</label>
                            </div>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              value="Beach"
                              onChange={(e) => handlefilterProperty(e)}
                              name="propertytagstype[]"
                              className="checkboxtagfilter"
                              defaultChecked={
                                filterSearch.filter((vlu) => vlu == "Beach")[0]
                                  ? true
                                  : false
                              }
                            />
                            <div className="customtypedata">
                              <span class="notranslate material-symbols-outlined">
                                beach_access
                              </span>
                              <label>Beach</label>
                            </div>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              value="Disabled Access"
                              onChange={(e) => handlefilterProperty(e)}
                              name="propertytagstype[]"
                              className="checkboxtagfilter"
                              defaultChecked={
                                filterSearch.filter(
                                  (vlu) => vlu == "Disabled Access"
                                )[0]
                                  ? true
                                  : false
                              }
                            />
                            <div className="customtypedata">
                              <i className="fa fa-wheelchair"></i>
                              <label>Disabled Access</label>
                            </div>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              value="Garage"
                              onChange={(e) => handlefilterProperty(e)}
                              name="propertytagstype[]"
                              className="checkboxtagfilter"
                              defaultChecked={
                                filterSearch.filter((vlu) => vlu == "Garage")[0]
                                  ? true
                                  : false
                              }
                            />
                            <div className="customtypedata">
                              <i className="fa fa-car"></i>
                              <label>Garage</label>
                            </div>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              value="Lift"
                              onChange={(e) => handlefilterProperty(e)}
                              name="propertytagstype[]"
                              className="checkboxtagfilter"
                              defaultChecked={
                                filterSearch.filter((vlu) => vlu == "Lift")[0]
                                  ? true
                                  : false
                              }
                            />
                            <div className="customtypedata">
                              <span className="notranslate material-symbols-outlined">
                                forklift
                              </span>
                              <label>{document.documentElement.lang == "es" ? <c class="notranslate">Ascensor</c> : <c>Lift</c>}</label>
                            </div>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              value="Parking"
                              onChange={(e) => handlefilterProperty(e)}
                              name="propertytagstype[]"
                              className="checkboxtagfilter"
                              defaultChecked={
                                filterSearch.filter(
                                  (vlu) => vlu == "Parking"
                                )[0]
                                  ? true
                                  : false
                              }
                            />
                            <div className="customtypedata">
                              <i className="fa fa-parking"></i>
                              <label>Parking</label>
                            </div>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              value="Sea Views"
                              onChange={(e) => handlefilterProperty(e)}
                              name="propertytagstype[]"
                              className="checkboxtagfilter"
                              defaultChecked={
                                filterSearch.filter(
                                  (vlu) => vlu == "Sea Views"
                                )[0]
                                  ? true
                                  : false
                              }
                            />
                            <div className="customtypedata">
                              <span className="notranslate material-symbols-outlined">
                                visibility
                              </span>
                              <label>Sea Views</label>
                            </div>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              value="Swimming Pool"
                              onChange={(e) => handlefilterProperty(e)}
                              name="propertytagstype[]"
                              className="checkboxtagfilter"
                              defaultChecked={
                                filterSearch.filter(
                                  (vlu) => vlu == "Swimming Pool"
                                )[0]
                                  ? true
                                  : false
                              }
                            />
                            <div className="customtypedata">
                              <i className="fa fa-swimmer"></i>

                              <label>Swimming Pool</label>
                            </div>
                          </li>
                          <div className="doneFilter">
                            <button
                              type="button"
                              id="done_filter"
                              onClick={() => {
                                setpriceShow(false);
                                setbedShow(false);
                                setfilterShow(false);
                                setpropertyTypeShow(false);
                                searchfilterHandle();
                              }}
                            >
                              Done
                            </button>
                          </div>
                        </ul>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="form-group">
              <button
                type="button"
                className="save-search"
                onClick={(e) => {
                  fetchproperty();
                  handleSearch(e);
                }}
              >
                Save Search
              </button>
            </div>
          </form>
          {dialogShow && dialogShow == true ? (
            <div id="save-search-div" className="save-search-div active">
              <h2>Save your search and alerts</h2>
              <form action="" id="save-search-form" method="post">
                <div className="MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth">
                  <label>What would you like to call this search?</label>
                  <input
                    aria-invalid="true"
                    id="save-search-name"
                    name="save-search-name"
                    value={searchName}
                    onChange={(e) => setsearchName(e.target.value)}
                    type="text"
                    required=""
                    className="MuiInput-input"
                  />
                  <label>Get alerts on my search criteria</label>
                  <select
                    name="alerts-frequency"
                    required=""
                    id="alerts-frequency"
                    value={alerts}
                    onChange={(e) => setAlerts(e.target.value)}
                  >
                    <option value="daily">Daily</option>
                    <option value="three-days">Every 3 Days</option>
                    <option value="weekly">Weekly</option>
                    <option value="never">Never</option>
                    <option value="instant">Instant</option>
                  </select>
                  {login && login == true ? (
                    <button
                      type="button"
                      name="submit"
                      id="save-search-submit"
                      onClick={() => saveSearch()}
                    >
                      Save
                    </button>
                  ) : (
                    <button
                      // type="submit"
                      // name="submit"
                      id="save-search-submit"
                      onClick={() => navigate("/signin")}
                      style={{ width: "110px", maxWidth: "110px" }}
                    >
                      Register/Login
                    </button>
                  )}

                  <button
                    type="button"
                    id="cancel-search-submit"
                    style={{ margin: "0 5px" }}
                    onClick={(e) => handleSearch(e)}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          ) : (
            ""
          )}

          <div
            class="responsive_filter"
            onClick={() => setMobileMenu(!MobileMenu)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="22"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
              ></path>
            </svg>
          </div>

          <div className="bookmark-btn-outer">
            {login && login == true ? (
              <>
                <button
                  type="button"
                  id="bookmark-btn"
                  onClick={() => {
                    setShowbookmark(!Showbookmark);
                    setdialogShow(false);
                    setMobileMenu(false);
                    setShow(false);
                    setpriceShow(false);
                    setbedShow(false);
                    setfilterShow(false);
                    setpropertyTypeShow(false);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    {" "}
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
                    ></path>{" "}
                  </svg>
                </button>
                <span className="bookmark-coumt">{data.length}</span>
              </>
            ) : (
              ""
            )}
          </div>
          {Showbookmark && Showbookmark == true ? (
            <div id="bookmarks" className="bookmarks active">
              <h4>
                <div className="bookmark-btn-outer">
                  <button
                    type="button"
                    id="bookmark-btn"
                    onClick={() => setShowbookmark(false)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      {" "}
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
                      ></path>{" "}
                    </svg>
                  </button>
                  <span className="bookmark-coumt">{data.length}</span>
                </div>{" "}
                Saved searches:
              </h4>
              <p>All your saved searches in one place</p>
              <table id="tbUser" className="table_resp table ">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Location</th>
                    <th>Price Range</th>
                    <th>Beds</th>
                    <th>Alert Frequency</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    // { console.log("jjjjjj", item) }
                    <tr colspan="6" key={item.id}>
                      <td>{item.name}</td>
                      <td>{item.locations}</td>
                      <td className="notranslate">
                        {nf.format(item.min_price)}-{nf.format(item.max_price)}
                      </td>
                      <td className="notranslate">
                        {item.min_beds}-{item.max_beds}
                      </td>
                      <td>
                        <input
                          type="hidden"
                          name="saved-search-id"
                          id="saved-search-id"
                          class="saved-search-id"
                          value="49"
                        />
                        <select
                          name="alerts-frequency"
                          required=""
                          class="form-control"
                          id="alerts-frequency"
                          defaultValue={item.alert}
                          onChange={(e) => Alert_Frequency(item.id, e.target.value)}
                        >
                          <option value="daily">Daily</option>
                          <option value="three-days">Every 3 Days</option>
                          <option value="weekly">Weekly</option>
                          <option value="never">Never</option>
                          <option value="instant">Instant</option>
                        </select>
                      </td>
                      <td>
                        <a class="frq-link" href="/">
                          Search
                        </a>
                      </td>
                      <td>
                        <button
                          class="frqDeleteBtn"
                          data-id="49"
                          type="submit"
                          onClick={() => delateSearch(item.id)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="#000"
                          >
                            {" "}
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M6 18L18 6M6 6l12 12"
                            ></path>{" "}
                          </svg>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            ""
          )}
        </div>
        <p
          style={{
            textAlign: "center",
            padding: "10px 0 0",
            fontSize: "17px",
            color: "red",
          }}
        >
          {savesearcherror}
        </p>
      </section>
      {/* {    <!-- top fillter section end here -->} */}

      {loading !== true ? (
        <div className="loader_set">
          {" "}
          <img src={loaderImg} alt="loading..." />{" "}
        </div>
      ) : (
        <>
          <Rent
            details={details}
            error={error}
            town={searchValue}
            totalResult={property}
            setshorting={setshorting}
            shorting={shorting}
            total={total}
            premiumDetails={premiumDetails}
          />
          <div className="pagination_div">
            {/* <Pagination
               pageSize={61}
              onChange={(vlu) => setPageValue(vlu)}
              total={4}
            /> */}
            <button
              onClick={handlePrevbtn}
              disabled={currentPage == pages[0] ? true : false}
            >
              Prev
            </button>{" "}
            <p style={{ display: "flex" }}>
              {pages.map((number, index) => {
                if (index > currentPage - 5 && index < currentPage + 5) {
                  return (
                    <span
                      key={number}
                      id={number}
                      onClick={() => {
                        navigate("#page=" + (number))
                        setcurrentPage(number)
                      }}
                      className={`${currentPage == number ? "active_page" : ""
                        }`}
                    >
                      {number}{" "}
                    </span>
                  );
                }
              })}
            </p>
            <button
              onClick={handleNextbtn}
              disabled={currentPage == pages[pages.length - 1] ? true : false}
            >
              Next
            </button>
          </div>
        </>
      )}
    </Layout>
  );
}

export default Searchlisting;
