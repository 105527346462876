import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../../baseUrl";
import loaderImg from "../../../assets/images/loader.svg";
import moment from "moment";
import swal from "sweetalert";

function Section3() {


  const [popup, setpopup] = useState(false);
  const [data, setData] = useState(1);
  const [agentData, setAgentData] = useState([]);
  const navigate = useNavigate();
  const [userData, setUserData] = useState();
  const token = JSON.parse(localStorage.getItem("token"));
  const [loading, setloading] = useState(false);
  const islogin = JSON.parse(sessionStorage.getItem("login"));
  const userinfo = JSON.parse(localStorage.getItem("user"));
  const [agentlist, setagentlist] = useState();
  const [currentpae, setcurrentpae] = useState(1);
  const [currentpage2, setcurrentpage2] = useState(1);
  //const page = [];
  const page2 = [];
  const [forPagination, setForPagination] = useState([]);
  const [forPagination2, setForPagination2] = useState([]);
  const [leadReportdata, setLeadReportData] = useState();

  const [performancedata, setperformancedata] = useState();
  console.log(">>>>>>>>>>>", performancedata);


  //const [data, setData] = useState([]);

  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(5);

  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

  const handleClick = (event) => {
    setcurrentPage();
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(performancedata?.length / itemsPerPage); i++) {
    pages.push(i);
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = performancedata?.slice(indexOfFirstItem, indexOfLastItem);

  const renderPageNumbers = pages.map((number) => {
    console.log(number);
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentPage == number ? "active" : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
  }








  //console.log("leadReportdata", leadReportdata);

  let totalPage = forPagination.length - 2;
  let totalPage2 = leadReportdata?.enquiry?.links.length - 2;
  for (let i = 0; i < Math.ceil(totalPage); i++) {
    // if ((totalPage - 10) > -9) {
    //   totalPage = (totalPage - 10)
    // }
    // page.push(i + 1);
  }
  for (let i = 0; i < Math.ceil(totalPage2); i++) {
    // if ((totalPage - 10) > -9) {
    //   totalPage = (totalPage - 10)
    // }
    page2.push(i + 1);
  }

  const [performanceSearchValue, setperformanceSearchValue] = useState("");


  let nf = new Intl.NumberFormat();


  const [startingdate, setstartingDate] = useState("");
  const [endingdate, setEndingDate] = useState("");
  console.log(">>>>>>>>> startingdate", startingdate);


  const [premium, setpremium] = useState([]);
  //console.log("agentlist==>>>>", premium);
  const premiumpagination = [];
  const lastpage = premium.property?.last_page;
  for (let i = 0; i < Math.ceil(lastpage); i++) {
    premiumpagination.push(i + 1);
  }

  const myPropertyperformance = async () => {
    await axios
      .get(baseUrl + `/agent/property-performance`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.status == 200) {
          setperformancedata(res.data?.data);
          console.log(">>>>>>>>>>>", res.data.data);
          setloading(true);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error) {
          setloading(true);
        }
      });
  };

  const [searhValue, setSearhValue] = useState("");
  const [searhValueData, setSearhValueData] = useState(agentlist);

  const setSearhValueHandle = (e) => {
    console.log(parseFloat(e.target.value));
    setSearhValue(e.target.value);
    if (e.target.value === "") {
      setSearhValueData(agentlist.data);
      // console.log(agentlist ,"agentlisdfgdst");
    } else {
      const filterdata = agentlist.data.filter((item) =>
        item.postcode.includes(parseFloat(e.target.value))
      );

      console.log(filterdata, "filterdata");
      setSearhValueData(filterdata);
    }
    // setSearhValue(e.target.value);
  };
  useEffect(() => {
    if (userinfo) {
      if (userinfo.user_type == "User") {
        setUserData(userinfo);
      } else if (userinfo.user_type == "Agent") {
        setUserData(userinfo);
      }
    }

    myPropertyperformance();
  }, []);

  const performancefilterHandle = (e) => {
    setperformanceSearchValue(e.target.value);
  };




  const [shorted, setshorted] = useState({ shorted: "id", reversed: false });
  const shortedbyDate = () => {
    setshorted({ shorted: "id", reversed: !shorted.reversed });
    if (shorted.reversed) {
      performancedata?.sort((a, b) => new Date(moment(a.get_property?.updated_at).format(
        "DD/MM/YYYY"
      )) - new Date(moment(b.get_property?.updated_at).format(
        "DD/MM/YYYY"
      )));
    } else {
      performancedata?.sort((a, b) => new Date(moment(b.get_property?.updated_at).format(
        "DD MM, YYYY"
      )) - new Date(moment(a.get_property?.updated_at).format(
        "DD MM, YYYY"
      )));
    }
  };

  const shortedPrice = () => {
    setshorted({ shorted: "id", reversed: !shorted.reversed });
    if (shorted.reversed) {
      performancedata?.sort(
        (valuA, valuB) => valuA.get_property?.data.price - valuB.get_property?.data.price
      );
    } else {
      performancedata?.sort(
        (valuA, valuB) => valuB.get_property?.data.price - valuA.get_property?.data.price
      );
    }
  };
  const shorteddailyview = () => {
    setshorted({ shorted: "id", reversed: !shorted.reversed });
    if (shorted.reversed) {
      performancedata?.sort(
        (valuA, valuB) => valuA?.daily - valuB?.daily
      );
    } else {
      performancedata?.sort(
        (valuA, valuB) => valuB?.daily - valuA?.daily
      );
    }
  };
  const shortedlast7daysview = () => {
    setshorted({ shorted: "id", reversed: !shorted.reversed });
    if (shorted.reversed) {
      performancedata?.sort(
        (valuA, valuB) => valuA?.last_7_days - valuB?.last_7_days
      );
    } else {
      performancedata?.sort(
        (valuA, valuB) => valuB?.last_7_days - valuA?.last_7_days
      );
    }
  };
  const shortedbyid = () => {
    setshorted({ shorted: "id", reversed: !shorted.reversed });
    if (shorted.reversed) {
      performancedata?.sort(
        (valuA, valuB) => valuA?.id - valuB?.id
      );
    } else {
      performancedata?.sort(
        (valuA, valuB) => valuB?.id - valuA?.id
      );
    }
  };


  return (
    <>
      {loading == false ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItem: "center",
            width: "100%",
            height: "75%",
          }}
        >
          <img src={loaderImg} style={{ width: "100px" }} />
        </div>
      ) :
        <div
          className="main-wrapper availableProperty"
          style={{ display: "block" }}
          id="menuThree"
        >
          <h2>Property Perfomance</h2>
          <div className="filterDownload flex">
            <form action="">
              <div className="form_group">
                <input
                  type="search"
                  placeholder="Find by address or postcode"
                  onChange={performancefilterHandle}
                />
              </div>
              <div className="form_group">
                <button>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                    ></path>
                  </svg>
                </button>
              </div>
            </form>
            <p>
              {performancedata?.length
                ? performancedata?.length
                : "0"}{" "}
              Listings
            </p>
          </div>
          <div className="tableWrap">
            <table
              className="table1 tablesorter tablesorter-blue tablesorter72d6f05d2d007"
              data-sortlist="[[0,0],[2,0]]"
            >
              <thead>
                <tr className="tablesorter-headerRow">
                  <th className="tablesorter-header tablesorter-headerAsc" onClick={shortedbyid}>
                    Address
                  </th>
                  <th
                    className="tablesorter-header tablesorter-headerAsc"
                    onClick={shortedPrice}
                  >
                    Listing-price
                  </th>
                  <th className="tablesorter-header tablesorter-headerAsc" onClick={shortedbyDate}>
                    Updated date
                  </th>
                  <th className="tablesorter-header tablesorter-headerAsc" onClick={shorteddailyview}>
                    Daily views
                  </th>
                  <th className="tablesorter-header tablesorter-headerAsc" onClick={shortedlast7daysview}>
                    vs.prev 7 days
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentItems
                  ? currentItems
                    ?.filter((item) => {
                      if (performanceSearchValue == "") {
                        return item;
                      } else {
                        return item.get_property?.town
                          .toLowerCase()
                          .includes(
                            performanceSearchValue.toLowerCase()
                          );
                      }
                    })
                    ?.map((item, index) => {
                      // console.log(item);

                      return (
                        <tr key={item.id}>
                          <td>
                            <div class="propertyOuter">
                              <div class="property-img">
                                <img src={item?.get_property?.data?.images?.image[0].url} />
                              </div>
                              <div class="property-name notranslate">
                                {item?.get_property?.data
                                  ? item?.get_property?.data.town?.replace(
                                    /[\[,"\]']+/g,
                                    "  "
                                  )
                                  : "Not Added"}

                                <br /> Ref :
                                {
                                  item?.get_property?.data
                                    ? item?.get_property?.data?.ref : ""
                                }
                              </div>
                            </div>

                            {/* {item?.get_property?.data
                                          ? item?.get_property?.data.town?.replace(
                                              /[\[,"\]']+/g,
                                              "  "
                                            )
                                          : "Not Added"} */}
                          </td>
                          <td className="notranslate">
                            €{" "}
                            {item?.get_property?.data
                              ? nf.format(
                                item?.get_property?.data.price
                              )
                              : "Not Added"}
                          </td>
                          <td>
                            {moment(item?.updated_at).format(
                              "DD MMM, YYYY"
                            )}
                          </td>
                          <td>
                            {item?.daily ? item?.daily : "0"} <br />{" "}
                            avg. views
                          </td>
                          <td>
                            <i className="text-success">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="17"
                                height="17"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="#07db14"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M5 10l7-7m0 0l7 7m-7-7v18"
                                ></path>
                              </svg>
                            </i>{" "}
                            {item?.last_7_days
                              ? item?.last_7_days
                              : "0"}
                            <br /> vs. prev 7 days
                          </td>
                        </tr>
                      );
                    })
                  : ""}
              </tbody>
            </table>
          </div>

          <div className="bottomPagenation">
            <div
              className="dataTables_info"
              id="datatable-tabletools_info"
            >
              {currentPage * performancedata?.length == 0 ? 0: 5}   of  {performancedata?.length
                ? performancedata?.length
                : "0"}{" "} entries
            </div>
            <div className="pagenationTable">
              <button
                onClick={handlePrevbtn}
                disabled={currentPage == pages[0] ? true : false}
              >
                Prev
              </button>
              {
                pages?.map((number) => {

                  if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
                    return (

                      <p key={number}
                        id={number}
                        onClick={() => setcurrentPage(number)}
                        className={currentPage == number ? "active" : null}>
                        <span className={currentPage == number ? "active_page" : ""}>{number}</span>
                      </p>
                    );
                  } else {
                    return null;
                  }
                })
              }

              <button
                onClick={handleNextbtn}
                disabled={currentPage == pages[pages.length - 1] ? true : false}
              >
                Next
              </button>
            </div>
          </div>
        </div>

      }
    </>
  )
}

export default Section3