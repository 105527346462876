import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { baseUrl } from "../../baseUrl";
import Layout from "../Layout/Layout";
import loader from "../../assets/images/loader.svg";
import $ from "jquery"
function SpanishBuy() {
  const { id } = useParams();
  console.log(id);
  const token = JSON.parse(localStorage.getItem("token"));
  const [adivcer, setadvicer] = useState([]);
  const [backimage, setbackimage] = useState("");
  const [loading, setloading] = useState(false);

  // console.log("ffff", backimage[0]?.image);
  const fetchbuyadvice = async () => {
    await axios
      .get(baseUrl + `/buying-advice?id=${id}`, {
        headers: { Authorization: `No Auth` },
      })
      .then((res) => {
        // console.log(res);
        setbackimage(res.data?.data)
        setadvicer(res.data.data[0]?.get_lang);
        if (res.status == 200) {
          setloading(true);

        }
      })
      .catch((error) => {
        console.log(error);
        if (error) {
          setloading(true);
        }
      });
  };

  useEffect(() => {
    fetchbuyadvice();
  }, []);



  // console.log(window.innerHeight);



  const [scrolltoTop, setscrolltoTop] = useState(0)
  const onscroll = () => {
    const winscroll = document.documentElement.scrolltoTop
    const height = document.documentElement.scrollHeight - document.documentElement.clientHeight
    const scrolled = (winscroll / height) * 100
    setscrolltoTop(scrolled)


  }

  // console.log(window.height ,document.documentElement.scrollHeight);

  useEffect(() => {
    //   window.addEventListener("scroll" ,onscroll)
    // return() =>   window.removeEventListener("scroll" ,onscroll)

    var blankAgHieght = $(".ag-timeline_list").height(),
      listLastHeight = $('.ag-timeline_list .ag-timeline_item:last').height(),
      actualBlankAgHeight = blankAgHieght - listLastHeight;
    $('.timeline .default-line').height(actualBlankAgHeight);

    var items = $(".ag-timeline_list .ag-timeline_item")
    let timelineHeight = $(".ag-timeline_list").height()
    let greyLine = $('.default-line')
    let lineToDraw = $('.draw-line');

    if (lineToDraw.length) {
      $(window).on('scroll', function () {
        var redLineHeight = lineToDraw.height()
        let greyLineHeight = greyLine.height()
        let windowDistance = $(window).scrollTop()
        let windowHeight = $(window).height() / 2
        let timelineDistance = $(".timeline").offset().top;

        if (windowDistance >= timelineDistance - windowHeight) {
          let line = windowDistance - timelineDistance + windowHeight;

          if (line <= greyLineHeight) {
            lineToDraw.css({
              'height': line + 20 + 'px'
            });
          }
        }
        var bottom = lineToDraw.offset().top + lineToDraw.outerHeight(true);
        items.each(function (index) {
          var circlePosition = $(this).offset();
          if (bottom > (circlePosition.top + 110)) {
            console.log(bottom, circlePosition.top);
            $(this).addClass('in-view');
          } else {
            $(this).removeClass('in-view');
          }
        });

      });
    }



  }, [document.documentElement.scrollHeight])







  return (
    <>
      <Layout>
        {loading !== true ? (
          <div className="loader_set">
            {" "}
            <img src={loader} alt="loading..." />{" "}
          </div>
        ) : (
          <>
            {/* <!-- inner banner section start here --> */}
            <section className="innerBanner innerBanner_spanish"
              style={{
                backgroundImage: `url(` + backimage[0]?.image + `)`
              }}
            >
              <div className="inner-heading">
                <h1>Helpful Tips and Guidance</h1>
                <h3>THE PROCESS OF PURCHASING OF A PROPERTY IN MALLORCA</h3>
              </div>
            </section>
            {/* <!-- inner banner section end here -->

    <!-- buying advice section start here --> */}
            <section className="buyingAdvice">
              <div className="container-sm">
                <div className="ag-timeline-block">
                  <section className="ag-section">
                    <div className="ag-format-container">
                      <div className="js-timeline ag-timeline">
                        <div class="timeline">
                          <span class="default-line" style={id == 3 ? { maxHeight: "2437.33px" } : {}}></span>
                          <span class="draw-line" style={id == 1 ? { height: "1850px" } : id == 3 ? { maxHeight: "2437.33px" } : {}}></span>
                        </div>
                        <div className="ag-timeline_list">
                          <div className="js-timeline_item ag-timeline_item">
                            <div className="ag-timeline-card_item">
                              <div className="ag-timeline-card_inner">
                                <div className="ag-timeline-card_info">
                                  <div className="ag-timeline-card_title">
                                    {adivcer[0]?.title}
                                  </div>
                                  <div className="ag-timeline-card_desc">
                                    {adivcer[0]?.description}
                                  </div>
                                </div>
                              </div>
                              <div className="ag-timeline-card_arrow"></div>
                            </div>
                            <div className="ag-timeline-card_box">
                              <div className="js-timeline-card_point-box ag-timeline-card_point-box">
                                <div className="ag-timeline-card_point">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                  >
                                    <path d="M497.9 142.1l-46.1 46.1c-4.7 4.7-12.3 4.7-17 0l-111-111c-4.7-4.7-4.7-12.3 0-17l46.1-46.1c18.7-18.7 49.1-18.7 67.9 0l60.1 60.1c18.8 18.7 18.8 49.1 0 67.9zM284.2 99.8L21.6 362.4.4 483.9c-2.9 16.4 11.4 30.6 27.8 27.8l121.5-21.3 262.6-262.6c4.7-4.7 4.7-12.3 0-17l-111-111c-4.8-4.7-12.4-4.7-17.1 0zM124.1 339.9c-5.5-5.5-5.5-14.3 0-19.8l154-154c5.5-5.5 14.3-5.5 19.8 0s5.5 14.3 0 19.8l-154 154c-5.5 5.5-14.3 5.5-19.8 0zM88 424h48v36.3l-64.5 11.3-31.1-31.1L51.7 376H88v48z" />
                                  </svg>
                                </div>
                              </div>
                              <div className="ag-timeline-card_meta-box">
                                <div className="ag-timeline-card_meta">
                                  Step 1
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="js-timeline_item ag-timeline_item">
                            <div className="ag-timeline-card_box">
                              <div className="js-timeline-card_point-box ag-timeline-card_point-box">
                                <div className="ag-timeline-card_meta-box">
                                  <div className="ag-timeline-card_meta">
                                    Step 2
                                  </div>
                                </div>
                                <div className="ag-timeline-card_point">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                  >
                                    <path d="M0 405.3V448c0 35.3 86 64 192 64s192-28.7 192-64v-42.7C342.7 434.4 267.2 448 192 448S41.3 434.4 0 405.3zM320 128c106 0 192-28.7 192-64S426 0 320 0 128 28.7 128 64s86 64 192 64zM0 300.4V352c0 35.3 86 64 192 64s192-28.7 192-64v-51.6c-41.3 34-116.9 51.6-192 51.6S41.3 334.4 0 300.4zm416 11c57.3-11.1 96-31.7 96-55.4v-42.7c-23.2 16.4-57.3 27.6-96 34.5v63.6zM192 160C86 160 0 195.8 0 240s86 80 192 80 192-35.8 192-80-86-80-192-80zm219.3 56.3c60-10.8 100.7-32 100.7-56.3v-42.7c-35.5 25.1-96.5 38.6-160.7 41.8 29.5 14.3 51.2 33.5 60 57.2z" />
                                  </svg>
                                </div>
                              </div>
                            </div>
                            <div className="ag-timeline-card_item">
                              <div className="ag-timeline-card_inner">
                                <div className="ag-timeline-card_info">
                                  <div className="ag-timeline-card_title">
                                    {adivcer[1]?.title}
                                  </div>
                                  <div className="ag-timeline-card_desc">
                                    {adivcer[1]?.description}
                                    {/* <ul>
                                    <li>- One-off payment</li>
                                    <li>- Mortgage loan</li>
                                    <li>- Mortgage subrogation</li>
                                  </ul> */}
                                  </div>
                                </div>
                              </div>
                              <div className="ag-timeline-card_arrow"></div>
                            </div>
                          </div>

                          <div className="js-timeline_item ag-timeline_item">
                            <div className="ag-timeline-card_item">
                              <div className="ag-timeline-card_inner">
                                <div className="ag-timeline-card_info">
                                  <div className="ag-timeline-card_title">
                                    {adivcer[2]?.title}
                                  </div>
                                  <div className="ag-timeline-card_desc">
                                    {adivcer[2]?.description}
                                  </div>
                                </div>
                              </div>
                              <div className="ag-timeline-card_arrow"></div>
                            </div>
                            <div className="ag-timeline-card_box">
                              <div className="js-timeline-card_point-box ag-timeline-card_point-box">
                                <div className="ag-timeline-card_point">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512"
                                  >
                                    <path d="M570.69,236.27,512,184.44V48a16,16,0,0,0-16-16H432a16,16,0,0,0-16,16V99.67L314.78,10.3C308.5,4.61,296.53,0,288,0s-20.46,4.61-26.74,10.3l-256,226A18.27,18.27,0,0,0,0,248.2a18.64,18.64,0,0,0,4.09,10.71L25.5,282.7a21.14,21.14,0,0,0,12,5.3,21.67,21.67,0,0,0,10.69-4.11l15.9-14V480a32,32,0,0,0,32,32H480a32,32,0,0,0,32-32V269.88l15.91,14A21.94,21.94,0,0,0,538.63,288a20.89,20.89,0,0,0,11.87-5.31l21.41-23.81A21.64,21.64,0,0,0,576,248.19,21,21,0,0,0,570.69,236.27ZM288,176a64,64,0,1,1-64,64A64,64,0,0,1,288,176ZM400,448H176a16,16,0,0,1-16-16,96,96,0,0,1,96-96h64a96,96,0,0,1,96,96A16,16,0,0,1,400,448Z" />
                                  </svg>
                                </div>
                              </div>
                              <div className="ag-timeline-card_meta-box">
                                <div className="ag-timeline-card_meta">
                                  Step 3
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="js-timeline_item ag-timeline_item">
                            <div className="ag-timeline-card_box">
                              <div className="js-timeline-card_point-box ag-timeline-card_point-box">
                                <div className="ag-timeline-card_meta-box">
                                  <div className="ag-timeline-card_meta">
                                    {document.documentElement.lang == "es" ? <c class="notranslate">Paso </c> : "Step "} 4
                                  </div>
                                </div>
                                <div className="ag-timeline-card_point">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512"
                                  >
                                    <path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm95.8 32.6L272 480l-32-136 32-56h-96l32 56-32 136-47.8-191.4C56.9 292 0 350.3 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-72.1-56.9-130.4-128.2-133.8z" />
                                  </svg>
                                </div>
                              </div>
                            </div>
                            <div className="ag-timeline-card_item">
                              <div className="ag-timeline-card_inner">
                                <div className="ag-timeline-card_info">
                                  <div className="ag-timeline-card_title">
                                    {adivcer[3]?.title}
                                  </div>
                                  <div className="ag-timeline-card_desc">
                                    {adivcer[3]?.description}
                                  </div>
                                </div>
                              </div>
                              <div className="ag-timeline-card_arrow"></div>
                            </div>
                          </div>

                          <div className="js-timeline_item ag-timeline_item">
                            <div className="ag-timeline-card_item">
                              <div className="ag-timeline-card_inner">
                                <div className="ag-timeline-card_info">
                                  <div className="ag-timeline-card_title">
                                    {adivcer[4]?.title}
                                  </div>
                                  <div className="ag-timeline-card_desc">
                                    {adivcer[4]?.description}
                                  </div>
                                </div>
                              </div>
                              <div className="ag-timeline-card_arrow"></div>
                            </div>
                            <div className="ag-timeline-card_box">
                              <div className="js-timeline-card_point-box ag-timeline-card_point-box">
                                <div className="ag-timeline-card_point">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                  >
                                    <path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z" />
                                  </svg>
                                </div>
                              </div>
                              <div className="ag-timeline-card_meta-box">
                                <div className="ag-timeline-card_meta">
                                  Step 5
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="js-timeline_item ag-timeline_item">
                            <div className="ag-timeline-card_box">
                              <div className="js-timeline-card_point-box ag-timeline-card_point-box">
                                <div className="ag-timeline-card_meta-box">
                                  <div className="ag-timeline-card_meta">
                                    Step 6
                                  </div>
                                </div>
                                <div className="ag-timeline-card_point">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 640 512"
                                  >
                                    <path d="M608 64H32C14.33 64 0 78.33 0 96v320c0 17.67 14.33 32 32 32h576c17.67 0 32-14.33 32-32V96c0-17.67-14.33-32-32-32zM48 400v-64c35.35 0 64 28.65 64 64H48zm0-224v-64h64c0 35.35-28.65 64-64 64zm272 176c-44.19 0-80-42.99-80-96 0-53.02 35.82-96 80-96s80 42.98 80 96c0 53.03-35.83 96-80 96zm272 48h-64c0-35.35 28.65-64 64-64v64zm0-224c-35.35 0-64-28.65-64-64h64v64z" />
                                  </svg>
                                </div>
                              </div>
                            </div>
                            <div className="ag-timeline-card_item">
                              <div className="ag-timeline-card_inner">
                                <div className="ag-timeline-card_info">
                                  <div className="ag-timeline-card_title">
                                    {adivcer[5]?.title}
                                  </div>
                                  <div className="ag-timeline-card_desc">
                                    {adivcer[5]?.description}
                                  </div>
                                </div>
                              </div>
                              <div className="ag-timeline-card_arrow"></div>
                            </div>
                          </div>

                          <div className="js-timeline_item ag-timeline_item">
                            <div className="ag-timeline-card_item">
                              <div className="ag-timeline-card_inner">
                                <div className="ag-timeline-card_info">
                                  <div className="ag-timeline-card_title">
                                    {adivcer[6]?.title}
                                  </div>
                                  <div className="ag-timeline-card_desc">
                                    {adivcer[6]?.description}
                                  </div>
                                </div>
                              </div>
                              <div className="ag-timeline-card_arrow"></div>
                            </div>
                            <div className="ag-timeline-card_box">
                              <div className="js-timeline-card_point-box ag-timeline-card_point-box">
                                <div className="ag-timeline-card_point">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512"
                                  >
                                    <path d="M218.17 424.14c-2.95-5.92-8.09-6.52-10.17-6.52s-7.22.59-10.02 6.19l-7.67 15.34c-6.37 12.78-25.03 11.37-29.48-2.09L144 386.59l-10.61 31.88c-5.89 17.66-22.38 29.53-41 29.53H80c-8.84 0-16-7.16-16-16s7.16-16 16-16h12.39c4.83 0 9.11-3.08 10.64-7.66l18.19-54.64c3.3-9.81 12.44-16.41 22.78-16.41s19.48 6.59 22.77 16.41l13.88 41.64c19.75-16.19 54.06-9.7 66 14.16 1.89 3.78 5.49 5.95 9.36 6.26v-82.12l128-127.09V160H248c-13.2 0-24-10.8-24-24V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24v-40l-128-.11c-16.12-.31-30.58-9.28-37.83-23.75zM384 121.9c0-6.3-2.5-12.4-7-16.9L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1zm-96 225.06V416h68.99l161.68-162.78-67.88-67.88L288 346.96zm280.54-179.63l-31.87-31.87c-9.94-9.94-26.07-9.94-36.01 0l-27.25 27.25 67.88 67.88 27.25-27.25c9.95-9.94 9.95-26.07 0-36.01z" />
                                  </svg>
                                </div>
                              </div>
                              <div className="ag-timeline-card_meta-box">
                                <div className="ag-timeline-card_meta">
                                  Step 7
                                </div>
                              </div>
                            </div>
                          </div>
                          {adivcer[7] ? (
                            <div className="js-timeline_item ag-timeline_item">
                              <div className="ag-timeline-card_box">
                                <div className="js-timeline-card_point-box ag-timeline-card_point-box">
                                  <div className="ag-timeline-card_meta-box">
                                    <div className="ag-timeline-card_meta">
                                      Step 8
                                    </div>
                                  </div>
                                  <div className="ag-timeline-card_point">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 576 512"
                                    >
                                      <path d="M218.17 424.14c-2.95-5.92-8.09-6.52-10.17-6.52s-7.22.59-10.02 6.19l-7.67 15.34c-6.37 12.78-25.03 11.37-29.48-2.09L144 386.59l-10.61 31.88c-5.89 17.66-22.38 29.53-41 29.53H80c-8.84 0-16-7.16-16-16s7.16-16 16-16h12.39c4.83 0 9.11-3.08 10.64-7.66l18.19-54.64c3.3-9.81 12.44-16.41 22.78-16.41s19.48 6.59 22.77 16.41l13.88 41.64c19.75-16.19 54.06-9.7 66 14.16 1.89 3.78 5.49 5.95 9.36 6.26v-82.12l128-127.09V160H248c-13.2 0-24-10.8-24-24V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24v-40l-128-.11c-16.12-.31-30.58-9.28-37.83-23.75zM384 121.9c0-6.3-2.5-12.4-7-16.9L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1zm-96 225.06V416h68.99l161.68-162.78-67.88-67.88L288 346.96zm280.54-179.63l-31.87-31.87c-9.94-9.94-26.07-9.94-36.01 0l-27.25 27.25 67.88 67.88 27.25-27.25c9.95-9.94 9.95-26.07 0-36.01z" />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                              <div className="ag-timeline-card_item">
                                <div className="ag-timeline-card_inner">
                                  <div className="ag-timeline-card_info">
                                    <div className="ag-timeline-card_title">
                                      {adivcer[7]?.title}
                                    </div>
                                    <div className="ag-timeline-card_desc">
                                      {adivcer[7]?.description}
                                    </div>
                                  </div>
                                </div>
                                <div className="ag-timeline-card_arrow"></div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}

                          {adivcer[8] ? (
                            <div className="js-timeline_item ag-timeline_item">
                              <div className="ag-timeline-card_item">
                                <div className="ag-timeline-card_inner">
                                  <div className="ag-timeline-card_info">
                                    <div className="ag-timeline-card_title">
                                      {adivcer[8]?.title}
                                    </div>
                                    <div className="ag-timeline-card_desc">
                                      {adivcer[8]?.description}
                                    </div>
                                  </div>
                                </div>
                                <div className="ag-timeline-card_arrow"></div>
                              </div>
                              <div className="ag-timeline-card_box">
                                <div className="js-timeline-card_point-box ag-timeline-card_point-box">
                                  <div className="ag-timeline-card_point">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 576 512"
                                    >
                                      <path d="M527.9 32H48.1C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48.1 48h479.8c26.6 0 48.1-21.5 48.1-48V80c0-26.5-21.5-48-48.1-48zM54.1 80h467.8c3.3 0 6 2.7 6 6v42H48.1V86c0-3.3 2.7-6 6-6zm467.8 352H54.1c-3.3 0-6-2.7-6-6V256h479.8v170c0 3.3-2.7 6-6 6zM192 332v40c0 6.6-5.4 12-12 12h-72c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h72c6.6 0 12 5.4 12 12zm192 0v40c0 6.6-5.4 12-12 12H236c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h136c6.6 0 12 5.4 12 12z" />
                                    </svg>
                                  </div>
                                </div>
                                <div className="ag-timeline-card_meta-box">
                                  <div className="ag-timeline-card_meta">
                                    Step 9
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}

                          {adivcer[9] ? (
                            <div className="js-timeline_item ag-timeline_item">
                              <div className="ag-timeline-card_box">
                                <div className="js-timeline-card_point-box ag-timeline-card_point-box">
                                  <div className="ag-timeline-card_meta-box">
                                    <div className="ag-timeline-card_meta">
                                      Step 10
                                    </div>
                                  </div>
                                  <div className="ag-timeline-card_point">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 640 512"
                                    >
                                      <path d="M630.6 364.9l-90.3-90.2c-12-12-28.3-18.7-45.3-18.7h-79.3c-17.7 0-32 14.3-32 32v79.2c0 17 6.7 33.2 18.7 45.2l90.3 90.2c12.5 12.5 32.8 12.5 45.3 0l92.5-92.5c12.6-12.5 12.6-32.7.1-45.2zm-182.8-21c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24c0 13.2-10.7 24-24 24zm-223.8-88c70.7 0 128-57.3 128-128C352 57.3 294.7 0 224 0S96 57.3 96 128c0 70.6 57.3 127.9 128 127.9zm127.8 111.2V294c-12.2-3.6-24.9-6.2-38.2-6.2h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 287.9 0 348.1 0 422.3v41.6c0 26.5 21.5 48 48 48h352c15.5 0 29.1-7.5 37.9-18.9l-58-58c-18.1-18.1-28.1-42.2-28.1-67.9z" />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                              <div className="ag-timeline-card_item">
                                <div className="ag-timeline-card_inner">
                                  <div className="ag-timeline-card_info">
                                    <div className="ag-timeline-card_title">
                                      {adivcer[9]?.title}
                                    </div>
                                    <div className="ag-timeline-card_desc">
                                      {adivcer[9]?.description}
                                    </div>
                                  </div>
                                </div>
                                <div className="ag-timeline-card_arrow"></div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {adivcer[10] ? (
                            <div className="js-timeline_item ag-timeline_item">
                              <div className="ag-timeline-card_item">
                                <div className="ag-timeline-card_inner">
                                  <div className="ag-timeline-card_info">
                                    <div className="ag-timeline-card_title">
                                      {adivcer[10]?.title}
                                    </div>
                                    <div className="ag-timeline-card_desc">
                                      {adivcer[10]?.description}
                                    </div>
                                  </div>
                                </div>
                                <div className="ag-timeline-card_arrow"></div>
                              </div>
                              <div className="ag-timeline-card_box">
                                <div className="js-timeline-card_point-box ag-timeline-card_point-box">
                                  <div className="ag-timeline-card_point">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 512 512"
                                    >
                                      <path d="M512 176.001C512 273.203 433.202 352 336 352c-11.22 0-22.19-1.062-32.827-3.069l-24.012 27.014A23.999 23.999 0 0 1 261.223 384H224v40c0 13.255-10.745 24-24 24h-40v40c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24v-78.059c0-6.365 2.529-12.47 7.029-16.971l161.802-161.802C163.108 213.814 160 195.271 160 176 160 78.798 238.797.001 335.999 0 433.488-.001 512 78.511 512 176.001zM336 128c0 26.51 21.49 48 48 48s48-21.49 48-48-21.49-48-48-48-48 21.49-48 48z" />
                                    </svg>
                                  </div>
                                </div>
                                <div className="ag-timeline-card_meta-box">
                                  <div className="ag-timeline-card_meta">
                                    Step 11
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
                {
                  id == 3 ? "" :
                    <div class="boxContent">
                      <h4>What else will you need to do?</h4>
                      <p>You can apply for citizenship at the local town hall. Sort your tax status Check with the tax authorities in your home country to see what – if anything – you need to inform them of.</p>
                      <p>Consider making a will Spanish laws are different from those of other countries so make sure that, if the worst happens, your family and assets are protected.</p>
                    </div>
                }
                <div className="notes">
                  <p>
                    Please note the information provided in this article is of
                    general interest only and is not to be construed or intended
                    as a <br /> substitute for professional legal advice. It is
                    not a legal document and may not cover every eventuality. If
                    you are planning <br />
                    on buying a property in Mallorca it is vital that you
                    consult with experts to make sure your purchase goes
                    smoothly.
                  </p>
                </div>
              </div>
            </section>
            {/* <!-- buying advice section end here --> */}
          </>
        )}
      </Layout>
    </>
  );
}

export default SpanishBuy;
