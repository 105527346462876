import React, { useEffect } from 'react'
import { useLocation, withRouter } from 'react-router-dom';



function ScrolTop() {
    const { pathname } = useLocation()
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({ top: 0 })
        }, 2000);
    }, [pathname])
}

export default ScrolTop