import React from "react";
import Slider from "react-slick";
import Layout from "../../Layout/Layout";
import bed from "../../../assets/images/single-bed.png";
import bathroom_size from "../../../assets/images/bathrooms_size.png";
import bath from "../../../assets/images/bath.png";
import { useEffect } from "react";
import { useState } from "react";
import { baseUrl } from "../../../baseUrl";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import PremiumSection from "../SearchSections/PremiumSection";
import { toast } from "react-toastify";
import swal from "sweetalert";

const img = [
  require("../../../assets/images/bookmark-img1.jpg"),
  require("../../../assets/images/bookmark-img2.jpg"),
  require("../../../assets/images/bookmark-img3.jpg"),
];
function Rent(props) {
  const [loader, setLoader] = useState(false);
  const detials = props.details;
  const total = props.total;
  const town = props.town;
  const navigate = useNavigate();
  const premiumDetails = props.premiumDetails;
  const setshorting = props.setshorting;
  const shorting = props.shorting;
  const error = props.error;
  const [p_Id, setP_Id] = useState("");
  const [addSucces, setaddSucces] = useState("");
  const [AddFav, setAddFav] = useState(false);
  // console.log("property_id", );
  const property = props.totalResult;
  const token = localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : "";


  const islogin = JSON.parse(localStorage.getItem("login"));

  const postFavourite = async (property_id) => {
    // e.preventDefault();

    const body = { property_id: property_id };
    try {


      const res = await axios.post(
        baseUrl + "/Add/favourite-property",
        body,

        { headers: { Authorization: `Bearer ${token}` } }
      );
      const data = await res.data;
      if (res.status == 200) {

        console.log(res, "<<<<<<<<");
        localStorage.setItem(
          "fav_property_id",
          JSON.stringify(res.data.data?.property_id ? res.data.data?.property_id : "")
        );
        setAddFav(true);

        swal({
          title: "Bella Islas says",
          text: res.data.message,
          icon: "success",
          button: "OK",
          className: "notranslate"
          // timer: 3000,
        });
      }

    } catch (error) {
      swal({
        title: "Bella Islas says",
        text: error.message,
        icon: "error",
        button: "OK",
        className: "notranslate"
        // timer: 3000,
      });
    }
    // console.log(data,islogin, property_id);
  };


  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,

    slidesToScroll: 1,
  };

  // console.log(detials ,"<<<<<<<<");

  const shortbyprice = () => {
  };

  const handleFilter = (e) => {
    setshorting(e.target.value);
    //else{
    //   detials.sort((a,b)=> a.data.price -b.data.price)
    // }
  };

  let nf = new Intl.NumberFormat();
  // nf.format

  const handlenewtab = (event) => {
    // console.log(event);
    // if (event.ctrlKey) {
    //   window.open(`/rent`, "_blank");
    // }
  };

  return (
    <>
      <section className="fillter_res">
        <div className="container_">
          <div className="top_bar flex">
            <h5 >
              Properties for Rent in {town !== "" ? town + " :" : "Mallorca :"}
              {"     "}
              {total !== "" ? total : "0"} property results
            </h5>
            <div className="result-fillter">
              <label for="sort">Sort:</label>
              <select
                id="sortType"
                name="sort"
                onChange={(e) => handleFilter(e)}
                className="select sort-select"
                // defaultValue={shorting== "low" ? "Lowest Price":""}
                value={shorting == "low" ? "low" : shorting == "high" ? "high" : shorting == "new" ? "new" : shorting == "old" ? "old" : "new"}
              >
                {/* <option value="">Select option</option> */}
                <option value="low">Lowest Price</option>
                <option value="high" onClick={shortbyprice}>
                  Highest Price
                </option>
                <option value="new">Newest Listed</option>
                <option value="old">Oldest Listed</option>
              </select>
            </div>
          </div>
          <PremiumSection premiumDetails={premiumDetails} />

          {/* <h3 className="top_heading"> Premium Featured-New to the market </h3> */}
          <h1>Available Listing</h1>
          <div style={{ textAlign: "center" }}>{error}</div>
          {detials?.map((item, index) => {
            var type2 = JSON.stringify(item.data?.type).length < 13 ? JSON.stringify(item.data?.type).replace(
              /[\\[,"\]']+/g,
              "  ") : JSON.stringify(item.data?.type).replace(
                /[\\[,"\]']+/g,
                " | ")
            // console.log(type2 ,"JSON.stringify(item.data?.type).length" ,JSON.stringify(item.data?.type).length );
            let town3 = item.data?.town.substr(item.data?.town.lastIndexOf('\\') + 1).split('[')[1] ? JSON.parse(item.data?.town).map((e, index) => e + (index < (JSON.parse(item.data?.town).length - 1) ? " | " : " ")) : ""

            console.log(premiumDetails);


            return (
              <div
                className="listings "
                key={item.data.id}
                onClick={(e) => handlenewtab(e)}
              >
                <div className="listDataRight uper_side">
                  <div

                    onClick={() => {
                      if (islogin) {
                        postFavourite(item.property_id);
                      } else {
                        swal({
                          title: "Bella Islas says",
                          text: "Please Log in First !",
                          icon: "error",
                          button: "OK",
                          className: "notranslate"
                          // timer: 3000,
                        });
                      }
                    }}
                  >
                    {" "}
                    {addSucces == item.property_id && AddFav == true || (premiumDetails?.favorite_property != "" ? premiumDetails?.favorite_property?.filter((e) => e.property_id == item.property_id)[0] : addSucces == item.property_id && AddFav == true) ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                      >
                        <path fill="none" d="M0 0H24V24H0z" />
                        <path
                          d="M12.001 4.529c2.349-2.109 5.979-2.039 8.242.228 2.262 2.268 2.34 5.88.236 8.236l-8.48 8.492-8.478-8.492c-2.104-2.356-2.025-5.974.236-8.236 2.265-2.264 5.888-2.34 8.244-.228z"
                          fill="rgba(255,140,0,1)"
                        />
                      </svg>
                    ) : (
                      <span
                        className="heart_hover"
                        onClick={() => setaddSucces(item.property_id)}
                      >
                        <i class="far fa-heart"></i>
                      </span>
                    )}
                  </div>
                </div>
                <NavLink
                  className="flex"
                  to={`/property-details/${item.property_id}`}
                >
                  <div className="col_">
                    <div className="bookmark-slider_mob ">
                      <div className="slickArrow bookmark-slider_mob bookmark-slider list-img-slider">
                        <Slider {...settings}>
                          {item.data?.images.image !== undefined
                            ? item.data?.images.image.map((img, i) => {
                              return (
                                <div key={i}>
                                  <figure style={{ cursor: "pointer" }}>
                                    <img src={img.url} alt="loading img..." />
                                  </figure>
                                </div>
                              );
                            })
                            : ""}
                        </Slider>
                      </div>
                    </div>
                  </div>
                  <div className="col_" style={{ cursor: "pointer" }}>
                    <div className="listDataContainer">
                      <a>
                        <div className="listDataOuter">
                          <div
                            className="listingtitle"
                            style={{ cursor: "pointer" }}
                          >
                            {item.data?.beds > 0 ? item.data?.beds : ""} {document.documentElement.lang == "es" ? <c class="notranslate"> Habitaciones</c> : "bedroom"}
                            {type2 && item.data?.type != "NA" ? type2 : ""} {document.getElementsByTagName("html")[0].getAttribute("lang") == "es" ? <c class="notranslate">En alquiler</c> : "for Rent"}
                          </div>
                          <div className="notranslate pricesection">
                            <span className=" price">
                              <b>
                                €{" "}
                                {item?.data.price
                                  ? nf.format(item?.data.price)
                                  : ""}
                              </b>{" "}
                              {/* {item.data?.price_freq} */}
                            </span>
                            <span className="bedrooms">
                              {item.data?.beds} <img src={bed} alt="logo" />{" "}
                            </span>
                            <span className="bathrooms">
                              {item.data?.baths} <img src={bath} />{" "}
                            </span>
                            <span className="bathrooms_size">
                              {item.data?.surface_area?.built == null ? (
                                ""
                              ) : (
                                <>
                                  <img src={bathroom_size} alt="" />
                                  <span>
                                    {item.data?.surface_area.built
                                      ? item.data.surface_area.built
                                      : ""}
                                    m<sup>2</sup>{" "}
                                  </span>
                                </>
                              )}
                            </span>
                          </div>

                          <div className="listingaddress notranslate" >
                            <i
                              aria-hidden="true"
                              className="fas fa-map-marker-alt"
                            ></i>

                            {town3 ? town3
                              : item.data?.town}
                            {/* {item.data?.town?.replace(/[\[,"\]']+/g, " | ")
                              ? item.data?.town?.replace(/[\[,"\]']+/g, " | ")
                              : ""} */}
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="agentname">
                      <div>
                        <p className="eng_desFont">
                          {item.english_description?.substring(0, window.innerWidth < "750" ? 100 : 200)} {item.english_description ? "..." : ""}
                        </p>
                      </div>
                      <div className="wrap flex">
                        {
                          item?.company_name == null ? "" : <>

                            <p className="notranslate">
                              <NavLink to={`/author/${item?.user_id}`}>
                                {item?.company_name}
                              </NavLink>
                            </p>
                            <figure>
                              <a
                                href={`/author/${item?.user_id}`}
                                className="notranslate firstalpha"
                              >
                                {
                                  item?.get_image[0]?.file_path && item?.get_image[0]?.file_path == "assets/images/users/default.png" ? item?.company_name?.charAt(0) :
                                    <img src={item?.get_image[0]?.file_path} />
                                }
                              </a>
                            </figure>
                          </>
                        }
                      </div>
                    </div>
                  </div>
                </NavLink>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
}

export default Rent;
