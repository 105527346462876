import React, { useEffect, useState } from "react";
import { useRef } from "react";
import Slider from "react-slick";
import bed from "../../../assets/images/single-bed.png";
import bathroom_size from "../../../assets/images/bathrooms_size.png";
import bath from "../../../assets/images/bath.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../../baseUrl";
import { toast } from 'react-toastify'
import swal from 'sweetalert';

const img = [
  require("../../../assets/images/bookmark-img1.jpg"),
  require("../../../assets/images/bookmark-img2.jpg"),
  require("../../../assets/images/bookmark-img3.jpg"),
];

function PrimeumSection(props) {
  const premiumDetails = props.premiumDetails;
  const navigate = useNavigate();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const PremiumRef = useRef(null);
  const islogin = JSON.parse(localStorage.getItem("login"));
  const [error, setError] = useState("");
  const [count, setcount] = useState(true);
  const [addSucces, setaddSucces] = useState(false);
  const [p_Id, setP_Id] = useState("");
  const [AddFav, setAddFav] = useState(false);

  const token = localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : "";
  const postFavourite = async (property_id) => {
    // e.preventDefault();
    //const property_id = p_Id;
    try {
      const res = await axios.post(
        baseUrl + "/Add/favourite-property",
        { property_id: property_id },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const data = await res.data;
      if (res.status == 200) {
        setAddFav(true)

        swal({
          title: "Bella Islas says",
          text: res.data.message,
          icon: "success",
          button: "OK",
          // timer: 3000,
        });
        setaddSucces(property_id);
      }
    } catch (error) {
      // if(error){
      //   setaddSucces(false)
      // }

      swal({
        title: "Bella Islas says",
        text: error.message,
        icon: "error",
        button: "OK",
        // timer: 3000,
      });
    }
  };

  useEffect(() => {
    if (count) {
      // postFavourite();
      setcount(false);
    }
  }, []);

  const passId = (id) => {
    navigate(`/property-details/${id}`);
  };




  var settings2 = {

    pauseOnHover: false,
    infinite: true,
    speed: 300,
    autoplay: true,
    slidesToScroll: 1,
    // variableWidth: false,
    // initialSlide: 0,
    responsive: [

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };


  let nf = new Intl.NumberFormat();
  ; // "1,234,567,890"
  return (
    <>
      {/* <!-- slider section start here --> */}
      {
        premiumDetails?.premium?.length === 0 ? "" :
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3 className="top_headin pre_heading">
              {" "}
              Premium Featured-New to the market{" "}
            </h3>

            <div
              style={{
                display: "flex",
                border: "1px solid re",
                marginRight: "rem",
              }}
            >
              <div
                className="prev_custom"
                onClick={() => PremiumRef.current.slickPrev()}
              ></div>
              <div
                className="next_custom"
                onClick={() => PremiumRef.current.slickNext()}
              >
                {/* <img src={require("../../../assets/images/arrow-right.svg")}/> */}
              </div>
            </div>
          </div>
      }

      {
        premiumDetails?.premium ?

          <div className="result-slider-wra">
            <div className="listing_mai  result-slide" style={{ margin: "px" }}>
              <Slider
                {...settings2}
                slidesToShow={premiumDetails?.premium?.length > 3 ? 3 : premiumDetails?.premium?.length}
                ref={PremiumRef}
              >
                {Array.from(premiumDetails?.premium).map((item, i) => {
                  var type2 = JSON.stringify(item.data?.type).replace(
                    /[\\[,"\]']+/g,
                    "  "
                  );

                  return (
                    <div key={i} className="listing_div_res"  >
                      <div
                        style={{
                          height: "100%",
                          justifyContent: "center",
                          display: "flex",
                          objectFit: "contain",
                          margin: "2px",
                        }}
                      >
                        <div className="listing_div_res  " >
                          <div
                            className="listings flex"
                            style={{ flexDirection: "column" }}
                          >
                            <div
                              className="col_"
                              style={{ width: "100%", padding: "0" }}
                            >
                              <div className="list-img-slider" style={{ cursor: "pointer" }}>
                                <div className="slickArrow bookmark-slider_mob bookmark-slider list-img-slider">
                                  <Slider {...settings}>
                                    {item.data?.images.image.map((img, i) => {
                                      return (
                                        <div key={i}>
                                          <figure
                                            onClick={() => passId(item.property_id)}
                                          >
                                            <img
                                              src={img.url}
                                              alt="loading img..."
                                            />
                                          </figure>
                                        </div>
                                      );
                                    })}
                                  </Slider>
                                </div>
                              </div>
                              {/* <a href="#" onClick={() => passId(item.property_id)} className="slider_link"></a> */}
                            </div>
                            <div className="col_" style={{ width: "100%" }}>
                              <div className="listDataContainer">
                                <a>
                                  <div className="listDataOuter">
                                    <div className="listingtitle2 listingtitle">
                                      {item.data?.beds ? item.data?.beds : "0"}{" "}
                                      bedroom {type2 ? type2 : "Apartment"} for{" "}
                                      {item.data?.price_freq
                                        ? item.data?.price_freq
                                        : "Sale"}
                                      <div className="listDataRight">
                                        {/* <a href="#">
                                      {" "}
                                      <i className="far fa-heart"></i>{" "}
                                    </a> */}
                                        {addSucces == item.property_id && AddFav == true ? (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            width="24"
                                            height="24"
                                          >
                                            <path fill="none" d="M0 0H24V24H0z" />
                                            <path
                                              d="M12.001 4.529c2.349-2.109 5.979-2.039 8.242.228 2.262 2.268 2.34 5.88.236 8.236l-8.48 8.492-8.478-8.492c-2.104-2.356-2.025-5.974.236-8.236 2.265-2.264 5.888-2.34 8.244-.228z"
                                              fill="rgba(255,140,0,1)"
                                            />
                                          </svg>
                                        ) : (
                                          // <svg
                                          //   xmlns="http://www.w3.org/2000/svg"
                                          //   onClick={() => setaddSucces(item.property_id)}

                                          //   viewBox="0 0 24 24"
                                          //   width="24"
                                          //   height="24"
                                          // >
                                          //   <path fill="none" d="M0 0H24V24H0z" />
                                          //   <path d="M12.001 4.529c2.349-2.109 5.979-2.039 8.242.228 2.262 2.268 2.34 5.88.236 8.236l-8.48 8.492-8.478-8.492c-2.104-2.356-2.025-5.974.236-8.236 2.265-2.264 5.888-2.34 8.244-.228zm6.826 1.641c-1.5-1.502-3.92-1.563-5.49-.153l-1.335 1.198-1.336-1.197c-1.575-1.412-3.99-1.35-5.494.154-1.49 1.49-1.565 3.875-.192 5.451L12 18.654l7.02-7.03c1.374-1.577 1.299-3.959-.193-5.454z" />
                                          // </svg>
                                          <span
                                            className="heart_hover"
                                            onClick={() => {
                                              if (islogin) {
                                                postFavourite(item.property_id);
                                              } else {

                                                swal({
                                                  title: "Bella Islas says",
                                                  text: "Please Log in First !",
                                                  icon: "error",
                                                  button: "OK",
                                                  // timer: 3000,
                                                });

                                              }
                                            }}
                                          //  onClick={() => setaddSucces(item.property_id)}
                                          >
                                            <i class="far fa-heart"></i>
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    <div
                                      className="pricesection"
                                      style={{ display: "flex" }}
                                      onClick={() => passId(item.property_id)}
                                    >
                                      <span
                                        className="price"
                                        style={{ fontSize: "17px" }}
                                      >
                                        <b>
                                          € {item.data?.price
                                            ? nf.format(item.data?.price)
                                            : "0"}
                                        </b>{" "}

                                      </span>
                                      <span
                                        className="bedrooms"
                                        style={{ display: "flex" }}
                                      >
                                        {item.data?.beds ? item.data?.beds : "0"}{" "}
                                        <img src={bed} />{" "}
                                      </span>
                                      <span
                                        className="bathrooms"
                                        style={{ display: "flex" }}
                                      >
                                        {item.data?.baths ? item.data?.baths : "0"}{" "}
                                        <img src={bath} />{" "}
                                      </span>

                                      {/* <span
                                        className="bathrooms_size"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                      
                                    {item.data?.surface_area?.built == null ? (
                                ""
                              ) : (
                                <>
                                  <img src={bathroom_size} alt="" />
                                  <span>
                                    {item.data?.surface_area.built
                                      ? item.data.surface_area.built
                                      : ""}
                                    m<sup>2</sup>{" "}
                                  </span>
                                </>
                              )}
                                      </span> */}
                                    </div>

                                    <div
                                      className="listingaddress"
                                      style={{ paddingBottom: "55px" }}
                                    >
                                      <i
                                        aria-hidden="true"
                                        className="fas fa-map-marker-alt"
                                      ></i>
                                      {item.data?.town.substr(item.data?.town.lastIndexOf('\\') + 1).split('[')[1] ? JSON.parse(item.data?.town).map((a, index2) => a + " ") : item.data?.town}
                                    </div>
                                  </div>
                                </a>
                                {/* <div className="listDataRight">
                      <a href="#">
                        {" "}
                        <i className="far fa-heart"></i>{" "}
                      </a>
                    </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div> : ""
      }
      {/* <!-- slider section end here --> */}
    </>
  );
}

export default PrimeumSection;
