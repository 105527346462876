import axios from "axios";
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { baseUrl } from "../../baseUrl";
import Layout from "../Layout/Layout";
import loader from "../../assets/images/loader.svg";

function Agentlogin() {
  const [checkError, setcheckError] = useState("Please Click on Check Box");
  const [loginError, setloginError] = useState();
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();

  // sessionStorage.clear()

  // console.log(loginError);
  const checkedHandle = (e) => {
    if (e.target.checked === true) {
      setcheckError("");
    } else if (e.target.checked === false) {
      setcheckError("Please Click on Check Box");
    }
  };

  const submitHandler = (event) => {
    event.preventDefault();
    const email = event.target.email.value;
    const password = event.target.password.value;
    setloading(true);

    const data = { email, password };
    if (email != "" && password != "") {
      axios
        .post(baseUrl + "/login", data)
        .then((response) => {
          console.log(" resp ", response);
          event.target.reset();
          if (response.status === 200) {
            //alert(response.data.message)
            const user = response.data.data.user.user_type;
            setloading(false);
            console.log(user);
            if (user == "Agent") {
              localStorage.setItem("data", JSON.stringify(response.data));
              localStorage.setItem(
                "token",
                JSON.stringify(response.data.data.token)
              );
              localStorage.setItem(
                "user",
                JSON.stringify(response.data.data.user)
              );

              localStorage.setItem("login", "true");
              navigate("/agent-dashboard");
            } else if (user == "User") {
              navigate("/agent-login");
              setloginError("invalid Usertype");
            } else {
              setloginError("");
            }
          } else if (response.status === 404) {
            //alert(response.data.message)
            console.log(response.data.message);
            navigate("/agent-login");
          } else {
            navigate("/agent-login");
          }
        })
        .catch((error) => {
          console.log(error);
          if (error) {
            // alert(error.message)
            setloginError(
              "Email id and Password do not match!"
            );
          } else {
            setloginError("");
          }
        });
    }else{
      setloginError(
        "Fill the required field!"
      );
    }
  };

  return (
    <>
      <Layout>
        <section className="login-section">
          <div className="container-sm agent_login">
            <div className="wrapper">
              <p className="agent_login_heading">
                <h1>Agent Login </h1>
              </p>

              <form action="#" className="user-login" onSubmit={submitHandler}>
                <div className="form_group">
                  <input
                    type="email"
                    name="userEmail"
                    id="email"
                    placeholder="Your Email"
                  />
                </div>
                <div className="form_group">
                  <input
                    type="password"
                    name="userPassword"
                    id="password"
                    placeholder="Password"
                  />
                </div>
                <div className="form_group flex">
                  <div className="col">
                    <input
                      type="checkbox"
                      onChange={checkedHandle}
                      name="remember"
                      value="remember me"
                    />

                    <label for="remember">Remember Me</label>
                  </div>

                  <div className="col">
                    <NavLink to="/forgot-password">Lost your password?</NavLink>
                  </div>
                </div>

                <div className="row d-flex text-center">
                  <p style={{ color: "red", fontSize: "15px" }}>{loginError}</p>
                </div>
                <div className="form_group">
                  <button type="submit">Login</button>
                </div>
              </form>
            </div>
          </div>

          {/* {loading == false ? (
            <div className="contact-popup active">
              <div className="wrapper2" style={{ height: "30rem" }}>
                <img src={loader} />
              </div>
            </div>
          ) : (
            ""
          )} */}
        </section>
      </Layout>
    </>
  );
}

export default Agentlogin;
