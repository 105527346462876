import React from 'react'
import GoogleMaps from './GoogleMaps'

function Maps(props) {
  return (
    <>
      <GoogleMaps map_value={props.map_value} mapdata={props.mapdata}/>



    </>
  )
}

export default Maps