import React, { useEffect, useState } from "react";
import { Route } from "../../Data/route";
import "../../Styles/main.css";
import HeaderLogo from "../../assets/images/header-logo.png";
import HeaderLogoBlue from "../../assets/images/header-logo-blue.png";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import "../../Styles/header.css";
import loaderImg from "../../assets/images/loader.svg";
function Header() {
  const [UserType, setUserType] = useState("User");
  const [userTypeData, setUserTypedata] = useState("User");
  const login = JSON.parse(localStorage.getItem("status"));
  const userinfo = JSON.parse(localStorage.getItem("user"));
  const [check, setcheck] = useState(true);
  const islogin = JSON.parse(localStorage.getItem("login"));
  const [language, setlanguage] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (userinfo) {
      // setUserType(userinfo.user_type)
      if (userinfo.user_type == "User") {
        setUserType(userinfo.name);
      } else if (userinfo.user_type == "Agent") {
        setUserType(userinfo.username);
      }
    }

    if (userinfo) {
      setUserTypedata(userinfo.user_type);
    }
  }, []);

  const handleLogout = () => {
    if (userTypeData == "User") {
      localStorage.clear();

      navigate("/signin");
    } else if (userTypeData == "Agent") {
      localStorage.clear();

      navigate("/agent-login");
    }
    console.log(userTypeData);
  };

  // console.log(UserType, userTypeData);

  const toggelBtnHandler = () => {
    var btn_cross = document.getElementById("btn_avtive");
    var addatyle = document.getElementById("toggel_btn");

    if (addatyle.style.display === "none") {
      addatyle.style.display = "block";
      console.log(btn_cross.style.transform);
      btn_cross.style.transform = "45deg";
    } else {
      addatyle.style.display = "none";
      console.log(btn_cross.style.transform);
    }
  };







  useEffect(() => {
    if (check) {
      setTimeout(() => {
        var addScript = document.createElement("script");
        addScript.setAttribute(
          "src",
          "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
        );

        document.body.appendChild(addScript);
        window.googleTranslateElementInit = googleTranslateElementInit;
      }, 2000);
      setcheck(false)
    }

  }, []);

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        // pageLanguage: "en",
        includedLanguages: 'en,fr,de,es,en,fr,de,es,',

      },

      "google_translate_element"
    );

  };





  const [loading, setloading] = useState(true)
  useEffect(() => {
    if (language == 2) {
      setloading(false)
      setTimeout(() => {
        window.location.reload()
      }, 200);
    }
  }, [language,loading])





  return (
    <>
      {loading !== true ? (
        <div className="loader_set loader_set2">
          {" "}
          <img src={loaderImg} alt="loading..." />{" "}
        </div>
      ) : ""}
      <div className="max_wi">
        <header className=" flex navbar_maxW  " style={{ position: "static" }}>
          <div className="container_ flex">
            <figure className="logo">
              <NavLink to="/">
                <img src={HeaderLogo} alt="" className="white-logo" />
                <img
                  src={HeaderLogoBlue}
                  alt="img loading"
                  className="blue-logo"
                />
              </NavLink>
            </figure>
            <nav>
              <div
                className="toogle-btn"
                id="btn_avtive"
                onClick={toggelBtnHandler}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div className="menus" id="toggel_btn">
                <ul className="flex">
                  {userTypeData && userTypeData == "User" ? (
                    <>
                      <li>
                        <NavLink to="/buy">Buy</NavLink>
                      </li>
                      <li>
                        <NavLink to="/rent">Rent</NavLink>
                      </li>

                      <li>
                        <NavLink to="/commercial">Commercial</NavLink>
                      </li>
                      <li>
                        <NavLink to="/buy-advice">Buying Advice</NavLink>
                      </li>
                      <li>
                        <NavLink to="/mallorca">Mallorca</NavLink>
                      </li>
                      <li className="inner dropdown2">
                        <div className="multi_lang">
                          <span id="google_translate_element" onClick={e => {
                            setlanguage(language + 1)
                          }}> </span>
                        </div>
                      </li>
                    </>
                  ) : (
                    <>
                      <li>
                        <NavLink to="/">{document.getElementsByTagName("html")[0].getAttribute("lang") == "es" ? <c class="notranslate">Página de inicio</c> : "Home"}</NavLink>
                      </li>
                      <li>
                        <NavLink to="/add-listing">Add Property</NavLink>
                      </li>
                      <li>
                        <NavLink to="/my-account">My Account</NavLink>
                      </li>
                      <li>
                        <NavLink to="/aboutus">About Us</NavLink>
                      </li>
                      <li className="inner dropdown2">
                        <div className="multi_lang">
                          <span id="google_translate_element" onClick={e => {
                            setlanguage(language + 1)
                          }}> </span>
                        </div>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </nav>

            {!islogin ? (
              <NavLink to="/signin" className="loginBtn">
                Login/Sign Up
              </NavLink>
            ) : (
              <>
                <div className="dropdown user_Heading  ">
                  <button className="notranslate dropbtn">
                    {UserType.substr(0, 20)}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M12 16l-6-6h12z" fill="rgba(77,84,113,1)" />
                    </svg>
                  </button>
                  <div className="dropdown-content">
                    <NavLink to="/my-account">Account Details</NavLink>
                    {userTypeData == "User" ? (
                      <>
                        <NavLink to="/my-bookmark">
                          Favourite Properties
                        </NavLink>
                        <NavLink to="/saved-searches">Saved Searches</NavLink>
                      </>
                    ) : (
                      ""
                    )}

                    <NavLink
                      to={userTypeData == "User" ? "/signin" : "/agent-login"}
                      onClick={() => localStorage.clear()}
                    >
                      Log Out
                    </NavLink>
                  </div>
                </div>
              </>
            )}
          </div>
        </header>
      </div>
    </>
  );
}

export default Header;
